import { Component, Logger } from 'lib'
import Home from 'Container/Home/Home'
import News from 'Container/News/News'
import Schedule from 'Container/Schedule/Schedule'
import Rewards from 'Container/Rewards/Rewards'
import Standings from 'Container/Standings/Standings'
import WatchLive from 'Container/Watch/Live/WatchLive'
import WatchVod from 'Container/Watch/Vod/WatchVod'
import Vods from 'Container/Vods/Vods'
import Team from 'Container/Team/Team'
import GlobalPartners from 'Container/GlobalPartners/GlobalPartners'
import Video from 'Container/Video/Video'
import Article from 'Container/Article/Article'
import Settings from 'Container/Settings/Settings'
import analytics from 'Common/Service/Analytics/Analytics'

const containers = {
  Home,
  News,
  Schedule,
  Rewards,
  Standings,
  WatchLive,
  WatchVod,
  Vods,
  Team,
  GlobalPartners,
  Video,
  Article,
  Settings
}

class Container extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  componentDidUpdate () {
    // make sure scrolled containers go back top
    window.scrollTo(0, 0)
  }

  render () {
    const name = this.props.path.split('/').pop()

    this.log.info('Loaded', name)
    const MyContainer = containers[name]

    analytics.trackPageEvent(name)

    return <MyContainer/>
  }
}

export default Container
