import { Component } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'

class ContentFiltersHeader extends Component {
  clearFilters () {
    this.props.setFiltersState([], [])
  }

  renderHeaderTitle () {
    const count = this.props.selectedContentFilters
      ? this.props.selectedContentFilters.length
      : 0
    const title = `
      ${locale.translate('news.contentFilters.filter')}
      (${count})`

    return <div className="title">{ title }</div>
  }

  onTitleClick () {
    this.props.setContentFiltersActiveness(!this.props.contentFiltersActive)
  }

  render () {
    return (
      <div class={ this.constructor.name }>
        <div class="count" onClick={ () => this.onTitleClick() } role="button">
          <Icon name="filter"/>
          { this.renderHeaderTitle() }
        </div>
        <div
          class="clear-filters"
          onClick={ () => this.clearFilters() }
          role="button">
          { locale.translate('news.contentFilters.clearFilters') }
        </div>
      </div>
    )
  }
}

export default ContentFiltersHeader
