import { Logger } from 'lib'
import locale from 'Common/Locale/Locale'

const statNameOverride = {
  goldEarned: 'totalGoldEarned',
  minionKills: 'creepScore'
}

class StatsMatchupUtil {
  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  format (player, stat, type) {
    const value
      = player[statNameOverride[stat]] !== undefined
        ? player[statNameOverride[stat]]
        : player[stat]

    if (type === 'number') {
      // simple number
      return locale.translate(value)
    }
    else if (type === 'percent') {
      // number using percentage
      return [Math.round(value * 100), <span class="percent">%</span>]
    }
    else if (type === 'kda') {
      // special case, 3 stats in 1
      return this.renderKDA(player.kills, player.deaths, player.assists)
    }
    else if (type === 'number-k') {
      // format into Ks
      return locale.shortNumberFormatter(value)
    }
  }

  renderKDA (kills, deaths, assists) {
    return [
      <span class="kills">{ kills }</span>,
      <span class="slash"> / </span>,
      <span class="deaths">{ deaths }</span>,
      <span class="slash"> / </span>,
      <span class="assists">{ assists }</span>
    ]
  }

  renderStat (player, stat, type, localeKey) {
    const value = this.format(player, stat, type)
    const title = locale.translate(localeKey + '.' + stat)

    // safeguard from undexpected/new stats. If we don't handle a stat,
    // don't break the page, just don't render it.
    if (title === '' || value === '') {
      return this.log.warn('Invalid stats property', title, value)
    }

    return (
      <div class={ 'stat ' + stat }>
        <div class="value">{ value }</div>
        <div class="title">{ title }</div>
      </div>
    )
  }
}

export default new StatsMatchupUtil()
