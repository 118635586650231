import { Component, Logger, router, $ } from 'lib'
import HomeContentBlock from 'Component/Home/Content/Block/HomeContentBlock'
// import SpotifyWidget from 'Component/SpotifyWidget/SpotifyWidget'
import device from 'Common/Device/Device'
import locale from 'Common/Locale/Locale'

// Odd lengths to accomodate SpotifyWidget in second column
const initialContentLength = {
  small: 5,
  medium: 5,
  large: 13
}

const numExtraContentToShow = 4

class HomeContent extends Component {
  constructor (props) {
    super(props)
    this.log = new Logger(this.constructor.name)

    this.setState({
      numContentToShow: this.getContentSize(),
      deviceSize: device.getSize()
    })
    this.addListeners()
  }

  getContentSize () {
    let numContentToShow = initialContentLength[device.getSize()]

    if (!locale.supportsHomeSpotifyWidget()) numContentToShow++

    return numContentToShow
  }

  addListeners () {
    $(document).on('mediaSizeChange', (event) => {
      this.setState({ deviceSize: event.data })
    })
  }

  componentWillUnmount () {
    $(document).off('mediaSizeChange')
  }

  renderContentList (content) {
    const visibleContent = content.map((content) =>
      <HomeContentBlock content={ content }/>
    )

    // contract with spotify ended -- removing spotify logic/render
    // if (renderSpotify) visibleContent = [<SpotifyWidget/>, ...visibleContent]
    return <div class="content-list">{ visibleContent }</div>
  }

  loadMoreContent () {
    const numContentToShow = this.state.numContentToShow += numExtraContentToShow

    this.setState({ numContentToShow })
  }

  renderLoadMore (content) {
    if (this.state.numContentToShow >= content.length) {
      return
    }

    return (
      <div class="load-more-container">
        <div className="load-more">
          <div
            class="load-more-button"
            role="button"
            onClick={ () => this.loadMoreContent() }>
            { locale.translate('home.vods.loadMore') }
            <span class="plus">+</span>
          </div>
        </div>
      </div>
    )
  }

  splitHomepage (homepage) {
    const evenList = homepage
      .filter((_, index) => index % 2 === 0)
      .slice(0, Math.ceil(this.state.numContentToShow / 2))

    const oddList = homepage
      .filter((_, index) => index % 2 === 1)
      .slice(0, this.state.numContentToShow / 2)

    return { evenList, oddList }
  }

  renderContent (content) {
    if (this.props.hasContentFilters && content.length === 0) {
      return (
        <div class="content">
          { locale.translate('news.contentFilters.noResultsMsg') }
        </div>
      )
    }

    const showSpotify = locale.supportsHomeSpotifyWidget()

    if (this.state.deviceSize === 'small') {
      content = content.slice(0, this.state.numContentToShow)

      return (
        <div class="content">
          { this.renderContentList(content, showSpotify) }
        </div>
      )
    }
    else {
      const { evenList, oddList } = this.splitHomepage(content)

      return (
        <div class="content">
          { this.renderContentList(evenList) }
          { this.renderContentList(oddList, showSpotify) }
        </div>
      )
    }
  }

  renderHeader (content) {
    if (this.props.hasContentFilters) {
      return (
        <div class="header">
          <div class="results">
            { `${locale.translate('news.results')} (${content.length})` }
          </div>
        </div>
      )
    }
    return (
      <div class="header">
        <div class="latest">{ locale.translate('home.content.latest') }</div>
      </div>
    )
  }

  viewAllContent (event) {
    event.stopPropagation()
    router.go('/news', event)
  }

  renderViewAllButton () {
    return (
      <div
        class="view-all"
        onClick={ (event) => this.viewAllContent(event) }
        role="button">
        <div class="view-all-button">
          { locale.translate('home.content.viewAll') }
        </div>
      </div>
    )
  }

  renderFooter (content) {
    return this.props.hasContentFilters
      ? this.renderLoadMore(content)
      : <div class="view-all-container">{ this.renderViewAllButton() }</div>
  }

  render ({ content }) {
    if (!content) return

    return (
      <div class={ this.constructor.name }>
        { this.renderHeader(content) }
        { this.renderContent(content) }
        { this.renderFooter(content) }
      </div>
    )
  }
}

export default HomeContent
