import { Component, $, Logger } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'

class StatsMatchupSelector extends Component {
  constructor () {
    super()
    this.addListeners()
    this.log = new Logger(this.constructor.name)
  }

  addListeners () {
    this.clickTab = (event) => {
      const clickedTab = $(event.currentTarget)
      const selectedTab = $(this.base).find('.selected')

      if (clickedTab.data('tag') === selectedTab.data('tag')) return

      selectedTab.removeClass('selected')
      clickedTab.addClass('selected')

      this.props.statsTabChangeHandler
        && this.props.statsTabChangeHandler(clickedTab.data('tag'))
    }
  }

  renderTabs () {
    return this.props.tabList.map((tabName) => {
      const selectedClass = this.props.selectedTab === tabName && 'selected'
      const tabClasses = util.classNames('tab', tabName, selectedClass)

      return (
        <div class={ tabClasses } onclick={ this.clickTab } data-tag={ tabName }>
          <Icon name={ tabName }/>
        </div>
      )
    })
  }

  render () {
    if (
      !this.props.statsTabChangeHandler
      || !this.props.tabList
      || !this.props.selectedTab
    ) {
      this.log.error('Missing prop in StatsMatchupSelector:', this.props)
      return
    }

    return (
      <div class={ this.constructor.name }>
        <div class="menu">{ this.renderTabs() }</div>
      </div>
    )
  }
}

export default StatsMatchupSelector
