import { Component } from 'lib'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'

class Accordion extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: props.open || false
    }
  }

  onClick (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      open: !this.state.open
    })
  }

  renderBody (children) {
    return <div class="accordion-body">{ children }</div>
  }

  renderTitle (title, open, titleClass = '', chevronLocation = 'before-title') {
    const direction = open ? 'down' : 'right'
    const titleClassNames = util.classNames('accordion-title', titleClass)
    const classNames = util.classNames('chevron', direction)
    const chevron = (classNames) => (
      <div className={ classNames }>
        <Icon name="arrow" direction="right"/>
      </div>
    )

    return (
      <div
        class={ titleClassNames }
        onClick={ (event) => this.onClick(event) }
        role="button">
        { chevronLocation === 'before-title' && chevron(classNames) }
        <span class="title-text">{ title }</span>
        { chevronLocation === 'after-title' && chevron(classNames) }
      </div>
    )
  }

  render (props, state) {
    const { title, titleClass, chevron, children } = props
    const { open } = state
    const classes = util.classNames(
      this.constructor.name,
      open && 'open',
      props.class
    )

    return (
      <div class={ classes }>
        { this.renderTitle(title, open, titleClass, chevron) }
        { this.renderBody(children) }
      </div>
    )
  }
}

export default Accordion
