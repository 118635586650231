import { $, store, Component } from 'lib'
import locale from 'Common/Locale/Locale'
import Icon from 'Component/Asset/Icon/Icon'
import InformLoading from 'Component/Inform/Loading/InformLoading'

class LeaguesDropdown extends Component {
  constructor (props) {
    super(props)

    this.addListeners()
    this.toggleDropdownLinkActiveClasses(true)
    this.getMenuPosition()
  }

  componentWillUnmount () {
    this.toggleDropdownLinkActiveClasses(false)
    this.removeListeners()
  }

  addListeners () {
    $(document).on(
      'click',
      this.clickListener = (event) => {
        const target = $(event.target)

        if (target.closest(`.${this.constructor.name}`).length) {
          this.onMenuClick(event)
        }
        else if (!target.closest('a[href="#leagues"]').length) {
          this.closeMenu()
        }
      }
    )

    $(window).on(
      'resize',
      this.resizeListener = () => {
        this.closeMenu()
      }
    )
  }

  removeListeners () {
    $(document).off('click', this.clickListener)
    $(window).off('resize', this.resizeListener)
  }

  getMenuPosition () {
    const leaguesLink = $('a[href="#leagues"]')
    const moreLink = $('[data-link-id="__moreItems__"]')
    const moreMenuActive = leaguesLink.closest('[data-link-id="__moreItems__"]')
      .length
    const offsetElement = moreMenuActive ? moreLink : leaguesLink

    if (offsetElement.length) {
      const offsetRect = offsetElement[0].getBoundingClientRect()

      this.setState({ offsetRect })
    }
  }

  toggleDropdownLinkActiveClasses (isActive) {
    const leaguesLink = $('a[href="#leagues"]')
    const moreLink = $('[data-riotbar-link-id="__moreItems__"]')
    const moreMenuActive = leaguesLink.closest('[data-link-id="__moreItems__"]')
      .length
    const dropdownLink = moreMenuActive ? moreLink : leaguesLink

    isActive
      ? dropdownLink.addClass('leagues-menu-active')
      : dropdownLink.removeClass('leagues-menu-active')
  }

  closeMenu () {
    store.set('leaguesMenu.isActive', false)
    this.props.setActiveLeague(-1)
  }

  onMenuClick (event) {
    const target = $(event.target)

    if (
      target.closest('.close').length
      || target.closest('.back').length
      || target.closest('.league-item').length
    ) {
      event.preventDefault()
    }

    if (target.closest('.back').length) {
      this.props.setActiveLeague(-1)
    }
    else if (target.closest('.close').length) {
      store.set('leaguesMenu.isActive', false)
      const moreLink = $('[data-link-id="__moreItems__"]')

      moreLink.addClass('nav-dropdown-active')
    }
    else if (target.closest('.league-item').length) {
      const menuItem = target.closest('.item')

      this.props.setActiveLeague(menuItem.index() - 1)
    }
  }

  renderMenuItems (items) {
    return (
      <div class="list">
        <div class="item close">
          <span class="arrow">
            <Icon name="arrow" direction="left"/>
          </span>
          <div class="title">{ locale.translate('navigation.close') }</div>
        </div>
        { items.map((item) => this.renderMenuItem(item)) }
      </div>
    )
  }

  renderMenuItem (item) {
    const title = this.props.getLeagueTitleWithRegion(item, this.props.leagues)

    return (
      <a class="item league-item">
        <div class="title">{ title }</div>
        <span class="arrow">
          <Icon name="arrow" direction="right"/>
        </span>
      </a>
    )
  }

  renderSubmenuItems (index) {
    const item = this.props.items[index]
    const title = this.props.getLeagueTitleWithRegion(item, this.props.leagues)

    return (
      <div class="list">
        <div class="item back">
          <span class="arrow">
            <Icon name="arrow" direction="left"/>
          </span>
          <div class="title">{ title }</div>
        </div>
        { item.links.map((item) => this.renderSubmenuItem(item)) }
      </div>
    )
  }

  renderSubmenuItem (item) {
    return (
      <a class="item" href={ item.href }>
        <div class="title">{ item.title }</div>
      </a>
    )
  }

  renderLoading () {
    return (
      <div class="leagues-menu-loading">
        <InformLoading style={ { height: '100%' } }/>
      </div>
    )
  }

  renderContent () {
    return (
      <div class="menu-items">
        { this.props.activeLeagueIndex === -1
          && this.renderMenuItems(this.props.items) }
        { this.props.activeLeagueIndex > -1
          && this.renderSubmenuItems(this.props.activeLeagueIndex) }
      </div>
    )
  }

  render () {
    const { items, leagues, error } = this.props
    const contentLoaded = items && leagues && !error

    const offsetRect = this.state.offsetRect

    if (!offsetRect) return

    const positionStyles = {
      left: offsetRect ? offsetRect.left - 8 + 'px' : '0px',
      top: offsetRect ? offsetRect.bottom + 'px' : '0px'
    }

    return (
      <div class={ this.constructor.name } style={ positionStyles }>
        { contentLoaded ? this.renderContent() : this.renderLoading() }
      </div>
    )
  }
}

export default LeaguesDropdown
