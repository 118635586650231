import { Component, Logger, $ } from 'lib'
import StandingsBracketStandard from 'Component/Standings/Bracket/Standard/StandingsBracketStandard'
import StandingsBracketFallback from 'Component/Standings/Bracket/Fallback/StandingsBracketFallback'
import device from 'Common/Device/Device'

class StandingsBracket extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  componentDidMount () {
    this.addPostMountEventListeners()
  }

  addPostMountEventListeners () {
    device.getSize() === 'small'
      && device.addSwipeListener(
        this.base,
        (direction) => {
          const NEXT_SNAP_SWIPE_THRESHOLD = 30
          const elementToScroll = $(this.base)[0]

          const containingElement = this.isStandardBracket(this.props.stage)
            ? $('.grouping.first').eq(0)
            : $('.round').eq(0)

          // fix for TypeError: undefined is not an object (evaluating e.getBoundingClientRect)
          // when standings page is broken (missing a section) and user tries to swipe
          // the page does not have a $('.round') or $('.grouping.first') and trying to get
          // .width() from it errors out
          if (containingElement.length === 0) return

          const widthToScroll = containingElement.width()
          const pixelsFromLeftSnap = elementToScroll.scrollLeft % widthToScroll
          const pixelsFromRightSnap = widthToScroll - pixelsFromLeftSnap

          if (direction.leftToRight) {
            if (pixelsFromRightSnap < NEXT_SNAP_SWIPE_THRESHOLD) {
              this.scrollToSnapPosition(elementToScroll, pixelsFromRightSnap)
            }
            else {
              this.scrollToSnapPosition(elementToScroll, -pixelsFromLeftSnap)
            }
          }
          else if (direction.rightToLeft) {
            if (pixelsFromLeftSnap < NEXT_SNAP_SWIPE_THRESHOLD) {
              this.scrollToSnapPosition(elementToScroll, -pixelsFromLeftSnap)
            }
            else {
              this.scrollToSnapPosition(elementToScroll, pixelsFromRightSnap)
            }
          }
        },
        1
      )

    $(document).on(
      'mousedown',
      this.onMouseDownListener = (event) => {
        let prevX = event.clientX

        let prevY = event.clientY
        const bracketElement = $(this.base)[0]

        if (!this.mouseMoveListenerActive) {
          // prevent duplicate listeners from being added
          this.mouseMoveListenerActive = true
          $(document).on(
            'mousemove',
            this.onMouseMoveListener = (event) => {
              bracketElement.scrollLeft
                = bracketElement.scrollLeft + (prevX - event.clientX)
              window.scrollBy(0, prevY - event.clientY)

              prevX = event.clientX
              prevY = event.clientY
            }
          )
        }
      }
    )

    $(document).on(
      'mouseup',
      this.onMouseUpListener = () => {
        this.removeMouseMoveEventListener()
      }
    )

    $(document).on(
      'dragend',
      this.onDragEndListener = () => {
        this.removeMouseMoveEventListener()
      }
    )
  }

  componentWillUnmount () {
    $(document).off('mousedown', this.onMouseDownListener)
    $(document).off('mouseup', this.onMouseUpListener)
    $(document).off('dragend', this.onDragEndListener)
    device.removeSwipeListener(this.base)
  }

  removeMouseMoveEventListener () {
    $(document).off('mousemove', this.onMouseMoveListener)
    this.mouseMoveListenerActive = false
  }

  scrollToSnapPosition (element, pixels) {
    // these two lines allow scrolling via a negative pixel amount
    const numSteps = Math.abs(pixels)
    const step = pixels / numSteps

    for (let i = 0; i < numSteps; i++) {
      window.setTimeout(() => {
        element.scrollLeft = element.scrollLeft + step
      }, this.getScrollDelay(i, pixels))
    }
  }

  // Gets the time (in ms) to delay the next step. Starts out constant with a
  // base modifier * the current step number, but at a certain percentage
  // through the scroll, its switches to a linearly increasing function (that
  // starts at the constant delay) to make the last bit of the scroll smooth
  getScrollDelay (currentStep, totalSteps) {
    const BASE_DELAY_MODIFIER = 0.5
    const CONSTANT_DELAY_PERCENTAGE_CUTOFF = 0.6
    const LINEAR_DELAY_MAX_MODIFIER
      = BASE_DELAY_MODIFIER / CONSTANT_DELAY_PERCENTAGE_CUTOFF

    const percentageScrolled = currentStep / totalSteps

    if (percentageScrolled < CONSTANT_DELAY_PERCENTAGE_CUTOFF) {
      return currentStep * BASE_DELAY_MODIFIER
    }
    else {
      return percentageScrolled * LINEAR_DELAY_MAX_MODIFIER * currentStep
    }
  }

  // e.g. 4 matches -> 2 matches -> 1 match
  isStandardBracket (stage) {
    let prevSectionSize

    for (let i = 0; i < stage.sections.length; i++) {
      const section = stage.sections[i]

      if (!prevSectionSize) {
        prevSectionSize = section.matches.length
      }
      else if (prevSectionSize !== section.matches.length * 2) return false

      prevSectionSize = section.matches.length
    }

    return true
  }

  // e.g. 1 match -> 1 match -> 1 match
  isGauntletBracket (stage) {
    for (let i = 0; i < stage.sections.length; i++) {
      if (stage.sections[i].matches.length !== 1) return false
    }

    return true
  }

  renderBracket (stage) {
    if (this.isStandardBracket(stage)) {
      return <StandingsBracketStandard stage={ stage }/>
    }
    else {
      return (
        <StandingsBracketFallback
          stage={ stage }
          gauntlet={ this.isGauntletBracket(stage) }
        />
      )
    }
  }

  render () {
    if (!this.props.stage) {
      return
    }

    return (
      <div class={ this.constructor.name }>
        { this.renderBracket(this.props.stage) }
      </div>
    )
  }
}

export default StandingsBracket
