import { Component, Logger, store } from 'lib'
import liveStats from 'Common/Service/LiveGameEvents/LiveGameEvents'
import locale from 'Common/Locale/Locale'
import watchUtil from 'Container/Watch/Util/WatchUtil'
import dataDragon from 'Common/Service/DataDragon/DataDragon'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import StatsTeamsPlayers from 'Component/Stats/Teams/Players/StatsTeamsPlayers'
import StatsTeamsSummary from 'Component/Stats/Teams/Summary/StatsTeamsSummary'

const gameStates = {
  FINISHED: 'finished'
}

class StatsTeams extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.timeline = []
    this.gameIdIsAccurate = false
    this.gameMetadata = {}
  }

  componentDidMount () {
    this.addListeners()
  }

  componentWillMount () {
    if (this.props.gameId) {
      this.gameIdIsAccurate = true
    }
  }

  componentWillUnmount () {
    store.removeListener('videoPlayer.timestamp', this.onVideoPlayerTimestamp)
    store.removeListener('dataDragon.setup', this.onDataDragonSetup)
    store.removeListener(
      'watch.live.finalTimestamp',
      this.onFinalTimestampChange
    )
    store.removeListener(
      'watch.initialTimestamp',
      this.onInitialTimestampChange
    )
    store.set('dataDragon.setup', false)
    this.refetcher && this.refetcher.stop()
  }

  addListeners () {
    store.onChange(
      'videoPlayer.timestamp',
      this.onVideoPlayerTimestamp = (timestamp) => {
        if (this.isInGame() && this.gameIdIsAccurate) {
          const stats = this.getStats(timestamp)

          stats && this.setState({ stats })
        }
      }
    )

    store.onChange(
      'dataDragon.setup',
      this.onDataDragonSetup = (setup) => {
        if (setup) {
          this.refetcher && this.refetcher.restart()
        }
      }
    )

    store.onChange(
      'watch.initialTimestamp',
      this.onInitialTimestampChange = (newTimestamp) => {
        if (newTimestamp) {
          this.refetcher ? this.refetcher.restart() : this.refetch()
        }
      }
    )

    store.onChange(
      'watch.live.finalTimestamp',
      this.onFinalTimestampChange = (newTimestamp) => {
        if (newTimestamp) {
          this.refetcher && this.refetcher.stop()
          this.gameIdIsAccurate = false
        }
      }
    )
  }

  componentDidUpdate (prevProps) {
    if (this.props.gameId !== prevProps.gameId) {
      this.refetcher && this.refetcher.stop()
      this.refetch()
      this.timeline = []
      this.gameIdIsAccurate = true
    }
  }

  isInGame () {
    return (
      store.get('watch.initialTimestamp')
      && !store.get('watch.live.finalTimestamp')
    )
  }

  refetch () {
    this.log.info('Refetching with gameId', this.props.gameId)
    this.refetcher = liveStats.refetchWindow(this.props.gameId)

    this.refetcher
      .onFetch((json) => {
        // got a 200 with no JSON body, we are waiting for the game to start
        if (!json) return this.log.info('Waiting for game to start...')

        if (!store.get('dataDragon.setup')) {
          dataDragon.setup(json.gameMetadata.patchVersion)
        }
        else {
          this.timeline = json.frames
          this.gameMetadata = json.gameMetadata

          // force refresh stats
          const stats = this.getStats(store.get('videoPlayer.timestamp'))

          if (stats) {
            this.setState({
              stats,
              gameId: this.props.gameId
            })
          }
        }
      })
      .onError((error) => {
        this.log.error(error)
      })
  }

  getStats (videoTimestamp) {
    for (let n = 1; n < this.timeline.length; n++) {
      const eventTimestamp = Date.parse(this.timeline[n].rfc460Timestamp)

      if (eventTimestamp > videoTimestamp) {
        return this.timeline[n - 1]
      }
    }

    // Current timestamp does not match any events on the timeline
    // This means we are at post/after game but still showing livestats
    // Show the last stats we have
    const finalFrame = this.timeline[this.timeline.length - 1]

    if (
      this.props.watchType === watchUtil.streamTypes.live
      && finalFrame
      && finalFrame.gameState === gameStates.FINISHED
      && !store.get('watch.live.finalTimestamp')
    ) {
      this.log.info(
        'Received final event, setting finalTimestamp to',
        finalFrame.rfc460Timestamp
      )
      store.set('watch.live.finalTimestamp', finalFrame.rfc460Timestamp)
    }

    return finalFrame
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading style="height:200px"/>
        }
      </main>
    )
  }

  renderNoStatsAvailable () {
    return (
      <main class={ this.constructor.name }>
        <div class="no-stats">{ locale.translate('stats.notAvailable') }</div>
      </main>
    )
  }

  render () {
    if (!this.state.stats || !this.state.gameId) {
      return this.renderNoStatsAvailable()
    }

    if (
      !this.state.stats.blueTeam
      || !this.state.stats.redTeam
      || this.state.error
    ) {
      return this.renderErrorOrLoading(this.state.error)
    }

    const blueTeam = this.state.stats.blueTeam
    const redTeam = this.state.stats.redTeam

    return (
      <div class={ this.constructor.name }>
        <StatsTeamsSummary blueTeam={ blueTeam } redTeam={ redTeam }/>

        <StatsTeamsPlayers
          gameId={ this.state.gameId }
          blueTeamPlayers={ blueTeam.participants }
          redTeamPlayers={ redTeam.participants }
          blueTeamInfo={ this.gameMetadata.blueTeamMetadata }
          redTeamInfo={ this.gameMetadata.redTeamMetadata }
          smallShowMatchupHandler={ this.props.smallShowMatchupHandler }
        />
      </div>
    )
  }
}

export default StatsTeams
