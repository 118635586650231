import { Component } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'

class InformLoading extends Component {
  render () {
    return (
      <div class={ this.constructor.name } style={ this.props.style }>
        <div class="inner">
          <Icon name="lolesportsLogo"/>
          <Icon name="lolesportsLogo"/>
        </div>
      </div>
    )
  }
}

export default InformLoading
