import { Component } from 'lib'
import statsMatchupUtil from 'Component/Stats/Matchup/Util/StatsMatchupUtil'
import util from 'Common/Util/Util'

const attributeMap = {
  attackDamage: 'number',
  abilityPower: 'number',
  attackSpeed: 'number',
  lifeSteal: 'number',
  armor: 'number',
  magicResistance: 'number'
}

class StatsMatchupAttributes extends Component {
  renderAttributes (player, id) {
    const localeKey = 'attribute'

    const attributes = Object.keys(attributeMap).map((stat) =>
      statsMatchupUtil.renderStat(player, stat, attributeMap[stat], localeKey)
    )

    const classes = util.classNames(
      'player',
      id === this.props.primaryTeam ? 'primary' : 'secondary'
    )

    return <div class={ classes }>{ attributes }</div>
  }

  render () {
    return (
      <div class={ this.constructor.name }>
        { this.renderAttributes(this.props.bluePlayer, 'blue') }
        { this.renderAttributes(this.props.redPlayer, 'red') }
      </div>
    )
  }
}

export default StatsMatchupAttributes
