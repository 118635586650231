import { Component } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import ContentFiltersHeader from 'Component/News/ContentFilters/ContentFiltersHeader/ContentFiltersHeader'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'
import analytics from 'Common/Service/Analytics/Analytics'

class ContentFilters extends Component {
  constructor (props) {
    super(props)

    let collapsedGroups = []

    if (!this.props.inSidebar) {
      collapsedGroups = this.props.filterGroups
        .slice(1)
        .map((filterGroup) => filterGroup.uid)
    }

    this.setState({ collapsedGroups })
  }

  onGroupTitleClick (uid) {
    let collapsedGroups = this.state.collapsedGroups

    if (!this.props.inSidebar) {
      const collapsed = collapsedGroups.indexOf(uid) !== -1
      // collapse all groups except for the one that was clicked

      collapsedGroups = this.props.filterGroups.reduce(
        (results, filterGroup) => {
          if (filterGroup.uid !== uid) results.push(filterGroup.uid)
          return results
        },
        []
      )
      // if the clicked item was not already collpased, collpase it as well
      if (!collapsed) collapsedGroups.push(uid)
    }
    else {
      collapsedGroups = this.updateCollapsedGroupsLarge(collapsedGroups, uid)
    }
    this.setState({ collapsedGroups })
  }

  updateCollapsedGroupsLarge (collapsedGroups, uid) {
    if (collapsedGroups.indexOf(uid) !== -1) {
      collapsedGroups = collapsedGroups.filter((item) => item !== uid)
    }
    else {
      collapsedGroups.push(uid)
    }
    return collapsedGroups
  }

  onFilterClick (event, contentFilter) {
    const { selectedContentFilters, setFiltersState } = this.props
    const { uid, title } = contentFilter

    analytics.trackEvent('News content filter removed', { filter: title })

    if (selectedContentFilters.indexOf(uid) !== -1) {
      // remove content filter
      const index = selectedContentFilters.indexOf(uid)

      selectedContentFilters.splice(index, 1)
    }
    else {
      // add content filter
      analytics.trackEvent('News content filter added', { filter: title })

      selectedContentFilters.push(uid)
    }
    setFiltersState(
      selectedContentFilters,
      this.getActiveFilterGroups(selectedContentFilters)
    )
  }

  getActiveFilterGroups (selectedContentFilters) {
    const groups = this.props.filterGroups
    const activeGroups = []

    if (!groups || groups.length < 1) return activeGroups
    groups.forEach((filterGroup) => {
      const filters = filterGroup.content_filters

      if (filters && filters.length > 0) {
        filters.forEach((contentFilter) => {
          if (selectedContentFilters.indexOf(contentFilter.uid) !== -1) {
            activeGroups.push(filterGroup.uid)
          }
        })
      }
    })
    // return new array with duplicates removed
    return activeGroups.filter(
      (element, position, array) => array.indexOf(element) === position
    )
  }

  renderFilters () {
    if (!this.props.filterGroups) return
    const groups = []

    this.props.filterGroups.map((filterGroup) => {
      groups.push(this.renderGroup(filterGroup))
    })
    return groups
  }

  renderGroup (filterGroup) {
    const filtersInGroup = filterGroup.content_filters

    if (!filtersInGroup || filtersInGroup.length < 1) return
    const activeFiltersCount = filtersInGroup.filter(
      (contentFilter) =>
        this.props.selectedContentFilters.indexOf(contentFilter.uid) !== -1
    ).length
    const groupCollapsed
      = this.state.collapsedGroups.indexOf(filterGroup.uid) !== -1
    const classes = util.classNames(
      'section',
      groupCollapsed && 'collapsed',
      activeFiltersCount > 0 && 'active'
    )

    return (
      <div class={ classes }>
        { this.renderGroupTitle(filterGroup, activeFiltersCount) }
        { !groupCollapsed && (
          <div className="group-inputs">
            { this.renderInputs(filtersInGroup) }
          </div>
        ) }
      </div>
    )
  }

  renderGroupTitle (filterGroup, activeFiltersCount) {
    return (
      <div
        role="button"
        class="group-title"
        onClick={ () => this.onGroupTitleClick(filterGroup.uid) }>
        { filterGroup.title } ({ activeFiltersCount })
      </div>
    )
  }

  renderInputs (filtersInGroup) {
    const inputs = []

    filtersInGroup.map((contentFilter) => {
      inputs.push(this.renderInput(contentFilter))
    })
    return inputs
  }

  renderInput (contentFilter) {
    const isChecked
      = this.props.selectedContentFilters.indexOf(contentFilter.uid) !== -1
    const classes = util.classNames('option', isChecked && 'checked')

    return (
      <div
        role="button"
        class={ classes }
        onClick={ (event) => this.onFilterClick(event, contentFilter) }>
        <span class="input">
          <input
            id={ `filter-${contentFilter.uid}` }
            type="checkbox"
            checked={ isChecked }
          />
        </span>
        <Icon name="checkmark"/>
        <label
          htmlFor={ `filter-${contentFilter.uid}` }
          onClick={ (event) => event.stopPropagation() }>
          { contentFilter.title }
        </label>
      </div>
    )
  }

  renderCloseButton () {
    return (
      <div class="section close">
        <div
          role="button"
          class="close-button"
          onClick={ () => this.props.setContentFiltersActiveness(false) }>
          { locale.translate('news.contentFilters.applyFilters') }
        </div>
      </div>
    )
  }

  render () {
    return (
      <div class={ this.constructor.name }>
        <div class="section">
          <ContentFiltersHeader
            selectedContentFilters={ this.props.selectedContentFilters }
            setFiltersState={ this.props.setFiltersState }
            contentFiltersActive={ this.props.contentFiltersActive }
            setContentFiltersActiveness={ this.props.setContentFiltersActiveness }
          />
        </div>
        { this.renderFilters() }
        { this.renderCloseButton() }
      </div>
    )
  }
}

export default ContentFilters
