import { Component, Logger, router } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import Image from 'Component/Asset/Image/Image'
import relApi from 'Common/Service/RelApi/RelApi'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import EventWidget from 'Component/Event/Widget/EventWidget'
import util from 'Common/Util/Util'

class Team extends Component {
  constructor (props, state) {
    super(props, state)
    this.log = new Logger(this.constructor.name)
  }

  componentDidMount () {
    const slug = router.param('teamSlug')

    relApi
      .fetchTeamBySlug(slug)
      .then((team) => {
        this.setState(team)
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  renderTeamBanner () {
    const sizes = { medium: 1100, small: 700 }
    const leagueName
      = this.state.homeLeague
      && `${this.state.homeLeague.name} - ${this.state.homeLeague.region}`
    const banner = this.state.banner || '/team-header-fallback.jpg'

    return (
      <div class="team-banner" style={ Image.background(banner, sizes) }>
        <Image class="team-icon" src={ this.state.image } size="200"/>
        <div class="team-info">
          <p class="team-name">{ this.state.name }</p>
          <p class="league-name">{ leagueName }</p>
        </div>
      </div>
    )
  }

  renderRosterContainer () {
    const classes = util.classNames(
      'roster',
      this.state.players.length % 2 === 0 && 'even'
    )

    return (
      <div class={ classes }>
        { this.state.players.map((player) =>
          this.renderPlayerCard(player, this.state.image)
        ) }
      </div>
    )
  }

  renderPlayerCard (player, teamIcon) {
    const teamSizes = { medium: 500, large: 500 } // small uses default size
    const playerSizes = { small: 375, medium: 375, large: 375 }

    return (
      <div class="card mask" style={ Image.background(teamIcon, teamSizes) }>
        <div class="info" style={ Image.background(player.image, playerSizes) }>
          <Icon name="roleIcon" role={ player.role }/>
          <div class="names">
            <div class="summoner-name">{ player.summonerName }</div>
            <div class="real-name">
              { `${player.firstName} ${player.lastName}`.toUpperCase() }
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderScheduleWidget () {
    const teamSlug = router.param('teamSlug')

    return <EventWidget teamSlug={ teamSlug }/>
  }

  renderErrorOrLoading (error) {
    return error ? (
      <InformBubble theme="error" icon="error">
        { error.message }
      </InformBubble>
    ) : (
      <div class="loading">
        <InformLoading/>
      </div>
    )
  }

  render () {
    if (this.state.error || !this.state.id) {
      return (
        <main class={ this.constructor.name }>
          { this.renderErrorOrLoading(this.state.error) }
        </main>
      )
    }

    return (
      <main class={ this.constructor.name }>
        { this.renderTeamBanner() }
        { this.renderScheduleWidget() }
        { this.renderRosterContainer() }
      </main>
    )
  }
}

export default Team
