import './babel-helpers'

import './polyfills/array-filter.min'
import './polyfills/array-find.min'
import './polyfills/array-from.min'
import './polyfills/array-includes.min'
import './polyfills/object-assign.min'
import './polyfills/function-name.min'
import './polyfills/number-isnan.min'
import './polyfills/promise.min'
import './polyfills/broadcast-channel.min'
import './polyfills/custom-event.min'
import './polyfills/fetch.min'

import WebSessionClient from '@riotgames/web-session-client'
import RmsClient from '@riotgames/rms-client'
import Refetcher from './modules/refetcher'
import Logger from './modules/logger'
import router from './modules/router'
import store from './modules/store'
import $ from './modules/select'
import { require } from './modules/require'
import { Component, render, h } from 'preact'
import {
  twitchHeartbeater,
  youtubeHeartbeater,
  trovoHeartbeater,
  afreecaTVHeartbeater,
  unsupportedPlayerHeartbeater
} from '@riotgames/eras-heartbeater'

// if it already exists don't overwrite it, it was mocked on our unit tests
if (!window.require) window.require = require // mimic the old RequireJS // TODO: remove / add to Util
if (!window.h) window.h = h // TODO: remove, add "import { h } ..." to all components

const erasHeartbeater = {
  twitchHeartbeater,
  youtubeHeartbeater,
  trovoHeartbeater,
  afreecaTVHeartbeater,
  unsupportedPlayerHeartbeater
}

export {
  Refetcher, Logger, router, store, $, Component, h, render,
  erasHeartbeater, WebSessionClient, RmsClient
}
