import { Component, Logger } from 'lib'
import Image from 'Component/Asset/Image/Image'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'
import rewardsTeamPersistence from 'Common/Service/Rewards/TeamPersistence/RewardsTeamPersistence'

const slugToTeamSelectImage = {
  lcs: '/rewards/pass/lcs.png',
  lec: '/rewards/pass/lec.png'
}

class InformTeamPassSelector extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  componentWillMount () {
    this.addListeners()
  }

  addListeners () {
    this.onGetTeamPass = () => {
      const { selectedTeam } = this.state
      const handler = this.props.teamPassSelectedHandler
      const callHandler = () => handler && handler()

      return rewardsTeamPersistence
        .selectTeamPass(selectedTeam.id)
        .then(callHandler)
        .then(() => this.setState({ selectedTeam: null }))
        .catch((error) => {
          this.log.error(error)
          return callHandler()
        })
    }

    this.onTeamSelected = (selectedTeam) => this.setState({ selectedTeam })

    this.toggleTeamList = () =>
      this.setState({ hideTeamList: !this.state.hideTeamList })
  }

  renderTeams () {
    const teams = util.getDeepValue(this.props, 'league.teams')

    if (!teams) {
      return this.log.error('League does not contain teams', teams)
    }

    return this.props.league.teams.map((team) => {
      const selected
        = team.id === util.getDeepValue(this.state, 'selectedTeam.id')
        && 'selected'

      return (
        <span
          class={ util.classNames('team', selected) }
          onclick={ () => this.onTeamSelected(team) }>
          <Image src={ team.image } class="logo" size="50"/>
          <div class="details">
            <div class="name">{ team.name }</div>
            <div class="code">{ team.code }</div>
          </div>
        </span>
      )
    })
  }

  renderNotSelected () {
    const slug = util.getDeepValue(this.props, 'league.slug')

    return (
      <div class="panel not-selected">
        <div class="message">
          { locale.translate('rewards.teamPass.unlocked', {
            league: <span class="league">{ slug }</span>
          }) }
        </div>
        <Image
          class="team-select"
          src={ this.getTeamSelectImage(slug) }
          size="250"
        />
        <div class="button choose-team" onclick={ this.toggleTeamList }>
          { locale.translate('rewards.teamPass.choose') }
        </div>
      </div>
    )
  }

  getTeamSelectImage (slug) {
    return slugToTeamSelectImage[slug.toLowerCase()] || '/rewards/pass/fan.png'
  }

  renderSelected () {
    return (
      <div class="panel selected">
        <Image
          class="team-logo"
          src={ this.state.selectedTeam.image }
          size="256"
          alt={ this.state.selectedTeam.code }
        />
        <div class="selected-header">
          { locale.translate('rewards.teamPass.confirmTeam') }
        </div>
        <div class="selected-message">
          { locale.translate('rewards.teamPass.warning') }
        </div>
        <div role="button" class="button" onclick={ this.onGetTeamPass }>
          { locale.translate('rewards.teamPass.confirm') }
        </div>
        <div role="button" class="change-team" onclick={ this.toggleTeamList }>
          { locale.translate('rewards.teamPass.changeTeam') }
        </div>
      </div>
    )
  }

  render () {
    const teamListClasses = util.classNames(
      'team-list',
      !this.state.hideTeamList && 'hide-small'
    )

    const infoClasses = util.classNames(
      'info',
      this.state.hideTeamList && 'hide-small'
    )

    return (
      <div class={ util.classNames(this.constructor.name) }>
        <div class="modal">
          <div class="content">
            <div class={ infoClasses }>
              { this.state.selectedTeam
                ? this.renderSelected()
                : this.renderNotSelected() }
            </div>
            <div class={ teamListClasses }>
              <div class="header">
                { locale.translate('rewards.teamPass.select') }
                <div class="save" onclick={ this.toggleTeamList } role="button">
                  { locale.translate('navigation.save') }
                </div>
              </div>
              <div class="teams">{ this.renderTeams() }</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InformTeamPassSelector
