import { Component } from 'lib'
import StandingsBracketV2Match from 'Component/Standings/BracketV2/Match/StandingsBracketV2Match'
import standingsBracketUtil from 'Component/Standings/BracketV2/Util/StandingsBracketUtil'
import util from 'Common/Util/Util'

class StandingsBracketV2 extends Component {
  constructor (props) {
    super()
    const brackets = standingsBracketUtil.tournamentHasLowerBracket(
      props.section.columns
    )
      ? standingsBracketUtil.splitBracket(props.section.columns)
      : [props.section.columns]

    this.setState({
      // canvasWidth: 0,
      // canvasHeight: 0,
      brackets
    })
  }

  // TODO: uncomment out and fix when standings lines are prioritized (need to uncomment out canvas and state setting above)
  componentDidMount () {
    // const canvasRef = this.canvasRef
    // const bracketRef = this.bracketRef
    // const componentRef = this.componentRef
    // const columns = this.props.section.columns
    // const shouldHaveMouseListeners = columns.length > maxShownColumns
    // const setCanvasDimensions = () => {
    //   const bracketRect = bracketRef?.getBoundingClientRect()
    //   if (bracketRect) {
    //     const canvasWidth = bracketRect.width
    //     const canvasHeight = bracketRect.height
    //     this.setState({ canvasWidth, canvasHeight })
    //   }
    // }
    // setCanvasDimensions()
    // let mousePosition = { left: 0, x: 0 }
    // const mouseMoveListener = (event) => {
    //   const dx = event.clientX - mousePosition.x
    //   // There are no setter functions for element scroll positions
    //   // eslint-disable-next-line functional/immutable-data
    //   componentRef.scrollLeft = mousePosition.left - dx
    // }
    // const mouseUpListener = () => {
    //   removeMouseListeners()
    // }
    // const mouseLeaveListener = () => {
    //   removeMouseListeners()
    // }
    // const toggledMouseListeners = [
    //   { key: 'mousemove', func: mouseMoveListener },
    //   { key: 'mouseup', func: mouseUpListener },
    //   { key: 'mouseleave', func: mouseLeaveListener }
    // ]
    // const removeMouseListeners = () => {
    //   toggledMouseListeners.forEach((listener) => {
    //     componentRef.removeEventListener(listener.key, listener.func)
    //   })
    // }
    // const mouseDownListener = (event) => {
    //   mousePosition = {
    //     left: componentRef.scrollLeft,
    //     x: event.clientX
    //   }
    //   toggledMouseListeners.forEach((listener) => {
    //     componentRef.addEventListener(listener.key, listener.func)
    //   })
    // }
    // componentRef.addEventListener('mousedown', mouseDownListener)
    // const getNormalizedRect = (element) => {
    //   const canvasRect = canvasRef?.getBoundingClientRect()
    //   const rect = element.getBoundingClientRect()
    //   return new DOMRect(
    //     rect.x - canvasRect.left,
    //     rect.y - canvasRect.top,
    //     rect.width,
    //     rect.height
    //   )
    // }
    // const drawGraph = () => {
    //   const context = canvasRef?.getContext('2d')
    //   if (!context) return
    //   context.clearRect(0, 0, canvasRef.width, canvasRef.height)
    //   componentRef
    //     .querySelectorAll(`.StandingsBracketV2Match`)
    //     .forEach((node) => {
    //       if (node instanceof HTMLElement) {
    //         const nodeRect = getNormalizedRect(node)
    //         const endX = nodeRect.left
    //         const endY = nodeRect.top + nodeRect.height / 2
    //         const drawToPrevMatch = (match) => {
    //           const matchRect = getNormalizedRect(match)
    //           const startX = matchRect.right
    //           const startY = matchRect.top + matchRect.height / 2
    //           standingsBracketUtil.drawLine(startX, startY, endX, endY, context)
    //         }
    //         const prevMatch1Id = node.dataset.prevMatch1
    //         const prevMatch2Id = node.dataset.prevMatch2
    //         if (prevMatch1Id) {
    //           const prevMatch1 = componentRef.querySelector(
    //             `#${prevMatch1Id}`
    //           )
    //           prevMatch1 && drawToPrevMatch(prevMatch1)
    //         }
    //         if (prevMatch2Id) {
    //           const prevMatch2 = componentRef.querySelector(
    //             `#${prevMatch2Id}`
    //           )
    //           prevMatch2 && drawToPrevMatch(prevMatch2)
    //         }
    //       }
    //     })
    // }
    // if (this.state.canvasWidth && this.state.canvasHeight) {
    //   const context = canvasRef?.getContext('2d')
    //   if (context) standingsBracketUtil.setContextSettings(context)
    //   drawGraph()
    // }
  }

  render (props) {
    const columns = props.section.columns

    const renderMatch = (match) => <StandingsBracketV2Match match={ match }/>

    const renderCell = (cell) => (
      <div class="cell">
        <div class="title">{ cell.name }</div>
        { cell.matches.map((match) => renderMatch(match)) }
      </div>
    )

    const renderColumn = (column) =>
      <div class="column">{ column.cells.map((cell) => renderCell(cell)) }</div>

    const renderRow = (columns) => (
      <div class="row">
        <div class="columns">
          { columns.map((column) => renderColumn(column)) }
        </div>
      </div>
    )

    const classes = util.classNames(
      this.constructor.name,
      `numColumns${columns.length}`,
      'scrollable'
    )

    return (
      <div
        class={ classes }
        ref={ (componentRef) => {
          this.componentRef = componentRef
        } }
        data-testid="component">
        <div
          class="bracket"
          ref={ (bracketRef) => {
            this.bracketRef = bracketRef
          } }>
          { !!this.state.brackets.length
            && this.state.brackets.map((columns) => renderRow(columns)) }
        </div>
        { /* <canvas
          ref={ canvasRef => { this.canvasRef = canvasRef }}
          style={ `width:${this.state.canvasWidth}px; height:${this.state.canvasHeight}px` }
          width={ this.state.canvasWidth * util.getPixelRatio() }
          height={ this.state.canvasHeight * util.getPixelRatio() }
          class="canvas"
        /> */ }
      </div>
    )
  }
}

export default StandingsBracketV2
