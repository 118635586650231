import { Component } from 'lib'
import Image from 'Component/Asset/Image/Image'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'

const sponsors = [
  {
    name: 'Alienware',
    logo: '/partners/alienware.png',
    size: '276x61',
    url: 'http://alienware.com/lolesports'
  },
  {
    name: 'Mastercard',
    logo: '/partners/mastercard.png',
    size: '172x141',
    url: 'https://twitter.com/mastercardnexus'
  },
  {
    name: 'Secret Lab',
    logo: '/partners/secret-lab.png',
    size: '228x73',
    url: 'https://secretlab.co/lolesports'
  },
  {
    name: 'State Farm',
    logo: '/partners/state-farm.png',
    size: '264x48',
    url: 'https://www.statefarm.com/'
  },
  {
    name: 'Red Bull',
    logo: '/partners/red-bull.png',
    size: '200x122',
    url: 'https://www.redbull.com'
  },
  {
    name: 'Oppo',
    logo: '/partners/oppo.png',
    size: '278x66',
    url: 'https://www.oppo.com'
  },
  {
    name: 'Axe Gaming',
    logo: '/partners/axe-gaming.png',
    size: '265x170',
    url: 'https://www.axe.com/us/en/gaming.html'
  }
]

const tournamentSlugs = ['allstar', 'worlds', 'msi']

class GlobalPartners extends Component {
  renderTournamentBug (slug) {
    const classes = util.classNames('bug', slug)

    return (
      <div
        class={ classes }
        style={ Image.background(`/partners/tournament-icons/${slug}.png`) }
      />
    )
  }

  renderSponsor (sponsor) {
    return (
      <a class="sponsor" href={ sponsor.url } target="_blank">
        <Image src={ sponsor.logo } size={ sponsor.size } alt={ sponsor.name }/>
      </a>
    )
  }

  render () {
    const year = new Date().getFullYear()

    return (
      <main class={ this.constructor.name }>
        <div class="header" style={ Image.background('/partners/header.png') }/>
        <div class="tournament-bugs">
          { tournamentSlugs.map(this.renderTournamentBug) }
        </div>
        <div class="content">
          <div class="context">
            { locale.translate('partners.context.leagueOfLegends', { year }) }
          </div>
          <h1 class="title">{ locale.translate('partners.title') }</h1>
          <div class="sponsors-list">{ sponsors.map(this.renderSponsor) }</div>
        </div>
      </main>
    )
  }
}

export default GlobalPartners
