import { Component, render, router, store } from 'lib'
import Container from 'Container/Container'
import TopMenu from 'Component/Navigation/TopMenu/TopMenu'
import Footer from 'Component/Navigation/Footer/Footer'
import InformNotifications from 'Component/Inform/Notifications/InformNotifications'
import google from 'Common/Service/Google/Google'
import qualaroo from 'Common/Service/Qualaroo/Qualaroo'
import settings from 'Common/Service/Settings/Settings'
import osano from 'Common/Service/Osano/Osano'
// import webSession from 'Common/Service/WebSession/WebSession'
import locale from 'Common/Locale/Locale'
import { setupDatadog } from './Common/Service/Datadog/Datadog'
import analytics from 'Common/Service/Analytics/Analytics'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import xssoStateTracker from 'Common/Auth/xsso'

// const PATH_HOME = 'Home/Home'
// const PATH_SCHEDULE = 'Schedule/Schedule'
// const PATH_NEWS = 'News/News'
// const PATH_VODS = 'Vods/Vods'
const PATH_STANDINGS = 'Standings/Standings'
const PATH_REWARDS = 'Rewards/Rewards'

class Index extends Component {
  authTimer = undefined

  state = {
    authHandled: false
  }

  constructor () {
    super()
    this.setupRoutes()

    // These services must be set up before the first router.go call so that
    // they receive all navigation events.
    qualaroo.setup()
    osano.setup()

    window.setTimeout(() => {
      google.setupMetrics()
    }, 1000)

    setupDatadog()
    analytics.initialize()

    router.redirect(window.location.pathname + window.location.search)
  }

  componentDidMount () {
    // if a user has an active XSSO session but XSSO has not loaded in yet
    // we add a bit of a buffer delay and full page loading status until RiotBar
    // has notified us that the auth check is complete (which won't fire if we are
    // not logged in). We verify this by checking for a local id hint cookie
    if (riotBar.hasLocalIdHint()) {
      this.authTimer = setTimeout(() => {
        this.setupSettingsAndGTM()
        this.setState({ authHandled: true })
      }, 3000)
    }
    else {
      this.setupSettingsAndGTM()
      this.setState({ authHandled: true })
    }

    store.onChange('riotBar.authChecked', () => {
      clearTimeout(this.authTimer)
      this.setupSettingsAndGTM()
      this.setState({ authHandled: true })
    })
  }

  componentWillUnmount () {
    clearTimeout(this.authTimer)
  }

  setupSettingsAndGTM () {
    settings.setup()
    google.setupTagManager()
    xssoStateTracker.initialize()
  }

  setupRoutes () {
    // router.add({
    //   route: ['*', '/'],
    //   render: () => this.renderContainer(PATH_HOME)
    // })

    // router.add({
    //   route: ['/news'],
    //   render: () => this.renderContainer(PATH_NEWS)
    // })

    // router.add({
    //   route: ['/schedule'],
    //   render: () => this.renderContainer(PATH_SCHEDULE)
    // })

    router.add({
      route: ['/rewards'],
      render: () => this.renderContainer(PATH_REWARDS)
    })

    router.add({
      route: [
        '/standings',
        '/standings/:leagueSlug',
        '/standings/:leagueSlug/:tournamentSlug',
        '/standings/:leagueSlug/:tournamentSlug/:stageSlug'
      ],
      render: () => this.renderContainer(PATH_STANDINGS)
    })

    router.add({
      route: [
        '/live',
        '/live/:leagueSlug',
        '/live/:leagueSlug/:streamId',
        '/watch',
        '/watch/:leagueSlug',
        '/watch/:leagueSlug/:streamId'
      ],
      render: () => this.renderContainer('Watch/Live/WatchLive')
    })

    router.add({
      route: [
        '/vod',
        '/vod/:matchId',
        '/vod/:matchId/:gameNumber',
        '/vod/:matchId/:gameNumber/:streamId'
      ],
      render: () => this.renderContainer('Watch/Vod/WatchVod')
    })

    // router.add({
    //   route: [
    //     '/vods',
    //     '/vods/:leagueSlug',
    //     '/vods/:leagueSlug/:tournamentSlug'
    //   ],
    //   render: () => this.renderContainer(PATH_VODS)
    // })

    router.add({
      route: ['/team/:teamSlug', '/teams/:teamSlug'],
      render: () => this.renderContainer('Team/Team')
    })

    router.add({
      route: ['/global-partners'],
      render: () => this.renderContainer('GlobalPartners/GlobalPartners')
    })

    // router.add({
    //   route: ['/video/:videoId', '/videos/:videoId'],
    //   render: () => this.renderContainer('Video/Video')
    // })

    // router.add({
    //   route: [
    //     '/article/:articleTitle/:articleId',
    //     '/articles/:articleTitle/:articleId'
    //   ],
    //   render: () => this.renderContainer('Article/Article')
    // })

    router.add({
      route: ['/settings', '/settings/:settingsTab'],
      render: () => this.renderContainer('Settings/Settings')
    })

    router.add({
      route: ['/pickem'],
      render: () => {
        location.replace('https://pickem.lolesports.com/')
        return this.renderContainer('Home/Home')
      }
    })
  }

  renderContainer (containerPath) {
    this.setState({ containerPath })
  }

  render () {
    return (
      <div class={ locale.get() }>
        <TopMenu/>
        { !this.state.authHandled
          ? <InformLoading/>
          : <Container path={ this.state.containerPath }/>
        }
        <Footer/>

        <InformNotifications/>
      </div>
    )
  }
}

render(<Index/>, document.body)

export default Index
