import { Component, Logger, store, $ } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'
import Image from 'Component/Asset/Image/Image'

// TODO: remove when ELDS exports the right translation for all stage names
const tempStageNameLocKeyMap = {
  '1v1_elim': 'standings.stage.1v1',
  '2v2_elim': 'standings.stage.2v2',
  'all_star_exhibition': 'standings.stage.exhibition',
  'opl_2019_split1_regular_season': 'temp.regularSeason',
  'cblol_2019_split1_regular_season': 'temp.regularSeason'
}

// Some stages are not supported by our code so we skip rendering them
// e.g. Swiss in some cases are not supported but is in some others
const excludedStageIds = [
  '112801310069239589', // [PROD] EMEA Masters - Swiss
  '112801310069239588', // [PROD] EMEA Masters - Play ins
  '106971698244100001' // [LOCAL] Worlds - play in groups
]

class StandingsTopNav extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addEventListeners()
  }

  addEventListeners () {
    this.toggleStandingsFilter = () => {
      store.set('standings.toggleFilter', !store.get('standings.toggleFilter'))
    }

    this.selectStage = (stageSlug) => {
      this.props.stageChangeHandler && this.props.stageChangeHandler(stageSlug)
    }

    $(document).on(
      'click',
      this.clickListener = (event) => {
        if (
          store.get('standings.toggleFilter')
          && !$(event.target).closest('.league-selector').length
        ) {
          store.set('standings.toggleFilter', false)
        }
      }
    )
  }

  componentWillUnmount () {
    $(document).off('click', this.clickListener)
  }

  renderStageSelectorOptions (stages, selectedStage) {
    return stages.map((stage) => {
      // skip unsupported stages
      if (excludedStageIds.includes(stage.id)) return

      const classes = util.classNames(
        'button',
        'stage-option',
        selectedStage.slug === stage.slug && 'selected'
      )

      return (
        <button class={ classes } onclick={ () => this.selectStage(stage.slug) }>
          { tempStageNameLocKeyMap[stage.slug] // TODO: remove when ELDS exports the right translation for all stage names
            ? locale.translate(tempStageNameLocKeyMap[stage.slug])
            : stage.name }
        </button>
      )
    })
  }

  renderStageSelector (stages, selectedStage) {
    return (
      <div class="stage-selector">
        <div class="title">{ locale.translate('label.stage') }</div>
        <div class="options">
          { this.renderStageSelectorOptions(stages, selectedStage) }
        </div>
      </div>
    )
  }

  renderLeague (league) {
    return (
      <a class="info">
        <Image class="image" src={ league.image } size="60"/>
        <div class="label">
          <div class="name">{ league.name }</div>
          <div class="region">{ league.region }</div>
        </div>
      </a>
    )
  }

  renderLeagueSelector (selectedLeague, filterActiveClass) {
    return (
      <div
        class="league-selector"
        onclick={ this.toggleStandingsFilter }
        role="button">
        <div class="league">
          { this.renderLeague(selectedLeague) }
          <Icon name="arrow" direction={ filterActiveClass ? 'up' : 'down' }/>
        </div>
      </div>
    )
  }

  render ({ stages, selectedStage, selectedLeague, filterActiveClass }) {
    if (!stages || !selectedLeague) return

    const componentClasses = util.classNames(
      this.constructor.name,
      filterActiveClass
    )

    return (
      <div class={ componentClasses }>
        <div class="selectors">
          { this.renderLeagueSelector(selectedLeague, filterActiveClass) }
        </div>
        { !!stages.length && this.renderStageSelector(stages, selectedStage) }
      </div>
    )
  }
}

export default StandingsTopNav
