import { Component, $ } from 'lib'
import StandingsBracketSwissMatch from 'Component/Standings/BracketSwiss/Match/StandingsBracketSwissMatch'
import StandingsBracketSwissUtil from 'Component/Standings/BracketSwiss/Util/StandingsBracketSwissUtil'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'
import Image from 'Component/Asset/Image/Image'

class StandingsBracketSwiss extends Component {
  constructor (props) {
    super()
    const brackets = StandingsBracketSwissUtil.splitBracket(
      props.section.columns
    )
    const advancedTeams = StandingsBracketSwissUtil.getAdvancedTeams()

    this.setState({
      brackets,
      advancedTeams
    })
  }

  setCanvasDimensions () {
    if (!this.bracketRef) return
    this.canvasRef.width = this.bracketRef.clientWidth
    this.canvasRef.height = this.bracketRef.clientHeight
    StandingsBracketSwissUtil.drawGraph(this)
  }

  componentDidMount () {
    this.setPaginationArrowState()
    this.componentRef.addEventListener('scroll', () => {
      clearTimeout(this.scrollTimeout)
      this.scrollTimeout = setTimeout(() => {
        this.setPaginationArrowState()
      }, 250)
    })

    $(window).on('resize', this.setCanvasDimensions.bind(this))
    this.setCanvasDimensions()
  }

  componentWillUnmount () {
    this.componentRef.removeEventListener('scroll', () => {
      clearTimeout(this.scrollTimeout)
      this.setPaginationArrowState()
    })

    $(window).off('resize', this.setCanvasDimensions.bind(this))
  }

  setPaginationArrowState () {
    const componentRef = this.componentRef

    if (!componentRef) return
    const scrollLeft = componentRef.scrollLeft
    const scrollWidth = componentRef.scrollWidth
    const componentWidth = componentRef.clientWidth
    const padding = 24

    const leftArrow = componentRef.querySelector('.left')
    const rightArrow = componentRef.querySelector('.right')

    if (scrollLeft < padding) {
      leftArrow.disabled = true
    }
    else if (scrollLeft > 0) {
      leftArrow.disabled = false
    }

    if (scrollWidth - scrollLeft <= componentWidth + padding) {
      rightArrow.disabled = true
    }
    else if (scrollWidth - scrollLeft > componentWidth + padding) {
      rightArrow.disabled = false
    }
  }

  scroll (direction) {
    const componentRef = this.componentRef
    const width = componentRef.querySelector('.column').clientWidth

    if (direction === 'left') {
      componentRef.scrollLeft -= width
    }
    else if (direction === 'right') {
      componentRef.scrollLeft += width
    }
  }

  getDate (date) {
    if (typeof date === 'string') {
      return locale.translate(Date.parse(date)).shortDayAndMonth
    }

    // for range
    return locale.translate([Date.parse(date[0]), Date.parse(date[1])])
      .shortDayAndMonth
  }

  renderMatch (match) {
    return <StandingsBracketSwissMatch match={ match }/>
  }

  renderCell (cell) {
    return (
      <div
        class={ `cell ${cell.bracketType}` }
        data-prev-cell1={ cell.prevCell1 }
        data-prev-cell2={ cell.prevCell2 }>
        <div class="title">
          <span class="score">
            { cell.wins }-{ cell.loses }
          </span>
          <span class="date">{ this.getDate(cell.date) }</span>
        </div>
        { cell.matches.map((match) => this.renderMatch(match)) }
      </div>
    )
  }

  renderColumns (column) {
    return (
      <div class="column">
        { column.cells.map((cell) =>
          cell.matches.length ? this.renderCell(cell) : null
        ) }
      </div>
    )
  }

  renderTeam (team, index) {
    const { name, image, code, slug } = team

    return (
      <a href={ slug ? `/teams/${slug}` : '#' } class="team">
        <Image class="logo" src={ image } size="36x36"/>
        <div class="name">{ name }</div>
        <div class="tricode">{ code || name }</div>
        <div class="rank">{ index + 1 }</div>
      </a>
    )
  }

  renderAdvancedTeams () {
    return (
      <div class="column">
        <div class="cell advanced-teams">
          <div class="title">
            { locale.translate('standings.stage.advanceToKnockouts') }
          </div>
          <div class="teams">
            { this.state.advancedTeams.map((team, index) =>
              this.renderTeam(team, index)
            ) }
          </div>
        </div>
      </div>
    )
  }

  renderPagination () {
    return (
      <div class="pagination">
        <button
          class="left"
          aria-label="pagination-left"
          onClick={ () => this.scroll('left') }>
          <Icon name="arrow" direction="left"/>
        </button>
        <button
          class="right"
          aria-label="pagination-right"
          onClick={ () => this.scroll('right') }
          disabled={ this.isDisabled }>
          <Icon name="arrow" direction="right"/>
        </button>
      </div>
    )
  }

  render (props) {
    const columns = props.section.columns
    const classes = util.classNames(
      this.constructor.name,
      `numColumns${columns.length}`,
      'scrollable'
    )

    return (
      <div
        class={ classes }
        ref={ (componentRef) => {
          this.componentRef = componentRef
        } }
        data-testid="component">
        { this.renderPagination() }
        <div
          class="bracket"
          ref={ (bracketRef) => {
            this.bracketRef = bracketRef
          } }>
          <div class="columns">
            { !!this.state.brackets.length
              && this.state.brackets.map((columns) => this.renderColumns(columns)) }
            { this.renderAdvancedTeams() }
          </div>
        </div>
        <canvas
          ref={ (canvasRef) => {
            this.canvasRef = canvasRef
          } }
          class="canvas"
        />
      </div>
    )
  }
}

export default StandingsBracketSwiss
