import { Component } from 'lib'
import Modal from 'Component/Modal/Modal'
import Autocomplete from 'Component/Autocomplete/Autocomplete'
import locale from 'Common/Locale/Locale'

class AddLeagueNotifications extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: ''
    }
  }

  buildOptions (leagues) {
    return leagues.map((league) => ({
      label: league.name,
      value: league.id,
      icon: league.image
    }))
  }

  render (props, state) {
    const { leagues, onConfirm, onCancel } = props
    const { value } = state

    const options = this.buildOptions(leagues)

    return (
      <Modal
        class="modal"
        title={ locale.translate('settings.notifications.addLeague') }
        onConfirm={ () => onConfirm(value) }
        onCancel={ () => onCancel() }>
        <Autocomplete
          class="autocomplete"
          options={ options }
          placeholder={ locale.translate(
            'settings.notifications.leaguePlaceholder'
          ) }
          onSelect={ (value) => this.setState({ value }) }
          value={ value }
        />
      </Modal>
    )
  }
}

export default AddLeagueNotifications
