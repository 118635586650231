import { Component } from 'lib'
import RewardsPassAvailabilityContent from 'Component/Rewards/PassAvailabilityContent/RewardsPassAvailabilityContent'
import Icon from 'Component/Asset/Icon/Icon'

class InformPassAvailability extends Component {
  componentWillMount () {
    this.addListeners()
  }

  addListeners () {
    this.dismiss = () => {
      this.props.onPassOverlayDismissed && this.props.onPassOverlayDismissed()
    }
  }

  renderModal () {
    return (
      <div class="modal">
        <div class="content">
          <div class="info">
            <div class="close" onclick={ this.dismiss } role="button">
              <Icon name="bubbleClose"/>
            </div>
            <RewardsPassAvailabilityContent
              isTeamPass={ this.props.isTeamPass }
            />
          </div>
        </div>
      </div>
    )
  }

  render () {
    return <div class={ this.constructor.name }>{ this.renderModal() }</div>
  }
}

export default InformPassAvailability
