import { Component, router, store } from 'lib'
import SettingsNotifications from 'Component/Settings/Notifications/SettingsNotifications'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import settingsService from 'Common/Service/Settings/Settings'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import SettingsGeneral from 'Component/Settings/General/SettingsGeneral'

const panelSlugs = ['general', 'notifications']

class Settings extends Component {
  constructor (props) {
    super(props)

    // If we go to /settings/skjdhfaskdjfh redirect to something useful
    if (panelSlugs.indexOf(router.param('settingsTab')) === -1) {
      router.updateUrl('/settings/general')
    }

    this.addListeners()
    this.state = {
      settings: store.get('settings')
    }
  }

  addListeners () {
    store.onChange(
      'settings',
      this.settingsListener = (settings) => this.setState({ settings })
    )
  }

  componentWillUnmount () {
    store.removeListener('settings', this.settingsListener)
  }

  updateSettings () {
    const { settings } = this.state

    settingsService
      .updatePreferences(settings)
      .catch((error) => this.setState({ error }))
  }

  onNotificationChange (update) {
    const notificationsSettings = {
      ...this.state.settings.notificationsSettings,
      ...update
    }

    const settings = {
      ...this.state.settings,
      notificationsSettings
    }

    this.setState({
      settings
    })

    this.updateSettings()
  }

  onGeneralChange (update) {
    const watchSettings = {
      ...this.state.settings.watchSettings,
      ...update
    }

    const settings = {
      ...this.state.settings,
      watchSettings
    }

    this.setState({
      settings
    })

    this.updateSettings()
  }

  onTeamChange (teams) {
    const topics = {
      ...this.state.settings.topics,
      teams
    }

    const settings = {
      ...this.state.settings,
      topics
    }

    this.setState({
      settings
    })

    this.updateSettings()
  }

  onLeagueChange (leagues) {
    const topics = {
      ...this.state.settings.topics,
      leagues
    }

    const settings = {
      ...this.state.settings,
      topics
    }

    this.setState({
      settings
    })

    this.updateSettings()
  }

  renderError () {
    const dismiss = () => this.setState({ error: null })

    return (
      <InformBubble theme="error" icon="error" onDismissHandler={ dismiss }>
        { locale.translate('message.unableToSave') }
      </InformBubble>
    )
  }

  renderLoginPrompt () {
    return (
      <div class="login">
        <p class="title">{ locale.translate('settings.login.title') }</p>
        <button class="button" onclick={ () => riotBar.login() }>
          { locale.translate('settings.login.button') }
        </button>
      </div>
    )
  }

  renderSidebar () {
    const link = (slug) => {
      const classes = util.classNames(
        'button',
        slug === router.param('settingsTab') && 'active'
      )

      return (
        <div
          class={ classes }
          onClick={ () => router.go(`/settings/${slug}`) }
          role="button">
          { locale.translate(`settings.sidebar.${slug}`) }
        </div>
      )
    }

    return (
      <div class="sidebar">
        <label class="label">
          { locale.translate('settings.sidebar.label') }
        </label>
        { panelSlugs.map(link) }
      </div>
    )
  }

  renderContent (isLoggedIn) {
    return (
      <div class="contents">
        { !isLoggedIn ? this.renderLoginPrompt() : this.renderSelectedSection() }
      </div>
    )
  }

  renderGeneral (settings) {
    return (
      <SettingsGeneral
        watchSettings={ settings.watchSettings }
        onSettingsChange={ (update) => this.onGeneralChange(update) }
      />
    )
  }

  renderNotifications (settings) {
    return (
      <SettingsNotifications
        notificationsSettings={ settings.notificationsSettings }
        topics={ settings.topics }
        onNotificationChange={ (update) => this.onNotificationChange(update) }
        onTeamChange={ (id) => this.onTeamChange(id) }
        onLeagueChange={ (id) => this.onLeagueChange(id) }
      />
    )
  }

  renderSelectedSection () {
    const { settings } = this.state

    // If settings have not come back yet, don't render anything.  This is
    // usually only the case if a user navigates directly to a settings page.
    if (!settings) return <InformLoading/>

    const panelSlug = router.param('settingsTab')

    switch (panelSlug) {
      case 'general':
        return this.renderGeneral(settings)
      case 'notifications':
        return this.renderNotifications(settings)
      default:
        router.updateUrl('/settings/general')
        return this.renderGeneral(settings)
    }
  }

  render () {
    const { error } = this.state
    const isLoggedIn = riotBar.isLoggedIn()

    return (
      <div class={ this.constructor.name }>
        { isLoggedIn && this.renderSidebar() }
        { this.renderContent(isLoggedIn) }
        { error && this.renderError() }
      </div>
    )
  }
}

export default Settings
