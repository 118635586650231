import { Component, router, $, Logger } from 'lib'
import locale from 'Common/Locale/Locale'
import device from 'Common/Device/Device'
import relApi from 'Common/Service/RelApi/RelApi'
import watchUtil from 'Container/Watch/Util/WatchUtil'
import Icon from 'Component/Asset/Icon/Icon'
import Image from 'Component/Asset/Image/Image'

const supportedVideoProviders = ['youtube', 'twitch']

class HomeLiveBanner extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  addListeners () {
    this.goToLiveEvent = (event) => {
      router.go('/live/' + event.league.slug)
    }

    $(document).on('mediaSizeChange', (event) => {
      this.setState({ deviceSize: event.data })
    })
  }

  componentWillMount () {
    this.setState({ deviceSize: device.getSize() })
  }

  componentDidMount () {
    if (this.props.event) {
      this.fetchEventDetails(this.props.event.id)
    }
  }

  fetchEventDetails (eventId) {
    return relApi
      .fetchEventDetails(eventId)
      .then((eventDetails) => {
        const filteredStreams = eventDetails.streams.filter((stream) =>
          supportedVideoProviders.includes(stream.provider)
        )

        const stream = filteredStreams.length
          ? watchUtil.getInitialPrioritizedStream(filteredStreams)
          : watchUtil.getInitialPrioritizedStream(eventDetails.streams)

        this.setState({ stream })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  renderVideoEmbed (videoSrc, title) {
    return (
      <iframe
        title={ title }
        class="video"
        height="410"
        width="730"
        src={ videoSrc }
        frameborder="0"
        allow="autoplay"
      />
    )
  }

  getIframeTitle (event) {
    return (
      `${event.league.name} - ${event.match.teams[0].code} `
      + `${locale.translate('match.vs')} ${event.match.teams[1].code}`
    )
  }

  getVideoSrc (stream) {
    switch (stream.provider) {
      case 'youtube':
        return (
          'https://www.youtube.com/embed/'
          + stream.parameter
          + '?autoplay=1&mute=1&controls=0'
        )
      case 'twitch':
        return (
          'https://player.twitch.tv/?channel='
          + stream.parameter
          + '&autoplay=true&muted=true&controls=false&volume=0&parent='
          + window.location.hostname
        )
      default:
        return this.log.warn('Unsupported stream provider', stream.provider)
    }
  }

  canRenderVideo (stream) {
    return supportedVideoProviders.indexOf(stream.provider) !== -1
  }

  renderVideoContainer (stream, event) {
    const canRenderVideo = this.canRenderVideo(stream)
    const isNotSmall = this.state.deviceSize !== 'small'

    return (
      <div class="video-container">
        { canRenderVideo
          && isNotSmall
          && this.renderVideoEmbed(
            this.getVideoSrc(stream),
            this.getIframeTitle(event)
          ) }
        <div class="watch-live">
          { locale.translate('home.liveBanner.watchLive') }
        </div>
      </div>
    )
  }

  renderHeader (event) {
    return (
      <div class="header">
        <Image
          class="logo"
          src={ event.match.teams[0].image }
          size="80"
          alt={ event.match.teams[0].code }
        />
        <div class="text">
          <div class="league-round">
            <span class="name">{ event.league.name }</span>
            <span class="hyphen">
              { event.blockName && Icon.unicode('spacedHyphen') }
            </span>
            <span class="round">{ event.blockName }</span>
          </div>
          <div class="title">
            { event.match.teams[0].code }
            <span class="vs">{ locale.translate('match.vs') }</span>
            { event.match.teams[1].code }
          </div>
        </div>
        <Image
          class="logo"
          src={ event.match.teams[1].image }
          size="80"
          alt={ event.match.teams[1].code }
        />
      </div>
    )
  }

  render () {
    if (!this.props.event || !this.state.stream || this.state.error) {
      return
    }

    // Adding an invisible overlay that covers the entire component and listens
    // for a click to fix for IE not allowing pointer events to be
    // disabled on the video (and thus preventing the watch live button
    // from being pressed)
    return (
      <div class={ this.constructor.name }>
        <div
          class="invisible-overlay-button"
          onclick={ () => this.goToLiveEvent(this.props.event) }
        />
        { this.renderHeader(this.props.event) }
        { this.state.stream
          && this.renderVideoContainer(this.state.stream, this.props.event) }
      </div>
    )
  }
}

export default HomeLiveBanner
