import { Component, $ } from 'lib'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'

class Modal extends Component {
  constructor (props) {
    super(props)

    $(document.body).addClass('no-scroll-small')
  }

  componentWillUnmount () {
    $(document.body).removeClass('no-scroll-small')
  }

  renderHeader (title, onCancel) {
    return (
      <div class="modal-header">
        <span class="title">{ title }</span>
        <button class="close" onClick={ () => onCancel() }>
          <Icon name="close"/>
        </button>
      </div>
    )
  }

  renderBody (children) {
    const classes = util.classNames('modal-body', this.props.class)

    return <div class={ classes }>{ children }</div>
  }

  renderFooter (onConfirm, onCancel) {
    return (
      <div class="modal-footer">
        <button class="cancel" onClick={ () => onCancel() }>
          { locale.translate('navigation.cancel') }
        </button>
        <button class="save" onClick={ () => onConfirm() }>
          { locale.translate('navigation.save') }
        </button>
      </div>
    )
  }

  render (props) {
    const { title, children, onConfirm, onCancel } = props

    return (
      <div class={ this.constructor.name }>
        <div class="modal-background"/>
        <div class="modal-content">
          { this.renderHeader(title, onCancel) }
          { this.renderBody(children) }
          { this.renderFooter(onConfirm, onCancel) }
        </div>
      </div>
    )
  }
}

export default Modal
