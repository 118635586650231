import { Component, $, Logger, store } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import dataDragon from 'Common/Service/DataDragon/DataDragon'
import Icon from 'Component/Asset/Icon/Icon'
import analytics from 'Common/Service/Analytics/Analytics'

const minGoldDiff = 300

class StatsTeamsPlayers extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.roles = dataDragon.getRoles()
    this.selectedRole = null
    this.primaryTeam = 'blue'
    this.highlightedRole = null
  }

  componentDidMount () {
    this.addListeners()
  }

  componentDidUpdate (prevProps) {
    // force refresh if game changes
    if (prevProps.gameId !== this.props.gameId) {
      this.comparePlayers(this.selectedRole)
    }
  }

  addListeners () {
    this.comparePlayers = (role, selectedPlayer, activeChange) => {
      $('.blue-team, .red-team')
        .find('.selected')
        .removeClass('selected primary secondary')
      const team1Player = $('.blue-team .player.' + role)
      const team2Player = $('.red-team .player.' + role)

      if (team2Player[0] === selectedPlayer) {
        team2Player.addClass('selected primary')
        team1Player.addClass('selected secondary')
        this.primaryTeam = 'red'
      }
      else {
        team1Player.addClass('selected primary')
        team2Player.addClass('selected secondary')
        this.primaryTeam = 'blue'
      }

      this.selectedRole = role
      const roleIndex = this.roles.indexOf(role)

      const blueTeamPlayer = this.props.blueTeamPlayers[roleIndex]
      const bluePlayerInfo = this.props.blueTeamInfo.participantMetadata[
        roleIndex
      ]

      const redTeamPlayer = this.props.redTeamPlayers[roleIndex]
      const redPlayerInfo = this.props.redTeamInfo.participantMetadata[
        roleIndex
      ]

      const primaryTeam = this.primaryTeam
      const gameId = this.props.gameId

      if (activeChange) {
        analytics.trackEvent('player_compare', {
          blueChamp: bluePlayerInfo.championId,
          bluePlayer: bluePlayerInfo.summonerName,
          bluePlayerId: bluePlayerInfo.esportsPlayerId,
          redChamp: redPlayerInfo.championId,
          redPlayer: redPlayerInfo.summonerName,
          redPlayerId: bluePlayerInfo.esportsPlayerId
        })
      }

      store.set('stats.players.compare', {
        role,
        blueTeamPlayer,
        bluePlayerInfo,
        redTeamPlayer,
        redPlayerInfo,
        primaryTeam,
        gameId
      })

      this.props.smallShowMatchupHandler
        && this.props.smallShowMatchupHandler(true)
    }

    this.compareTeamsPlayers = (event, role) => {
      this.comparePlayers(role, event.currentTarget, true)
    }

    this.onHover = (role, type) => {
      this.highlightedRole = type === 'enter' ? role : null
      $('.blue-team, .red-team')
        .find('.player.' + role)
        .toggleClass('highlighted')
    }

    store.onChange(
      'dataDragon.setup',
      this.onDataDragonSetup = (setup) => {
        if (setup) {
          this.comparePlayers(dataDragon.getDefaultRole())
        }
      }
    )
  }

  renderGoldDiff (role, index) {
    const { redTeamPlayers, blueTeamPlayers } = this.props
    const redPlayerGold = redTeamPlayers[index].totalGold
    const bluePlayerGold = blueTeamPlayers[index].totalGold
    const goldDiff = Math.abs(redPlayerGold - bluePlayerGold)
    const className = redPlayerGold > bluePlayerGold ? 'red' : 'blue'
    const goldDiffText = locale.shortNumberFormatter(goldDiff)
    const classes = util.classNames(
      'diff',
      role,
      className,
      goldDiff < minGoldDiff && 'hidden'
    )

    return (
      <div class="wrapper">
        <div class={ classes }>{ goldDiffText }</div>
      </div>
    )
  }

  renderGoldDiffs () {
    return (
      <div class="gold-diffs">
        { this.roles.map((role, index) => this.renderGoldDiff(role, index)) }
      </div>
    )
  }

  isDead (player) {
    return (
      player.maxHealth !== player.currentHealth && player.currentHealth <= 0
    )
  }

  getSelectedClasses (roleName, color) {
    if (roleName === this.selectedRole) {
      if (color === this.primaryTeam) {
        return 'selected primary'
      }
      else {
        return 'selected secondary'
      }
    }
    return
  }

  renderPlayer (player, playerMetadata, roleName, color) {
    const championImage = dataDragon.getChampionImage(playerMetadata.championId)

    const classNames = util.classNames(
      'player',
      roleName,
      this.getSelectedClasses(roleName, color),
      roleName === this.highlightedRole && 'highlighted',
      this.isDead(player) && 'dead'
    )

    return (
      <div
        role="button"
        class={ classNames }
        onclick={ (event) => this.compareTeamsPlayers(event, roleName) }
        onmouseenter={ () => this.onHover(roleName, 'enter') }
        onmouseleave={ () => this.onHover(roleName, 'leave') }>
        <div class="name">{ playerMetadata.summonerName }</div>
        <div class="portrait">
          <div class="wrapper">
            <img class="image" src={ championImage } alt=""/>
          </div>
          <div class="level">{ player.level }</div>
        </div>
        <div class="details">
          <div class="stat kda">
            <Icon name="kills" size="small"/>
            <span class="kills">{ player.kills }</span>
            &nbsp;/&nbsp;
            <span class="deaths">{ player.deaths }</span>
            &nbsp;/&nbsp;
            <span class="assists">{ player.assists }</span>
          </div>
          <div class="stat cs">
            <Icon name="cs"/>
            { player.creepScore }
          </div>
          <div class="stat gold">
            <Icon name="gold"/>
            { locale.shortNumberFormatter(player.totalGold) }
          </div>
        </div>
      </div>
    )
  }

  renderTeam (team, playerInfo, color) {
    return (
      <div class={ color + '-team' }>
        { this.roles.map((roleName) =>
          this.renderPlayer(
            team[this.roles.indexOf(roleName)],
            playerInfo.participantMetadata[this.roles.indexOf(roleName)],
            roleName,
            color
          )
        ) }
      </div>
    )
  }

  render (props) {
    // first render don't have data yet, render loading or error message
    if (!props.blueTeamPlayers || !props.redTeamPlayers) {
      return
    }

    return (
      <div class={ this.constructor.name }>
        { this.renderTeam(
          this.props.blueTeamPlayers,
          this.props.blueTeamInfo,
          'blue'
        ) }
        { this.renderGoldDiffs() }
        { this.renderTeam(
          this.props.redTeamPlayers,
          this.props.redTeamInfo,
          'red'
        ) }
      </div>
    )
  }
}

export default StatsTeamsPlayers
