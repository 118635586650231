import { Component, Logger } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'

// Taken from EMP
class Dropdown extends Component {
  constructor (props) {
    super(props)
    this.log = new Logger(this.constructor.name)

    this.state = {
      active: false
    }
  }

  componentWillMount () {
    // If the initial value is invalid, try to correct this by asking the parent
    // to select the first value in the list of options.  This ensures that the
    // dropdown is always in a valid state.
    const selectedOption = this.getSelectedOption()

    if (selectedOption && selectedOption.value !== this.props.value) {
      this.props.onSelect(this.getSelectedOption())
    }
  }

  getSelectedOption () {
    if (this.props.value) {
      return this.props.options.find(
        (option) => option.value === this.props.value
      )
    }

    return this.props.options[0]
  }

  hideMenu () {
    this.setState({ active: false })
  }

  handleEvent (event) {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }

    return event
  }

  toggleMenu (event) {
    event && this.handleEvent(event)
    this.setState({ active: !this.state.active })
  }

  renderCurrentOption () {
    const option = this.getSelectedOption()
    const classes = util.classNames('currentOption')

    if (!option) {
      this.log.error('No current option found.')
      return
    }

    return (
      <div
        role="button"
        class={ classes }
        onClick={ (event) => {
          this.toggleMenu(event)
        } }>
        { <Icon name={ option.icon }/> }
        <div class="text">{ option.label }</div>
        { this.renderChevron() }
      </div>
    )
  }

  renderChevron () {
    return (
      <div class="chevron">
        <Icon name="arrow" direction="down"/>
      </div>
    )
  }

  selectOption (option) {
    this.toggleMenu()
    this.props.onSelect(option.value)
  }

  renderOption (option, selectedValue) {
    const classes = util.classNames(
      'option',
      option.value === selectedValue && 'selected'
    )

    return (
      <div
        class={ classes }
        onClick={ () => this.selectOption(option) }
        role="button">
        { <Icon name={ option.icon }/> }
        <div class="text">{ option.label }</div>
      </div>
    )
  }

  renderOptionsMenu (options) {
    const { value } = this.getSelectedOption()

    const classes = util.classNames('options')

    return (
      <div class={ classes }>
        { options.map((option) => this.renderOption(option, value)) }
      </div>
    )
  }

  render () {
    const { options } = this.props
    const { active } = this.state

    const classes = util.classNames(this.constructor.name, active && 'open')

    if (!options || !options.length) {
      this.log.error('Dropdown initialized with no available options.')
      return
    }

    return (
      <div class={ classes } tabIndex={ 0 } onBlur={ () => this.hideMenu() }>
        { this.renderCurrentOption() }
        { active && this.renderOptionsMenu(options) }
      </div>
    )
  }
}

export default Dropdown
