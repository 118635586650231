import { Component, $, Logger } from 'lib'
import device from 'Common/Device/Device'

const ROTATION_TIMER_MS = 3000

let adRotationTimeoutId = 0

// Partner is chosen instead of Sponsor to get around adblockers
class HomePartner extends Component {
  constructor (props) {
    super(props)

    this.log = new Logger(this.constructor.name)

    const filteredSponsors = this.filterSponsors(props.sponsors)

    if (filteredSponsors.length) {
      const adIndex = Date.now() % filteredSponsors.length

      this.setState(
        {
          deviceSize: device.getSize(),
          sponsors: filteredSponsors,
          adIndex
        },
        () => {
          this.startRotation()
        }
      )

      this.addListeners()
    }
  }

  addListeners () {
    $(document).on('mediaSizeChange', (event) => {
      this.setState({ deviceSize: event.data })
    })
  }

  componentWillUnmount () {
    $(document).off('mediaSizeChange')
  }

  filterSponsors (sponsors) {
    const filteredSponsors = []

    sponsors.forEach((sponsor) => {
      if (!sponsor.banner_image || !sponsor.block_image) {
        this.log.warn(
          `Sponsor images not published for sponsor ${sponsor.title}. Re-publish sponsor on Contentstack.`
        )
      }
      else {
        filteredSponsors.push(sponsor)
      }
    })
    return filteredSponsors
  }

  startRotation (maxRotations) {
    if (maxRotations === 0 || this.state.sponsors.length <= 1) return

    adRotationTimeoutId = window.setTimeout(() => {
      this.startRotation(maxRotations - 1)
      const adIndex = this.getNextAdIndex(this.state.adIndex)

      this.setState({ adIndex })
    }, ROTATION_TIMER_MS)
  }

  stopRotation () {
    window.clearTimeout(adRotationTimeoutId)
  }

  getNextAdIndex (currentIndex) {
    return (currentIndex + 1) % this.state.sponsors.length
  }

  getPrevAdIndex (currentIndex) {
    return currentIndex === 0
      ? this.state.sponsors.length - 1
      : currentIndex - 1
  }

  renderPartner (sponsor) {
    const imageUrl
      = this.state.deviceSize === 'medium'
        ? sponsor.banner_image.url
        : sponsor.block_image.url

    return (
      <a key={ sponsor.title } class="partner" href={ sponsor.url } target="_blank">
        <img class="partner-image" src={ imageUrl } alt={ sponsor.title }/>
      </a>
    )
  }

  render () {
    const { sponsors, adIndex } = this.state
    // avoid throwing an error if no sponsors are passed in

    if (!sponsors || sponsors.length === 0) return

    return (
      <div
        class={ this.constructor.name }
        onMouseEnter={ () => this.stopRotation() }
        onMouseLeave={ () => this.startRotation() }>
        { this.renderPartner(sponsors[adIndex]) }
        { this.renderPartner(sponsors[this.getPrevAdIndex(adIndex)]) }
      </div>
    )
  }
}

export default HomePartner
