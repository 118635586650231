import { Component } from 'lib'
import Modal from 'Component/Modal/Modal'
import Autocomplete from 'Component/Autocomplete/Autocomplete'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'

class AddTeamsNotifications extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: ''
    }
  }

  buildOptions (leagues, teams) {
    return leagues
      .map((league) => ({
        label: league.name,
        value: league.id,
        icon: league.image,
        menu: teams
          .filter(
            (team) =>
              util.getDeepValue(team, 'homeLeague.name') === league.name
              && team.status === 'active'
          )
          .map((team) => ({
            label: team.name,
            value: team.id,
            icon: team.image
          }))
        // Filter out leagues without teams (menu[] = list of teams)
        // Usually international leagues like worlds, msi and all-star)
      }))
      .filter((option) => option.menu.length > 0)
  }

  render (props, state) {
    const { leagues, teams, onConfirm, onCancel } = props
    const { value } = state

    const options = this.buildOptions(leagues, teams)

    return (
      <Modal
        class="modal"
        title={ locale.translate('settings.notifications.addTeams') }
        onConfirm={ () => onConfirm(value) }
        onCancel={ () => onCancel() }>
        <Autocomplete
          class="autocomplete"
          options={ options }
          placeholder={ locale.translate(
            'settings.notifications.teamsPlaceholder'
          ) }
          onSelect={ (value) => this.setState({ value }) }
          value={ value }
        />
      </Modal>
    )
  }
}

export default AddTeamsNotifications
