import { Component } from 'lib'
import locale from 'Common/Locale/Locale'
import Icon from 'Component/Asset/Icon/Icon'

class StatsTeamsSummary extends Component {
  constructor () {
    super()
    this.gold = { blueTeam: 0, redTeam: 0 }
  }

  renderGoldBar (blueTeam, redTeam) {
    this.gold.blueTeam
      = blueTeam && blueTeam.totalGold ? blueTeam.totalGold : this.gold.blueTeam
    this.gold.redTeam
      = redTeam && redTeam.totalGold ? redTeam.totalGold : this.gold.redTeam

    const total = this.gold.blueTeam + this.gold.redTeam
    const blueTeamWidth = this.gold.blueTeam / total
    const redTeamWidth = this.gold.redTeam / total

    return [
      <div class="blue-team" style={ 'flex:' + blueTeamWidth }/>,
      <div class="red-team" style={ 'flex:' + redTeamWidth }/>
    ]
  }

  renderDragons (blueTeam, redTeam) {
    const dragonList = (dragons, reverse = false) => {
      if (dragons.length === 0) {
        return ''
      }

      // dragons on the right side (red) show in reverse order (right to left)
      // reverse() is is destructive. We slice() it to create a copy
      const list = reverse ? dragons.slice().reverse() : dragons

      return list.map((name) => (
        <div class={ 'dragon ' + name }>
          <Icon name={ name + 'Dragon' }/>
        </div>
      ))
    }

    return (
      <div class="dragons">
        <div class="blue-team">{ dragonList(blueTeam.dragons) }</div>
        <div class="title">{ locale.translate('stats.dragons') }</div>
        <div class="red-team">{ dragonList(redTeam.dragons, true) }</div>
      </div>
    )
  }

  renderGold (blueTeam, redTeam) {
    return (
      <div class="gold">
        <div class="bar">{ this.renderGoldBar(blueTeam, redTeam) }</div>
        <div class="totals">
          <div class="blue-team">{ locale.shortNumberFormatter(blueTeam.totalGold) }</div>
          <div class="title">{ locale.translate('stats.gold') }</div>
          <div class="red-team">{ locale.shortNumberFormatter(redTeam.totalGold) }</div>
        </div>
      </div>
    )
  }

  renderTeam (team, color) {
    return (
      <div class={ color + '-team' }>
        <div class="stat inhibitors">
          <Icon name="inhibitors"/>
          { team.inhibitors }
        </div>
        <div class="stat barons">
          <Icon name="barons"/>
          { team.barons }
        </div>
        <div class="stat towers">
          <Icon name="towers"/>
          { team.towers }
        </div>
        <div class="stat kills">
          <Icon name="kills"/>
          { team.totalKills }
        </div>
      </div>
    )
  }

  renderDetails (blueTeam, redTeam) {
    return (
      <div class="details">
        { this.renderTeam(blueTeam, 'blue') }
        { this.renderTeam(redTeam, 'red') }
      </div>
    )
  }

  render () {
    // first render don't have data yet, render loading or error message
    if (!this.props.blueTeam || !this.props.redTeam) {
      return
    }

    return (
      <div class={ this.constructor.name }>
        { this.renderDragons(this.props.blueTeam, this.props.redTeam) }
        { this.renderGold(this.props.blueTeam, this.props.redTeam) }
        { this.renderDetails(this.props.blueTeam, this.props.redTeam) }
      </div>
    )
  }
}

export default StatsTeamsSummary
