import { Component, Logger, store } from 'lib'
import dataDragon from 'Common/Service/DataDragon/DataDragon'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'

class StatsMatchupAbilities extends Component {
  constructor () {
    super()
    this.keys = dataDragon.getAbilityKeys()
    this.log = new Logger(this.constructor.name)
  }

  componentDidMount () {
    this.addEventListeners()
  }

  componentWillUnmount () {
    store.removeListener('stats.players.compare', this.loadedPlayers)
    store.removeListener('dataDragon.setup', this.onDataDragonSetup)
  }

  addEventListeners () {
    store.onChange(
      'stats.players.compare',
      this.loadedPlayers = (data) => {
        const championIdPlayer1 = util.getDeepValue(
          data,
          'bluePlayerInfo.championId'
        )
        const championIdPlayer2 = util.getDeepValue(
          data,
          'redPlayerInfo.championId'
        )

        if (championIdPlayer1 && championIdPlayer2) {
          this.fetchDataDragonData(championIdPlayer1, championIdPlayer2)
        }
      }
    )

    store.onChange(
      'dataDragon.setup',
      this.onDataDragonSetup = (setup) => {
        if (setup) {
          this.fetchDataDragonData(
            this.props.bluePlayerInfo.championId,
            this.props.redPlayerInfo.championId
          )
        }
      }
    )

    this.toggleAbilityPane = (abilityPaneInfo) => {
      this.setState({ abilityPaneInfo })
    }
  }

  fetchDataDragonData (championIdPlayer1, championIdPlayer2) {
    this.log.debug(
      "Fetching Data Dragon with champion ID's",
      championIdPlayer1,
      championIdPlayer2
    )
    return Promise.all([
      dataDragon.fetchChampion(championIdPlayer1),
      dataDragon.fetchChampion(championIdPlayer2)
    ])
      .then((jsons) => {
        this.setState({
          player1Champion: jsons[0].data[championIdPlayer1],
          player2Champion: jsons[1].data[championIdPlayer2]
        })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  renderAbility (ability, key, isPassive) {
    const image = isPassive
      ? dataDragon.getPassiveAbilityImage(ability.image.full)
      : dataDragon.getAbilityImage(ability.image.full)

    const abilityClasses = util.classNames('ability', isPassive && 'passive')
    const sanitizedDescription = dataDragon.sanitizeDescription(
      ability.description
    )

    const abilityInfo = {
      image: image,
      name: ability.name,
      description: sanitizedDescription
    }

    return (
      <div
        class={ abilityClasses }
        onclick={ () => this.toggleAbilityPane(abilityInfo) }>
        <div class="ability-image">
          <img class="image" src={ abilityInfo.image } alt=""/>
        </div>
        { key && <div class="key">{ key }</div> }
        <div class="detail">
          <div class="name">{ abilityInfo.name }</div>
          <div class="description">{ abilityInfo.description }</div>
        </div>
      </div>
    )
  }

  renderAbilitiesHeader (champion) {
    const passive = champion.passive
    const spells = champion.spells

    return (
      <div class="header">
        { this.renderAbility(passive, false, true) }
        { this.keys.map((key, index) =>
          this.renderAbility(spells[index], key, false)
        ) }
      </div>
    )
  }

  renderAbilitiesPerLevel (selected, level) {
    const getClass = (selected, key) =>
      selected === key ? 'key selected' : 'key'

    return (
      <div class="row">
        <div class="level">{ level }</div>
        { this.keys.map((key) =>
          <div class={ getClass(key, selected) }>{ key }</div>
        ) }
      </div>
    )
  }

  renderAbilities (player, champion, teamColor) {
    const playerClasses = util.classNames(
      'player',
      teamColor === this.props.primaryTeam ? 'primary' : 'secondary'
    )

    return (
      <div class={ playerClasses }>
        <div class="wrapper">
          { this.renderAbilitiesHeader(champion) }
          { player.abilities.map((
            id,
            index // index + 1: level start at 1
          ) => this.renderAbilitiesPerLevel(id, index + 1)) }
        </div>
      </div>
    )
  }

  renderSmallAbilityPane (abilityInfo) {
    return (
      <div class="ability-pane">
        <div class="top">
          <div
            class="hide-pane-button"
            onclick={ () => this.toggleAbilityPane(undefined) }>
            <Icon name="arrow" direction="left"/>
          </div>
          <div class="ability-basics">
            <img class="image" src={ abilityInfo.image } alt=""/>
            <div class="name">{ abilityInfo.name }</div>
          </div>
        </div>
        <div class="bottom">
          <div class="description">{ abilityInfo.description }</div>
        </div>
      </div>
    )
  }

  render () {
    if (!this.props.bluePlayerStats || !this.props.redPlayerStats) {
      this.log.error('Missing player props:', this.props)
      return
    }

    if (!this.state.player1Champion || !this.state.player2Champion) {
      return // dataDragon setup not called yet
    }

    const bluePlayerStats = this.props.bluePlayerStats
    const redPlayerStats = this.props.redPlayerStats
    const player1Champion = this.state.player1Champion
    const player2Champion = this.state.player2Champion

    return (
      <div class={ this.constructor.name }>
        <div class="abilities">
          { this.renderAbilities(bluePlayerStats, player1Champion, 'blue') }
          { this.renderAbilities(redPlayerStats, player2Champion, 'red') }
          { this.state.abilityPaneInfo
            && this.renderSmallAbilityPane(this.state.abilityPaneInfo) }
        </div>
      </div>
    )
  }
}

export default StatsMatchupAbilities
