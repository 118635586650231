import { Component } from 'lib'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'

// USAGE:
// <InformBubble theme="{default|error}" icon="{name in Icon.js}" timeoutMs="5000">
//   { '<a href="" class="link">LOG IN</a> to earn rewards!' }
// </InformBubble>
// Note that this doesn't work with passing in JSX objects into the body of the element (yet)
class InformBubble extends Component {
  constructor () {
    super()
    this.addListeners()
  }

  addListeners () {
    this.dismiss = () => {
      this.setState({ display: 'none' })
      this.props.onDismissHandler && this.props.onDismissHandler()
    }
  }

  render () {
    if (this.props.timeoutMs) {
      window.setTimeout(this.dismiss, this.props.timeoutMs)
    }

    const classes = util.classNames(
      this.constructor.name,
      this.state.display,
      this.props.theme
    )

    return (
      <div class={ classes } style={ this.props.style }>
        <div class="indicator">
          <Icon name={ this.props.icon }/>
        </div>
        <div class="content">{ this.props.children }</div>
        <div class="close" onClick={ this.dismiss }>
          <Icon name="bubbleClose"/>
        </div>
      </div>
    )
  }
}

export default InformBubble
