import Image from 'Component/Asset/Image/Image'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import { Component, router } from 'lib'

const contentType = {
  articles: 'article',
  youtube_videos: 'video'
}

class HomeHero extends Component {
  render ({ url, reference, type, title, cta }) {
    const sizes = { medium: 1100, small: 700 }

    return (
      <a
        class={ this.constructor.name }
        style={ Image.background(url, sizes) }
        href={ reference.external_link }
        target="_blank"
        onClick={ (event) => this.goToArticle(event, reference) }>
        <div class="content">
          <div class="type">
            { locale.translate(`home.contentType.${contentType[type]}`) }
          </div>
          <div class="title">{ title }</div>
          <button
            class="cta"
            onClick={ (event) => this.goToArticle(event, reference) }>
            { cta }
          </button>
        </div>
      </a>
    )
  }

  contentIsExternalLink (article) {
    return article.external_link && article.external_link !== ''
  }

  goToArticle (event, article) {
    if (!this.contentIsExternalLink(article)) {
      event.preventDefault()
      event.stopPropagation()
      router.go(`/article/${util.toSlug(article.title)}/${article.uid}`)
    }
  }
}

export default HomeHero
