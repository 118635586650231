import { Component } from 'lib'
import statsMatchupUtil from 'Component/Stats/Matchup/Util/StatsMatchupUtil'
import util from 'Common/Util/Util'

const performanceMap = {
  kda: 'kda',
  goldEarned: 'number',
  minionKills: 'number',
  killParticipation: 'percent',
  championDamageShare: 'percent',
  wardsPlaced: 'number',
  wardsDestroyed: 'number'
}

class StatsMatchupPerformance extends Component {
  renderPerformance (player, teamColor) {
    const localeKey = 'performance'

    const classes = util.classNames(
      'player',
      teamColor === this.props.primaryTeam ? 'primary' : 'secondary'
    )

    return (
      <div class={ classes }>
        { Object.keys(performanceMap).map((stat) =>
          statsMatchupUtil.renderStat(
            player,
            stat,
            performanceMap[stat],
            localeKey
          )
        ) }
      </div>
    )
  }

  render () {
    return (
      <div class={ this.constructor.name }>
        { this.renderPerformance(this.props.bluePlayer, 'blue') }
        { this.renderPerformance(this.props.redPlayer, 'red') }
      </div>
    )
  }
}

export default StatsMatchupPerformance
