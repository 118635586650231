import { Component, $, store } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import util from 'Common/Util/Util'

class LeaguesDrawer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      show: false
    }

    this.addListeners()
  }

  componentDidMount () {
    // setTimeout required here to register the state change, which triggers
    // the sliding drawer transition when the component enters
    window.setTimeout(() => {
      this.setState({ show: true })
    }, 0)
  }

  componentWillUnmount () {
    this.removeListeners()
  }

  addListeners () {
    // close the LeaguesDrawer when clicking either outside of its parent
    // container, or on the Leagues menu item
    $(document).on(
      'click',
      this.clickListener = (event) => {
        const target = $(event.target)

        if (
          !target.closest('.navmenu-drawer').length
          && !target.closest('a[href="#leagues"]').length
        ) {
          this.props.setActiveLeague(-1)
          store.set('leaguesMenu.isActive', false)
        }
      }
    )
  }

  removeListeners () {
    $(document).off('click', this.clickListener)
  }

  getLeaguesMenuItemText () {
    const leaguesMenuItem = $('a[href="#leagues"]')

    return leaguesMenuItem.length ? leaguesMenuItem.html() : ''
  }

  closeMenu () {
    this.props.setActiveLeague(-1)
    store.set('leaguesMenu.isActive', false)
    $('.sub-menu-header .sub-menu-close').trigger('click')
  }

  onMenuClick (event) {
    // stop the event from bubbling up and causing the mobile menu to close
    event.stopPropagation()
    const target = $(event.target)

    if (target.closest('.sub-menu-back').length) {
      this.setState({ show: false })
    }
    else if (target.closest('.leagues-sub-menu-close').length) {
      this.closeMenu()
    }
    else if (target.closest('.league-item').length) {
      const menuItem = target.closest('.item')

      this.props.setActiveLeague(menuItem.index())
    }
  }

  onTransitionEnd () {
    if (this.state.show) return
    if (this.props.level === 1) {
      store.set('leaguesMenu.isActive', false)
    }
    else if (this.props.level === 2) {
      this.props.setActiveLeague(-1)
    }
  }

  renderLevelOneItem (item) {
    const title = this.props.getLeagueTitleWithRegion(item, this.props.leagues)

    return (
      <a class="item league-item">
        <div class="title">{ title }</div>
        <span class="side-menu-icon">
          <Icon name="riotBarArrow" direction="right"/>
        </span>
      </a>
    )
  }

  renderLevelTwoItem (item) {
    return (
      <a class="item" href={ item.href }>
        <div class="title">{ item.title }</div>
      </a>
    )
  }

  renderLoading () {
    return (
      <div class="leagues-menu-loading">
        <InformLoading/>
      </div>
    )
  }

  renderContent () {
    const { items, activeLeagueIndex } = this.props
    const league
      = activeLeagueIndex === -1 ? items[0] : items[activeLeagueIndex]

    const title
      = this.props.level === 1
        ? this.getLeaguesMenuItemText()
        : league.league[0].title

    const menuItems
      = this.props.level === 1
        ? this.props.items.map((item) => this.renderLevelOneItem(item))
        : league.links.map((item) => this.renderLevelTwoItem(item))

    return (
      <div class="leagues-sub-menu">
        <div class="leagues-sub-menu-header">
          <a class="sub-menu-back">
            <Icon name="riotBarArrow" direction="left"/>
          </a>
          <span class="sub-menu-header-text title">{ title }</span>
          <a class="leagues-sub-menu-close">
            <Icon name="riotBarSubMenuClose"/>
          </a>
        </div>
        <div class="menu-items">
          <div class="list">{ menuItems }</div>
        </div>
      </div>
    )
  }

  render () {
    const { items, leagues, error } = this.props
    const contentLoaded = items && leagues && !error

    const classes = util.classNames(
      this.constructor.name,
      this.state.show && 'show'
    )

    return (
      <div
        class={ classes }
        role="button"
        onClick={ (event) => this.onMenuClick(event) }
        onTransitionEnd={ () => this.onTransitionEnd() }>
        { contentLoaded ? this.renderContent() : this.renderLoading() }
      </div>
    )
  }
}

export default LeaguesDrawer
