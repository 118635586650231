import { Component, Logger } from 'lib'
import StandingsBracketMatch from 'Component/Standings/Bracket/Match/StandingsBracketMatch'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'

class StandingsBracketFallback extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  isMatchThirdPlace (match) {
    return match.flags && match.flags.indexOf('thirdPlace') !== -1
  }

  renderMatch (match) {
    const thirdPlaceTitle = this.isMatchThirdPlace(match)
      && <div class="title">{ locale.translate('standings.round.thirdPlace') }</div>

    return (
      <div class="match">
        { thirdPlaceTitle }
        <StandingsBracketMatch match={ match }/>
      </div>
    )
  }

  renderFallbackBracket (stage) {
    return stage.sections.map((section) => (
      <div class="round">
        <div class="title">{ section.name }</div>
        { section.matches.map((match) => this.renderMatch(match)) }
      </div>
    ))
  }

  render () {
    if (!this.props.stage) {
      return
    }

    const componentClasses = util.classNames(
      this.constructor.name,
      this.props.gauntlet && 'gauntlet'
    )

    return (
      <div class={ componentClasses }>
        { this.renderFallbackBracket(this.props.stage) }
      </div>
    )
  }
}

export default StandingsBracketFallback
