/* eslint max-lines: ["error", {"max": 500, "skipBlankLines": true, "skipComments": true}] */
import { Component, Logger, $, router } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import Image from 'Component/Asset/Image/Image'
import device from 'Common/Device/Device'
import contentstack from 'Common/Service/Contentstack/Contentstack'
import util from 'Common/Util/Util'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import locale from 'Common/Locale/Locale'
import markdown from '@riotgames/markdown-parser'

// TODO: Remove this once we have a better system for assigning branded styles
const brandedArticles = ['bltf8d3b256324734e5']

const imageLoadTimeout = 3 * util.times.SECONDS

class Article extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.setState({
      deviceSize: device.getSize(),
      isBranded: false,
      imagesLoaded: { header: false, background: false }
    })

    this.addListeners()

    window.setTimeout(() => {
      this.setState({ imagesLoaded: { header: true, background: true } })
    }, imageLoadTimeout)
  }

  componentDidMount () {
    const articleId = router.param('articleId')

    this.fetchArticleDetail(articleId)
  }

  addListeners () {
    $(document).on('mediaSizeChange', (event) => {
      this.setState({ deviceSize: event.data })
    })
  }

  fetchArticleDetail (articleId) {
    return contentstack
      .fetchArticleDetail(articleId)
      .then((article) => {
        if (article.external_link && article.external_link !== '') {
          window.location.href = article.external_link
        }
        this.setState({ article })
        this.setState({
          isBranded: brandedArticles.indexOf(article.uid) !== -1
        })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  setImageLoaded (imageType) {
    const imagesLoaded = this.state.imagesLoaded

    imagesLoaded[imageType] = true
    this.setState({ imagesLoaded })
  }

  renderBackground (article) {
    const backgroundStyle = article.header_image ? 'tall' : 'short'
    const classes = util.classNames('background', backgroundStyle)
    const imgSrc = article.league.article_background
      ? article.league.article_background.url
      : '/article/header-fallback.png'

    return (
      <div class={ classes }>
        <Image
          onLoad={ () => this.setImageLoaded('background') }
          class="image"
          src={ imgSrc }
          size="1600x650"
        />
      </div>
    )
  }

  renderHeaderImage (article) {
    // In the case of an announcement being rendered (which has no article
    // title image), place a dummy image block to ensure that content is pushed
    // down and the league icon is aligned
    return article.header_image ? (
      <Image
        onLoad={ () => this.setImageLoaded('header') }
        class="title-img"
        src={ article.header_image.url }
        size="1600x900"
      />
    )
      : <div class="title-img-dummy"/>
  }

  renderCredits (isoDate, author) {
    const date = locale.translate(Date.parse(isoDate))

    return (
      <div class="date-author">
        { date.dayMonthAndYear }
        { author
          && Icon.unicode('spacedHyphen')
            + locale.translate('content.article.by', { author }) }
      </div>
    )
  }

  renderImageHeader (imageHeader) {
    const { eyebrow, image, text } = imageHeader
    const imageHeaderStyle = `background-image: url("${image.url}")`

    return (
      <div class="img-header">
        <div class="img-header-bg" style={ imageHeaderStyle }>
          <div class="img-header-content">
            { eyebrow && <div class="eyebrow">{ eyebrow }</div> }
            { text && <div class="text">{ text }</div> }
          </div>
        </div>
      </div>
    )
  }

  formatBlocks (text) {
    // Manually removes newlines and empty blocks, and then adds back
    // paragraphs and newlines to ensure that in-line spacing
    // is respected but not excessive
    const removedNewLines = text
      .split('\n\n')
      .filter((block) => block.length > 0)

    const addedParagraphs = removedNewLines.map((block) =>
      block.charAt(0) !== '<'
      || block.slice(0, 3) === '<b>'
      || block.slice(0, 3) === '<i>'
      || block.slice(0, 2) === '<a'
        ? '<p>' + block + '</p>'
        : block
    )

    return addedParagraphs.join('\n')
  }

  renderTextBlockImage (image) {
    if (!image.image) return

    const imageComponent
      = <Image class="image" src={ image.image.url } size="70"/>

    if (image.link === '') {
      return imageComponent
    }

    return (
      <a class="img-link" href={ image.link } target="_blank">
        { imageComponent }
      </a>
    )
  }

  renderTextBlock (textBlock, isCentered) {
    const classes = util.classNames('text-block', isCentered && 'centered')
    const text = this.formatBlocks(markdown.parse(textBlock.text))
    const image = util.getDeepValue(textBlock, 'image')

    return (
      <div class={ classes }>
        <div class="text" dangerouslySetInnerHTML={ { __html: text } }/>
        { image.image
          && <div class="text-block-img">{ this.renderTextBlockImage(image) }</div>
        }
      </div>
    )
  }

  renderInlineQuote (textInlineQuote, isCentered) {
    const classes = util.classNames('inline-quote', isCentered && 'centered')
    const text = textInlineQuote.text
    const quoteText = textInlineQuote.quote
    const quoteSource = textInlineQuote.source

    if (this.state.deviceSize === 'large') {
      return (
        <div class={ classes }>
          <div class="spacer"/>
          <div class="quote">
            <div class="quote-text">{ quoteText }</div>
            <div class="quote-source">{ Icon.unicode('dash') + quoteSource }</div>
          </div>
          { text }
        </div>
      )
    }
    else {
      return [
        <div class="standalone-quote">
          <div class="quote-text">{ quoteText }</div>
          <div class="quote-source">{ Icon.unicode('dash') + quoteSource }</div>
        </div>,
        <div class="text-block">
          <div class="text">{ text }</div>
        </div>
      ]
    }
  }

  renderStandaloneQuote (standaloneQuote) {
    const quoteText = standaloneQuote.quote
    const quoteSource = standaloneQuote.source

    return (
      <div class="standalone-quote">
        <div class="quote-text">{ quoteText }</div>
        <div class="quote-source">{ Icon.unicode('dash') + quoteSource }</div>
      </div>
    )
  }

  renderImageBlockText (description, attribution) {
    return (
      <div class="text">
        <div class="description">{ description }</div>
        <div class="attribution">{ attribution }</div>
      </div>
    )
  }

  renderImageBlock (renderImageBlock) {
    const imageSrc = util.getDeepValue(renderImageBlock, 'image.url')

    if (!imageSrc) return

    const description = renderImageBlock.description
    const attribution = renderImageBlock.attribution
    const shouldRenderText = description !== '' || attribution !== ''

    const imageClasses = util.classNames(
      'image',
      renderImageBlock.orientation.toLowerCase()
    )

    const imageBlockClasses = util.classNames(
      'image-block',
      !shouldRenderText && 'centered'
    )

    return (
      <div class={ imageBlockClasses }>
        <img class={ imageClasses } src={ imageSrc } alt=""/>
        { shouldRenderText
          && this.renderImageBlockText(description, attribution) }
      </div>
    )
  }

  renderVideoBlock (videoBlock) {
    const videoId = videoBlock.youtube_video_id

    if (!videoId) return
    const videoSrc = `https://www.youtube.com/embed/${videoId}`

    return (
      <div class="video-block">
        <div class="video">
          <div class="video-wrapper">
            <iframe
              width="100%"
              height="100%"
              src={ videoSrc }
              frameborder="0"
              allowfullscreen
            />
          </div>
        </div>
      </div>
    )
  }

  renderArticleBody (content) {
    const shouldCenterText = !content.some(
      (item) => item.text_block && item.text_block.image.image
    )

    return content.map((item) => {
      if ('image_header' in item) {
        return this.renderImageHeader(item.image_header)
      }
      else if ('text_block' in item) {
        return this.renderTextBlock(item.text_block, shouldCenterText)
      }
      else if ('text_inline_quote' in item) {
        return this.renderInlineQuote(item.text_inline_quote, shouldCenterText)
      }
      else if ('standalone_quote' in item) {
        return this.renderStandaloneQuote(item.standalone_quote)
      }
      else if ('image_block' in item) {
        return this.renderImageBlock(item.image_block)
      }
      else if ('video_block' in item) {
        return this.renderVideoBlock(item.video_block)
      }
    })
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading/>
        }
      </main>
    )
  }

  renderLeagueLogo (logo) {
    if (!logo) return

    const image = this.state.isBranded
      ? <Icon name="lolesportsLogo"/>
      : <Image class="image" src={ logo.url } size="60"/>

    return <div class="league-button">{ image }</div>
  }

  renderHeader (article) {
    // required on Contentstack but can be undefined if an article is published
    // with an unpublished author
    const author = util.getDeepValue(article, 'author.0.title')
    const logo = util.getDeepValue(article, 'league.0.logo_dark')

    return (
      <div class="article-header">
        { this.renderHeaderImage(article) }
        { this.renderLeagueLogo(logo) }
        { this.renderCredits(article.date, author) }
        <div class="title">{ article.title }</div>
        <div class="intro">{ article.intro }</div>
      </div>
    )
  }

  render () {
    const { article, imagesLoaded } = this.state

    if (!article || this.state.error) {
      return this.renderErrorOrLoading(this.state.error)
    }

    const componentClassNames = util.classNames(
      this.constructor.name,
      !imagesLoaded.header | !imagesLoaded.background && 'images-loading'
    )

    // TODO: Remove this once we have a better system for assigning branded styles
    const articleClassNames = util.classNames(
      'article',
      this.state.isBranded && 'branded'
    )

    return (
      <main class={ componentClassNames }>
        <div class="loading-overlay">
          <InformLoading/>
        </div>
        <div class={ articleClassNames }>
          { this.renderBackground(article) }
          { this.renderHeader(article) }
          <div class="article-body">
            { this.renderArticleBody(article.article_body) }
          </div>
        </div>
      </main>
    )
  }
}

export default Article
