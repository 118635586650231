import { Component, Logger, $, router } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'

// Temporary hardcoded translation map to allow for non-slug group names
// TODO: remove when ELDS exports the translation for group names
const tempGroupNameLocKeyMap = {
  play_in_groups: {
    play_in_group_a: 'standings.group.a',
    play_in_group_b: 'standings.group.b',
    play_in_group_c: 'standings.group.c',
    play_in_group_d: 'standings.group.d'
  },
  groups: {
    group_a: 'standings.group.a',
    group_b: 'standings.group.b',
    group_c: 'standings.group.c',
    group_d: 'standings.group.d'
  },
  regular_season: {
    regular_season: 'temp.regularSeason'
  }
}

class StandingsList extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  addListeners () {
    this.link = (event) => {
      const link = $(event.currentTarget).attr('href')

      router.go(link, event)
    }
  }

  componentWillMount () {
    if (this.props.playAll) {
      this.updatePlayAllResults()
    }
  }

  componentWillUpdate (nextProps) {
    if (nextProps.playAll && nextProps.playAll !== this.props.playAll) {
      this.updatePlayAllResults()
    }
  }

  updatePlayAllResults () {
    const matches = this.props.stage.sections.flatMap(
      (section) => section.matches
    )

    this.setState({
      playAllResults: this.calculatePlayAllStandings(matches)
    })
  }

  calculatePlayAllStandings (matches) {
    const teams = matches
      .flatMap((match) => match.teams)
      .filter((team) => team.result.outcome)

    const result = {}

    teams.forEach((team) => {
      const teamId = team.id
      const gameWins = team.result.gameWins

      if (!result[teamId]) {
        result[teamId] = {
          wins: 0,
          losses: 0
        }
      }

      result[teamId] = {
        wins: result[teamId].wins + gameWins,
        losses: result[teamId].losses + (2 - gameWins)
      }
    })

    return result
  }

  renderRecord (record) {
    if (!record) return // TBD teams don't have records

    const { wins, losses } = record

    return (
      <div class="record">
        { locale.translate('match.wins', { wins }) }
        { Icon.unicode('hyphen') }
        { locale.translate('match.losses', { losses }) }
      </div>
    )
  }

  renderTeam (team) {
    const { name, image, id } = team

    const record = this.props.playAll
      ? this.state.playAllResults[id]
      : team.record

    return (
      <div class="team">
        <div class="team-logo">
          <Image src={ image } class="logo" size="70"/>
        </div>
        <div class="team-info">
          <div class="name">{ name }</div>
          { this.renderRecord(record) }
        </div>
      </div>
    )
  }

  renderRankings (rankings) {
    return rankings.map((ranking) =>
      ranking.teams.map((team, index) => {
        const ordinalClasses = util.classNames(
          'ordinal',
          index !== 0 && 'hidden'
        )

        const linkClasses = util.classNames(
          'ranking',
          team.slug === 'tbd' && 'tbd'
        )

        return (
          <a
            href={ `/teams/${team.slug}` }
            class={ linkClasses }
            onclick={ this.link }>
            <div class={ ordinalClasses }>{ ranking.ordinal }</div>
            { this.renderTeam(team) }
          </a>
        )
      })
    )
  }

  getTitle (stage, section, index) {
    if (
      tempGroupNameLocKeyMap[stage.slug]
      && tempGroupNameLocKeyMap[stage.slug][section.name]
    ) {
      return locale.translate(tempGroupNameLocKeyMap[stage.slug][section.name])
    }
    else if (section.name && section.name.toLowerCase().match(/round_\d/)) {
      const number = section.name.split('_')[1]

      return locale.translate('standings.round', { number })
    }
    // Use "Standings" when stage name is the same as section name
    // or when section name is a slug
    else if (
      !section.name
      || stage.name === section.name
      || section.name.indexOf('_') > -1
    ) {
      return index === 0
        ? util.toUpperFirst(
          locale.translate('navigation.standings').toLowerCase()
        )
        : Icon.unicode('space')
    }
    return section.name
  }

  renderList (stage) {
    return stage.sections.map((section, index) => {
      const title = this.getTitle(stage, section, index)

      return (
        <div class="group">
          <div class="title">{ title }</div>
          { this.renderRankings(section.rankings) }
        </div>
      )
    })
  }

  render () {
    if (!this.props.stage || !this.props.stage.sections) {
      return
    }

    const numGroups = this.props.stage.sections.length
    const numGroupsClass
      = numGroups % 2 === 0 ? 'num-groups-even' : 'num-groups-odd'
    const componentClasses = util.classNames(
      this.constructor.name,
      numGroupsClass
    )

    return (
      <div class={ componentClasses }>{ this.renderList(this.props.stage) }</div>
    )
  }
}

export default StandingsList
