import { Component, Logger, $ } from 'lib'
import VirtualScroll from 'Component/VirtualScroll/VirtualScroll'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'

class Autocomplete extends Component {
  constructor (props) {
    super(props)
    this.log = new Logger(this.constructor.name)

    this.state = {
      active: false,
      input: '',
      selectedMenu: null
    }
  }

  componentDidMount () {
    $(document).on(
      'click',
      this.clickListener = (event) => {
        const classes = this.props.class
          ? '.' + this.props.class.split(' ').join('.')
          : '.' + this.constructor.name

        if (this.state.active && !$(event.target).closest(classes).length) {
          this.setState({
            active: false
          })
        }
      }
    )
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.active !== this.state.active) {
      $(document.body).toggleClass('no-scroll-small', !!this.state.active)
    }
  }

  componentWillUnmount () {
    $(document).off('click', this.clickListener)
    $(document.body).removeClass('no-scroll-small')
  }

  handleEvent (event) {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }

    return event
  }

  selectOption (event, option) {
    this.handleEvent(event)

    if (option.menu) {
      return this.setState({
        selectedMenu: option
      })
    }

    this.props.onSelect(option.value)
    this.setState({ active: false, selectedMenu: null, input: option.label })
  }

  flattenOptions (options) {
    return options.reduce(
      (acc, curr) =>
        curr.menu ? acc.concat(curr, ...curr.menu) : acc.concat(curr),
      []
    )
  }

  getSelectedOption (value) {
    const options = this.flattenOptions(this.props.options)

    return options.find((option) => option.value === value)
  }

  renderOption (option) {
    return (
      <div
        role="button"
        class="option"
        onClick={ (event) => this.selectOption(event, option) }
        key={ option.value }>
        { option.icon && <Image class="icon" src={ option.icon } size="24"/> }
        <div class="label">{ option.label }</div>
        { option.menu && <Icon name="arrow" direction="right"/> }
      </div>
    )
  }

  renderBackMenuOption (option) {
    return (
      <div
        role="button"
        class="back-menu"
        onClick={ () => this.setState({ selectedMenu: null }) }>
        <Icon name="arrow" direction="left"/>
        <div class="label">{ option.label }</div>
      </div>
    )
  }

  renderInput () {
    return (
      <li>
        <input
          class="input"
          type="text"
          placeholder={ this.props.placeholder }
          onInput={ (event) => this.onInput(event) }
          value={ this.state.input }
        />
      </li>
    )
  }

  onInput (event) {
    this.setState({
      input: event.target.value
    })
  }

  openMenu (event) {
    this.handleEvent(event)

    this.setState({
      active: true
    })
  }

  renderMenu (data) {
    const { selectedMenu } = this.state
    const elements = data.map((element) => this.renderOption(element))

    if (selectedMenu) {
      elements.unshift(this.renderBackMenuOption(selectedMenu))
    }

    return (
      <li class="menu">
        <VirtualScroll itemHeight={ 40 }>{ elements }</VirtualScroll>
      </li>
    )
  }

  render (props, state) {
    const { options } = props
    const { input, selectedMenu, active } = state

    const classes = util.classNames(
      this.constructor.name,
      props.class,
      active && 'active'
    )

    const shouldFilter = input.length >= 2 && !selectedMenu
    const optionsToRender = selectedMenu ? selectedMenu.menu : options

    const data = shouldFilter
      ? this.flattenOptions(optionsToRender).filter((option) =>
        option.label.toLowerCase().startsWith(input.toLowerCase())
      )
      : optionsToRender

    return (
      <ul class={ classes } onClick={ (event) => this.openMenu(event) }>
        { this.renderInput() }
        { active && this.renderMenu(data) }
      </ul>
    )
  }
}

export default Autocomplete
