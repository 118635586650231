import { Component } from 'lib'
import Accordion from 'Component/Accordion/Accordion'
import util from 'Common/Util/Util'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'
import relApi from 'Common/Service/RelApi/RelApi'
import device from 'Common/Device/Device'
import AddLeagueNotifications from './AddLeague/AddLeagueNotifications'
import AddTeamsNotifications from './AddTeams/AddTeamsNotifications'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import InformLoading from 'Component/Inform/Loading/InformLoading'

const generalSlugs = ['watch']

class SettingsNotifications extends Component {
  constructor (props) {
    super(props)

    relApi
      .fetchLeagues()
      .then(({ leagues }) => this.setState({ leagues }))
      .catch((error) => this.setState({ error }))

    relApi
      .fetchTeams()
      .then(({ teams }) =>
        this.setState({
          teams: teams.filter((team) => team.status === 'active')
        })
      )
      .catch((error) => this.setState({ error }))

    this.state = {
      leagues: [],
      teams: []
    }
  }

  translate (key, isDifferentForSmall) {
    const prefix = 'settings.notifications'
    const fullKey = `${prefix}.${key}`

    const localeKey
      = isDifferentForSmall && device.getSize() === 'small'
        ? `${fullKey}.small`
        : fullKey

    return locale.translate(localeKey)
  }

  addLeague (id) {
    const alreadyAddedLeague = this.props.topics.leagues.find(
      (league) => league.relApiId === id
    )

    if (!alreadyAddedLeague) {
      const leagues = this.props.topics.leagues.concat({ relApiId: id })

      this.props.onLeagueChange(leagues)
    }

    this.closeModals()
  }

  deleteLeague (id) {
    const leagues = this.props.topics.leagues.filter(
      (league) => league.relApiId !== id
    )

    this.props.onLeagueChange(leagues)
  }

  addTeam (id) {
    const alreadyAddedTeam = this.props.topics.teams.find(
      (team) => team.relApiId === id
    )

    if (!alreadyAddedTeam) {
      const teams = this.props.topics.teams.concat({ relApiId: id })

      this.props.onTeamChange(teams)
    }

    this.closeModals()
  }

  deleteTeam (id) {
    const teams = this.props.topics.teams.filter((team) => team.relApiId !== id)

    this.props.onTeamChange(teams)
  }

  closeModals () {
    this.setState({
      showLeagueModal: false,
      showTeamModal: false
    })
  }

  renderOption (key) {
    return (
      <div class="option">
        <label class="label">{ this.translate(key, true) }</label>
        { this.renderToggle(key) }
      </div>
    )
  }

  renderGeneral () {
    return (
      <Accordion title={ this.translate('general') } open>
        <div class="general">
          { generalSlugs.map((key) => this.renderOption(key)) }
        </div>
      </Accordion>
    )
  }

  renderToggle (key) {
    const { notificationsSettings, onNotificationChange } = this.props
    const isEnabled = (key) => notificationsSettings[key] === 'enabled'
    const classes = util.classNames('toggle', isEnabled(key) && 'selected')

    const onClick = () => {
      const setting = this.props.notificationsSettings[key]
      const value = setting === 'enabled' ? 'disabled' : 'enabled'

      onNotificationChange({ [key]: value })
    }

    return (
      <div class={ classes } onClick={ onClick }>
        <div class="bubble"/>
      </div>
    )
  }

  renderTopic (url, name, onDelete) {
    const isSmall = device.getSize() === 'small'
    const imageSize = isSmall ? '32x32' : '100x100'
    const buttonContent = isSmall
      ? <Icon name="close"/>
      : this.translate('delete')

    return (
      <div class="topic">
        <Image class="img" size={ imageSize } src={ url }/>
        <label class="name">{ name }</label>
        <button class="delete" onClick={ () => onDelete() }>
          { buttonContent }
        </button>
      </div>
    )
  }

  renderAddLeagueModal () {
    return (
      <AddLeagueNotifications
        leagues={ this.state.leagues }
        onConfirm={ (id) => this.addLeague(id) }
        onCancel={ () => this.closeModals() }
      />
    )
  }

  renderAddTeamModal () {
    return (
      <AddTeamsNotifications
        leagues={ this.state.leagues }
        teams={ this.state.teams }
        onConfirm={ (id) => this.addTeam(id) }
        onCancel={ () => this.closeModals() }
      />
    )
  }

  renderLeagueBroadcastStart () {
    const leaguesToShow = this.props.topics.leagues.map(
      (league) => league.relApiId
    )
    const images = leaguesToShow.map((id) => {
      const league = this.state.leagues.find((league) => league.id === id)
      const onDelete = () => this.deleteLeague(league.id)

      if (league) {
        return this.renderTopic(league.image, league.name, onDelete)
      }
    })

    return (
      <Accordion title={ this.translate('leagueBroadcast', true) } open>
        <div class="section">
          <div class="absolute">
            <button
              class="add"
              onClick={ () =>
                this.setState({
                  showLeagueModal: true
                })
              }>
              { this.translate('addLeague', true) }
            </button>
          </div>
          { images }
        </div>
      </Accordion>
    )
  }

  renderTeamMatchStart () {
    const teamsToShow = this.props.topics.teams.map((team) => team.relApiId)
    const images = teamsToShow.map((id) => {
      const team = this.state.teams.find((team) => team.id === id)
      const onDelete = () => this.deleteTeam(team.id)

      if (team) {
        return this.renderTopic(team.image, team.code, onDelete)
      }
    })

    return (
      <Accordion title={ this.translate('teamMatch', true) } open>
        <div class="section">
          <div class="absolute">
            <button
              class="add"
              onClick={ () =>
                this.setState({
                  showTeamModal: true
                })
              }>
              { this.translate('addTeams', true) }
            </button>
          </div>
          { images }
        </div>
      </Accordion>
    )
  }

  renderErrorOrLoading (error) {
    const dismiss = () => this.setState({ error: null })

    if (error) {
      return (
        <InformBubble theme="error" icon="error" onDismissHandler={ dismiss }>
          { error.message }
        </InformBubble>
      )
    }

    return (
      <div class={ this.constructor.name }>
        <InformLoading/>
      </div>
    )
  }

  render () {
    const { leagues, teams, error } = this.state

    if (!leagues.length || !teams.length || error) {
      return this.renderErrorOrLoading(error)
    }

    return (
      <div class={ this.constructor.name }>
        { this.renderGeneral() }
        { this.renderLeagueBroadcastStart() }
        { this.renderTeamMatchStart() }
        { this.state.showLeagueModal && this.renderAddLeagueModal() }
        { this.state.showTeamModal && this.renderAddTeamModal() }
      </div>
    )
  }
}

export default SettingsNotifications
