import { Component, $, router } from 'lib'
import util from 'Common/Util/Util'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'

class StandingsBracketV2Match extends Component {
  addListeners () {
    this.link = (event) => {
      const link = $(event.currentTarget).attr('href')

      router.go(link, event)
    }
  }

  render (props) {
    const getTeamClasses = (team) =>
      util.classNames(
        'team',
        team.result && team.result.outcome ? team.result.outcome : '',
        team.slug === 'tbd' && 'tbd'
      )

    const renderTeam = (team) => {
      const { name, image, code, result, slug } = team

      // If the game hasn't started, should display a dash instead of a 0
      const dashForGames = !result || isNaN(result.gameWins)

      return (
        <a
          href={ `/teams/${slug}` }
          class={ getTeamClasses(team) }
          onclick={ this.link }>
          <Image class="logo" src={ image } size="36x36"/>
          <div class="name">{ name }</div>
          <div class="tricode">{ code }</div>
          <div class="score">
            { dashForGames ? Icon.unicode('dash') : result?.gameWins }
          </div>
        </a>
      )
    }

    const getPrevMatchId = (origin) =>
      origin.type === 'match' && origin.slot === 1 && origin.structuralId

    const team1 = props.match.teams[0]
    const team2 = props.match.teams[1]

    // TODO: always return origin when it always exists
    return (
      <div
        class={ this.constructor.name }
        id={ props.match.structuralId }
        key={ props.match.id }
        data-prev-match1={ team1.origin && getPrevMatchId(team1.origin) }
        data-prev-match2={ team2.origin && getPrevMatchId(team2.origin) }>
        <div class="teams">
          { props.match.teams.map((team) => renderTeam(team)) }
        </div>
      </div>
    )
  }
}

export default StandingsBracketV2Match
