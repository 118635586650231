import { Component, store } from 'lib'
import locale from 'Common/Locale/Locale'
import rewardsOpting from 'Common/Service/Rewards/Opting/RewardsOpting'
import Icon from 'Component/Asset/Icon/Icon'

class InformDontTrackMe extends Component {
  componentWillMount () {
    this.addListeners()
  }

  addListeners () {
    this.onClick = () => {
      this.setState({ active: true })
    }

    this.onClose = () => {
      this.setState({ active: false })
    }

    this.onOptClick = () => {
      this.setState({ active: false })
      this.optOut()
    }
  }

  optOut () {
    return rewardsOpting
      .optOut()
      .then(() => store.set('rewards.optOut.success', true))
      .catch((error) => {
        store.set('rewards.optOut.success', false)
        this.log.error(error)
      })
  }

  renderModal () {
    return (
      <div class="modal active">
        <div class="content">
          <div class="prompt-text">
            <h1>{ locale.translate('modal.dontTrackMe.header') }</h1>
            <p>{ locale.translate('modal.dontTrackMe.details1') }</p>
            <p>{ locale.translate('modal.dontTrackMe.details2') }</p>
            <p>
              { locale.translate('modal.dontTrackMe.details3') }
              { Icon.unicode('space') }
              <a
                class="link"
                href="https://leagueoflegends.com/legal/privacy"
                target="_blank">
                { locale.translate('rewards.privacyPolicy') }
              </a>
            </p>
          </div>
          <div>
            <span
              class="button opt-out-confirm"
              onclick={ this.onOptClick }
              role="button">
              { locale.translate('modal.dontTrackMe.optOut') }
            </span>
            <span
              class="button continue-confirm"
              onclick={ this.onClose }
              role="button">
              { locale.translate('modal.dontTrackMe.continue') }
            </span>
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div class={ this.constructor.name }>
        <a class="action" onclick={ this.onClick } role="button">
          <span class="large">
            { locale.translate('modal.dontTrackMe.optOut') }
          </span>
          <span class="small-medium">
            { locale.translate('modal.dontTrackMe.question') }
          </span>
        </a>
        { this.state.active && this.renderModal() }
      </div>
    )
  }
}

export default InformDontTrackMe
