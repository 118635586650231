import { Component, Logger, render, $ } from 'lib'
import util from 'Common/Util/Util'

class InformTooltip extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  static preventDefaultEventBehavior (event) {
    event.stopPropagation()
    event.preventDefault()
  }

  static addTooltip (element, message, alignment) {
    const { onclick, onfocusin, onfocusout } = element.attributes

    element.attributes.onclick = (event) => {
      InformTooltip.preventDefaultEventBehavior(event)
      if (util.isTouchDevice()) {
        onclick && onclick(event)
        window.alert(message)
      }
    }

    element.attributes.onfocusin = (event) => {
      InformTooltip.preventDefaultEventBehavior(event)
      if (!util.isTouchDevice()) {
        onfocusin && onfocusin(event)

        $(event.currentTarget).append(
          render(<InformTooltip message={ message } alignment={ alignment }/>)
        )
      }
    }

    element.attributes.onfocusout = (event) => {
      InformTooltip.preventDefaultEventBehavior(event)
      if (!util.isTouchDevice()) {
        onfocusout && onfocusout(event)

        $(event.currentTarget)
          .find('.InformTooltip')
          .remove()
      }
    }
  }

  render () {
    if (!this.props.message || !this.props.alignment) {
      this.log.error(
        'Missing props for InformTooltip:',
        '\nMessage: ',
        this.props.message,
        '\nAlignment: ',
        this.props.alignment
      )
      return
    }

    const componentClasses = util.classNames(
      this.constructor.name,
      this.props.alignment
    )

    return (
      <div class={ componentClasses }>
        <div class="message">{ this.props.message }</div>
      </div>
    )
  }
}

export default InformTooltip
