/* eslint max-lines: ["error", {"max": 250, "skipBlankLines": true, "skipComments": true}] */
import { Component, Logger, store } from 'lib'
import liveStats from 'Common/Service/LiveGameEvents/LiveGameEvents'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import dataDragon from 'Common/Service/DataDragon/DataDragon'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import Icon from 'Component/Asset/Icon/Icon'
import StatsMatchupPlayers from 'Component/Stats/Matchup/Players/StatsMatchupPlayers'
import StatsMatchupPerformance from 'Component/Stats/Matchup/Performance/StatsMatchupPerformance'
import StatsMatchupAttributes from 'Component/Stats/Matchup/Attributes/StatsMatchupAttributes'
import StatsMatchupAbilities from 'Component/Stats/Matchup/Abilities/StatsMatchupAbilities'
import StatsMatchupItems from 'Component/Stats/Matchup/Items/StatsMatchupItems'
import StatsMatchupRunes from 'Component/Stats/Matchup/Runes/StatsMatchupRunes'
import StatsMatchupSelector from 'Component/Stats/Matchup/Selector/StatsMatchupSelector'
import analytics from '../../../Common/Service/Analytics/Analytics'

class StatsMatchup extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.timeline = []
    this.primaryTeam = 1
  }

  addEventListeners () {
    store.onChange(
      'stats.players.compare',
      this.comparePlayers = (data) => {
        if (data) {
          this.log.debug(
            'Comparing players for role',
            data.role,
            'for game',
            this.props.gameId
          )

          this.primaryTeam = data.primaryTeam
          const bluePlayer = data.bluePlayerInfo
          const redPlayer = data.redPlayerInfo

          // stop fetching the previous matchup if there is one
          this.refetcher && this.refetcher.stop()
          this.refetch(bluePlayer, redPlayer)
        }
      }
    )

    store.onChange(
      'videoPlayer.timestamp',
      this.onVideoPlayerTimestamp = (timestamp) => {
        const stats = this.getStats(timestamp)

        stats
          && this.setState({
            players: stats.participants
          })
      }
    )

    store.onChange(
      'dataDragon.setup',
      this.onDataDragonSetup = (setup) => {
        if (setup) {
          this.fetchDataDragonData()
        }
      }
    )

    this.hideMatchup = () => {
      this.props.smallShowMatchupHandler
        && this.props.smallShowMatchupHandler(false)
    }
  }

  componentDidMount () {
    this.addEventListeners()
  }

  componentWillUnmount () {
    store.removeListener('stats.players.compare', this.comparePlayers)
    store.removeListener('videoPlayer.timestamp', this.onVideoPlayerTimestamp)
    store.removeListener('dataDragon.setup', this.onDataDragonSetup)

    this.refetcher && this.refetcher.stop() // stop fetching
  }

  fetchDataDragonData () {
    dataDragon
      .fetchItemList()
      .then((items) => {
        this.setState({ items })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })

    dataDragon
      .fetchRuneList()
      .then((runes) => {
        this.setState({ runes })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  refetch (bluePlayer, redPlayer) {
    const participants = [bluePlayer.participantId, redPlayer.participantId]

    this.refetcher = liveStats.refetchDetails(
      this.props.gameId,
      participants.join('_')
    )

    this.refetcher
      .onFetch((json) => {
        // got a 200 with no JSON body, we are waiting for the game to start
        if (!json) return this.log.info('Waiting for game to start...')

        this.timeline = json.frames

        // force refresh stats
        const stats = this.getStats(store.get('videoPlayer.timestamp'))

        stats
          && this.setState({
            players: stats.participants,
            bluePlayerInfo: bluePlayer,
            redPlayerInfo: redPlayer
          })

        // only fetch once if game is over
        store.get('watch.live.finalTimestamp') && this.refetcher.stop()
      })
      .onError((error) => {
        this.log.error(error)
      })
  }

  getStats (timestamp) {
    for (let n = 1; n < this.timeline.length; n++) {
      const currentTimestamp = Date.parse(this.timeline[n].rfc460Timestamp)

      if (currentTimestamp - timestamp > 1000) {
        this.log.debug('Skipping at least 1 frame from current stat window')
      }

      if (currentTimestamp > timestamp) {
        return this.timeline[n - 1]
      }
    }

    // Current timestamp does not match any events on the timeline
    // This means we are at post/after game but still showing livestats
    // Show the last stats we have
    return this.timeline[this.timeline.length - 1]
  }

  renderInfo () {
    switch (this.props.selectedTab) {
      case 'abilities':
        return (
          <StatsMatchupAbilities
            bluePlayerStats={ this.state.players[0] }
            bluePlayerInfo={ this.state.bluePlayerInfo }
            redPlayerStats={ this.state.players[1] }
            redPlayerInfo={ this.state.redPlayerInfo }
            primaryTeam={ this.primaryTeam }
            gameId={ this.props.gameId }
          />
        )
      case 'items':
        return (
          this.state.items && (
            <StatsMatchupItems
              bluePlayer={ this.state.players[0] }
              redPlayer={ this.state.players[1] }
              primaryTeam={ this.primaryTeam }
              items={ this.state.items }
            />
          )
        )
      case 'runes':
        return (
          this.state.runes && (
            <StatsMatchupRunes
              bluePlayer={ this.state.players[0] }
              redPlayer={ this.state.players[1] }
              primaryTeam={ this.primaryTeam }
              runes={ this.state.runes }
            />
          )
        )
      case 'stats':
      default:
        return (
          <div class="stats">
            <StatsMatchupPerformance
              bluePlayer={ this.state.players[0] }
              redPlayer={ this.state.players[1] }
              primaryTeam={ this.primaryTeam }
            />
            <StatsMatchupAttributes
              bluePlayer={ this.state.players[0] }
              redPlayer={ this.state.players[1] }
              primaryTeam={ this.primaryTeam }
            />
          </div>
        )
    }
  }

  renderNoStatsAvailable () {
    return (
      <main class={ this.constructor.name }>
        <div class="no-stats">{ locale.translate('stats.notAvailable') }</div>
      </main>
    )
  }

  renderError (error) {
    return (
      <main class={ this.constructor.name }>
        <InformBubble theme="error" icon="error">
          { error.message }
        </InformBubble>
      </main>
    )
  }

  render () {
    if (!this.state.players) {
      return this.renderNoStatsAvailable()
    }

    if (this.state.error) {
      return this.renderError(this.state.error)
    }

    const componentClasses = util.classNames(
      this.constructor.name,
      this.props.hideForSmall && 'hidden'
    )

    const bluePlayer = this.state.players[0]
    const redPlayer = this.state.players[1]

    return (
      <div class={ componentClasses }>
        <div class="player-info">
          <div class="hide-matchup-button" onclick={ this.hideMatchup }>
            <Icon name="arrow" direction="left"/>
          </div>
          <StatsMatchupPlayers
            bluePlayerStats={ bluePlayer }
            bluePlayerInfo={ this.state.bluePlayerInfo }
            redPlayerStats={ redPlayer }
            redPlayerInfo={ this.state.redPlayerInfo }
            primaryTeam={ this.primaryTeam }
          />
        </div>
        <StatsMatchupSelector
          selectedTab={ this.props.selectedTab }
          statsTabChangeHandler={ (tag) => {
            this.props.statsTabChangeHandler(tag)
            analytics.trackEvent('stats_tab_selected', {
              redChampionId: this.state.redPlayerInfo.championId,
              redPlayerId: this.state.redPlayerInfo.esportsPlayerId,
              redSummonerName: this.state.redPlayerInfo.summonerName,
              blueChampionId: this.state.bluePlayerInfo.championId,
              bluePlayerId: this.state.bluePlayerInfo.esportsPlayerId,
              blueSummonerName: this.state.bluePlayerInfo.summonerName,
              tab: tag
            })
          } }
          tabList={ this.props.tabList }
        />
        <div class="info">{ this.renderInfo() }</div>
      </div>
    )
  }
}

export default StatsMatchup
