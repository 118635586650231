/* eslint max-lines: ["error", {"max": 350, "skipBlankLines": true, "skipComments": true}] */
import { Component, Logger, router, store, $ } from 'lib'
import relApi from 'Common/Service/RelApi/RelApi'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'
import StandingsFilter from 'Component/Standings/Filter/StandingsFilter'
import StandingsList from 'Component/Standings/List/StandingsList'
import StandingsTopNav from 'Component/Standings/TopNav/StandingsTopNav'
import StandingsBracket from 'Component/Standings/Bracket/StandingsBracket'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import google from 'Common/Service/Google/Google'
import StandingsConfig from './Standings.conf'
import analytics from 'Common/Service/Analytics/Analytics'
import {
  leaguePriority,
  sortLeaguesByPriority
} from '../../Common/Service/RelApi/RelApi'
import StandingsBracketV2 from '../../Component/Standings/BracketV2/StandingsBracketV2'
import StandingsBracketSwiss from '../../Component/Standings/BracketSwiss/StandingsBracketSwiss'

const standingsLeagueKey = 'selected-league-standings'

class Standings extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addEventListeners()
    this.fetchLeagues()
  }

  componentWillUnmount () {
    store.remove('standings.toggleFilter')
    $(document.body).removeClass('no-scroll-small')
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.filterActive !== this.state.filterActive) {
      $(document.body).toggleClass('no-scroll-small', !!this.state.filterActive)
    }
  }

  sendMetrics (selectedLeague) {
    const leagueIdFromStorage = window.localStorage.getItem(standingsLeagueKey)

    google.pushSelectedLeagues(
      [selectedLeague],
      !!leagueIdFromStorage,
      'standings'
    )
  }

  addEventListeners () {
    store.onChange(
      'standings.toggleFilter',
      this.standingsToggleFilterListener = (next, prev) => {
        if (next !== prev) {
          this.setState({ filterActive: next })
        }
      }
    )

    this.leagueChangeHandler = (leagueSlug) => {
      router.go('/standings/' + leagueSlug)
      store.set('standings.toggleFilter', false)
      const selectedLeague = util.getObjectFromKey(
        'slug',
        leagueSlug,
        this.state.leagues
      )

      analytics.trackEvent('standings_filter_applied', { league: leagueSlug })
      this.sendMetrics(selectedLeague)

      window.localStorage.setItem(standingsLeagueKey, selectedLeague.id)

      // reset stages to show the loading spinner
      this.setState({ selectedLeague, stages: undefined })
      this.fetchTournaments(selectedLeague.id)
    }

    this.stageChangeHandler = (stageSlug) => {
      const selectedStage = util.getObjectFromKey(
        'slug',
        stageSlug,
        this.state.stages
      )

      this.setState({ selectedStage })
      this.setUrlFromState()
    }
  }

  fetchLeagues () {
    return relApi
      .fetchLeagues()
      .then((json) => {
        const leagues = json.leagues.sort((leagueA, leagueB) =>
          sortLeaguesByPriority(leagueA, leagueB)
        )
        const selectedLeague = this.getSelectedLeague(leagues)

        this.sendMetrics(selectedLeague)
        this.setState({ leagues, selectedLeague })
        this.fetchTournaments(this.state.selectedLeague.id)
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  fetchTournaments (leagueId) {
    return relApi
      .fetchTournaments(leagueId)
      .then((json) => {
        const tournaments = json[0].tournaments
        const selectedTournament = this.getSelectedTournament(tournaments)

        this.setState({ selectedTournament, tournaments })
        this.fetchStandings(this.state.selectedTournament.id)
        this.fetchStandingsV3(this.state.selectedTournament.id)
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  fetchStandingsV3 (tournamentId) {
    return relApi
      .fetchStandingsV3(tournamentId)
      .then((data) => {
        this.setState({ stagesV3: data })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  fetchStandings (tournamentId) {
    return relApi
      .fetchStandings(tournamentId)
      .then((stages) => {
        const selectedStage = this.getSelectedStage(stages)

        const override = StandingsConfig.playAllOverrides.find(
          (override) =>
            override.tournamentId === this.state.selectedTournament.id
        )

        if (override) {
          const stageOverride = override.stages.includes(selectedStage.slug)

          this.setState({
            playAll: stageOverride
          })
        }
        else {
          this.setState({
            playAll: false
          })
        }

        this.setState({ selectedStage, stages })
        this.setUrlFromState()
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  setUrlFromState () {
    let url
      = '/standings/'
      + this.state.selectedLeague.slug
      + '/'
      + this.state.selectedTournament.slug

    if (this.state.selectedStage) {
      url += '/' + this.state.selectedStage.slug
    }

    router.updateUrl(url)
  }

  getSelectedLeague (leagues) {
    const leagueSlug = router.param('leagueSlug')
    const leagueFromParam
      = leagueSlug && util.getObjectFromKey('slug', leagueSlug, leagues)

    if (leagueFromParam) {
      return leagueFromParam
    }

    const forcedLeague = leagues.find(
      (league) =>
        league.displayPriority?.status === leaguePriority.FORCE_SELECTED
    )

    if (forcedLeague) {
      return forcedLeague
    }

    const leagueIdFromStorage = window.localStorage.getItem(standingsLeagueKey)

    if (leagueIdFromStorage) {
      const leagueFromStorage = util.getObjectFromKey(
        'id',
        leagueIdFromStorage,
        leagues
      )

      if (leagueFromStorage) {
        return leagueFromStorage
      }
    }

    return leagues[0] // already in priority order, get first
  }

  getSelectedTournament (tournaments) {
    const tournamentSlug = router.param('tournamentSlug')
    const tournamentFromParam
      = tournamentSlug
      && util.getObjectFromKey('slug', tournamentSlug, tournaments)

    return tournamentFromParam || tournaments[0]
  }

  getSelectedStage (stages) {
    if (!stages.length) return

    const stageSlug = router.param('stageSlug')
    const stageFromParam
      = stageSlug && util.getObjectFromKey('slug', stageSlug, stages)

    return stageFromParam || this.getCurrentStage(stages)
  }

  getCurrentStage (stages) {
    // Find the first stage having uncompleted matches and use it as
    // the default selected stage. If nothing was found it means
    // the tournament ended and we return the last stage
    for (let i = 0; i < stages.length; i++) {
      const hasUncompletedMatches = stages[i].sections.find((section) =>
        section.matches.find((match) => match.state !== 'completed')
      )

      if (hasUncompletedMatches) return stages[i]
    }

    return stages[stages.length - 1]
  }

  renderStage (stage) {
    if (!stage) return this.renderNoStandings()

    if (stage.type === 'groups') {
      return (
        <StandingsList
          stage={ this.state.selectedStage }
          playAll={ this.state.playAll }
        />
      )
    }
    else if (stage.type === 'bracket') {
      return <StandingsBracket stage={ this.state.selectedStage }/>
    }
    else {
      this.log.warn(
        'Unhandled displayType '
          + stage.displayType
          + ' for stage '
          + stage.slug
      )
    }
  }

  renderStageV3 (stage) {
    return stage?.sections.map((section) =>
      <StandingsBracketV2 key={ section.id } section={ section }/>
    )
  }

  renderStageSwiss (stage) {
    return stage?.sections.map((section) =>
      <StandingsBracketSwiss key={ section.id } section={ section }/>
    )
  }

  renderV3OrDefaultStage (stage) {
    // brackets are handled by new API (StageV3)
    const isBracket = stage?.sections?.[0]?.type === 'bracket'

    // back-compat for old API that require type=group
    this.state.selectedStage.type = 'groups'

    return isBracket
      ? this.renderStageV3(stage)
      : this.renderStage(this.state.selectedStage)
  }

  renderResults (filterActiveClass) {
    const stage = this.state.stagesV3?.find(
      (stage) => stage.slug === this.state.selectedStage.slug
    )

    const isSwissBracket = stage?.slug === 'swiss'

    return (
      <div class="results">
        <StandingsTopNav
          stages={ this.state.stages }
          selectedStage={ this.state.selectedStage }
          selectedLeague={ this.state.selectedLeague }
          stageChangeHandler={ this.stageChangeHandler }
          leagueChangeHandler={ this.leagueChangeHandler }
          filterActiveClass={ filterActiveClass }
        />
        <div class="stage">
          { isSwissBracket
            ? this.renderStageSwiss(stage)
            : this.renderV3OrDefaultStage(stage) }
        </div>
      </div>
    )
  }

  renderNoStandings () {
    // TODO: use Standings locale key and not vods
    return <div class="coming-soon">{ locale.translate('vods.comingSoon') }</div>
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading/>
        }
      </main>
    )
  }

  render () {
    if (!this.state.leagues || !this.state.tournaments || this.state.error) {
      return this.renderErrorOrLoading(this.state.error)
    }

    const filterActiveClass = this.state.filterActive && 'filter-active'
    const componentClasses = util.classNames(
      this.constructor.name,
      filterActiveClass
    )

    return (
      <main class={ componentClasses }>
        <div class="sidebar">
          <StandingsFilter
            leagues={ this.state.leagues }
            selectedLeague={ this.state.selectedLeague }
            leagueChangeHandler={ this.leagueChangeHandler }
          />
        </div>
        { this.state.stages && this.state.stagesV3
          ? this.renderResults(filterActiveClass)
          : (
            <div class="loading">
              <InformLoading/>
            </div>
          ) }
      </main>
    )
  }
}

export default Standings
