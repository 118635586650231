import { Component } from 'lib'
import util from 'Common/Util/Util'

class VirtualScroll extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: []
    }
  }

  initState (settings) {
    const { itemHeight, amount = 5, bufferSize = 1 } = settings

    this.setState({
      viewportHeight: amount * itemHeight,
      totalHeight: this.props.children.length * itemHeight,
      bufferSize,
      amount,
      itemHeight
    })

    this.onScroll({ target: { scrollTop: 0 } })
  }

  componentDidMount () {
    this.initState(this.props)
  }

  // If we receive new props, it is likely because we have received new data to
  // display.  We must reinitialize state since the length of data may have changed
  // e.g. from filtering the data or requesting more.  Delaying to the next iteration
  // ensures that this.props.children is properly updated, otherwise we will re-render
  // with the old children.
  componentWillReceiveProps (nextProps) {
    window.setTimeout(() => this.initState(nextProps), 0)
  }

  // When the user scrolls, we must update the top and bottom padding, and change
  // which elements are visible on the screen
  onScroll (event) {
    const { scrollTop } = event.target

    const { totalHeight, itemHeight, bufferSize, amount } = this.state

    const totalItems = this.props.children.length
    const visibleItems = amount + 2 * bufferSize

    // The starting index to render data from - in other words, how many have we
    // already scrolled past
    const startingIndex = Math.min(
      Math.floor(scrollTop / itemHeight),
      totalItems - visibleItems > -1 ? totalItems - visibleItems : 0
    )
    const data = this.props.children.slice(
      startingIndex,
      startingIndex + visibleItems
    )

    const topPaddingHeight = Math.max(0, startingIndex * itemHeight)
    const bottomPaddingHeight = Math.max(
      0,
      totalHeight - topPaddingHeight - data.length * itemHeight
    )

    this.setState({
      topPaddingHeight,
      bottomPaddingHeight,
      data
    })
  }

  renderBuffer (height) {
    const style = { height }

    return <li class="buffer" style={ style }/>
  }

  renderListItem (item) {
    // Force the height of the element because the algorithm relies on fixed height
    const style = {
      height: this.state.itemHeight
    }

    return (
      <li class="list-item" style={ style } key={ item.key }>
        { item }
      </li>
    )
  }

  render () {
    const { topPaddingHeight, bottomPaddingHeight, data } = this.state
    const classes = util.classNames(this.constructor.name, this.props.class)

    return (
      <ul class={ classes } onScroll={ (event) => this.onScroll(event) }>
        { this.renderBuffer(topPaddingHeight) }
        { data.map((child) => this.renderListItem(child)) }
        { this.renderBuffer(bottomPaddingHeight) }
      </ul>
    )
  }
}

export default VirtualScroll
