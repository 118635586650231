import util from 'Common/Util/Util'

const bracketLineColor = '#555D64'
const lowerBracketStringPrefixes = ['lower', 'loser']
const slugContainsLowerBracketPrefix = (slug) =>
  lowerBracketStringPrefixes.some((prefix) => slug.includes(prefix))

class StandingsBracketUtil {
  drawLine (startX, startY, endX, endY, context) {
    const roundToHalf = (notRounded) => {
      const rounded = Math.round(notRounded)

      return rounded > notRounded ? rounded - 0.5 : rounded + 0.5
    }

    const startXRounded = roundToHalf(startX)
    const endXRounded = roundToHalf(endX)
    const startYRounded = roundToHalf(startY)
    const endYRounded = roundToHalf(endY)

    const xDiff = endXRounded - startXRounded
    const secondX = startXRounded + xDiff / 2

    context.moveTo(startXRounded, startYRounded)
    context.lineTo(secondX, startYRounded)
    context.lineTo(secondX, endYRounded)
    context.lineTo(endXRounded, endYRounded)
    context.stroke()
  }

  setContextSettings (context) {
    // There are no context setter functions for these attributes
    context.strokeStyle = bracketLineColor
    context.globalAlpha = 1
    context.lineWidth = 1

    context.scale(util.getPixelRatio(), util.getPixelRatio())
  }

  teamOriginFromLosingMatch (team) {
    return team.origin?.type === 'match' && team.origin.slot === 2
  }

  teamOriginFromLosingOrSeeding (team) {
    return (
      this.teamOriginFromLosingMatch(team) || team.origin?.type === 'seeding'
    )
  }

  teamsInLowerBracketMatch (team1, team2) {
    const oneTeamLost
      = this.teamOriginFromLosingMatch(team1)
      || this.teamOriginFromLosingMatch(team2)
    const teamsFromLosingOrSeeding
      = this.teamOriginFromLosingOrSeeding(team1)
      && this.teamOriginFromLosingOrSeeding(team2)

    return oneTeamLost && teamsFromLosingOrSeeding
  }

  tournamentHasLowerBracket (columns) {
    return !!columns.find((column) =>
      column.cells.find((cell) =>
        cell.matches.find(
          (match) =>
            this.teamsInLowerBracketMatch(match.teams[0], match.teams[1])
            || slugContainsLowerBracketPrefix(cell.slug)
        )
      )
    )
  }

  splitBracket (columns) {
    const mutUpperBracket = []
    const mutLowerBracket = []

    const lowerBracketStructuralIds = new Set()

    const matchHasLosingTeam = (match) =>
      !!match.teams.find(
        (team) =>
          team.origin && team.origin.type === 'match' && team.origin.slot === 2
      )

    const matchHasTeamsFromLowerBracket = (match) =>
      match.teams.every(
        (team) =>
          team.origin && lowerBracketStructuralIds.has(team.origin.structuralId)
      )

    const matchInLowerBracket = (match, cellSlug) =>
      matchHasLosingTeam(match)
      || matchHasTeamsFromLowerBracket(match)
      || slugContainsLowerBracketPrefix(cellSlug)

    columns.forEach((column) => {
      const mutUpperCells = []
      const mutLowerCells = []

      column.cells.forEach((cell) => {
        const mutUpperMatches = []
        const mutLowerMatches = []

        cell.matches.forEach((match) => {
          if (matchInLowerBracket(match, cell.slug)) {
            lowerBracketStructuralIds.add(match.structuralId)
            mutLowerMatches.push(match)
          }
          else {
            mutUpperMatches.push(match)
          }
        })

        const upperCell = {
          ...cell,
          matches: mutUpperMatches
        }

        const lowerCell = {
          ...cell,
          matches: mutLowerMatches
        }

        mutUpperMatches.length && mutUpperCells.push(upperCell)
        mutLowerMatches.length && mutLowerCells.push(lowerCell)
      })

      mutUpperBracket.push({ cells: mutUpperCells })
      mutLowerBracket.push({ cells: mutLowerCells })
    })

    return [mutUpperBracket, mutLowerBracket]
  }
}

export default new StandingsBracketUtil()
