import { Component } from 'lib'
import locale from 'Common/Locale/Locale'

class CookiePreferences extends Component {
  constructor () {
    super()
    this.addListeners()
  }

  addListeners () {
    this.showDrawer = (event) => {
      event.stopPropagation()
      event.preventDefault()

      window.Osano
        && window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
    }
  }

  render () {
    return (
      <a href="#" class="link" onClick={ this.showDrawer }>
        { locale.translate('footer.cookie') }
      </a>
    )
  }
}

export default CookiePreferences
