import { Component, Logger, store } from 'lib'
import EventWidget from 'Component/Event/Widget/EventWidget'
import HomeContent from 'Component/Home/Content/HomeContent'
import relApi from 'Common/Service/RelApi/RelApi'
import contentstack from 'Common/Service/Contentstack/Contentstack'
import locale from 'Common/Locale/Locale'
import HomeVods from 'Component/Home/Vods/HomeVods'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import HomeLiveBanner from 'Component/Home/LiveBanner/HomeLiveBanner'
import HomeHero from 'Component/Home/Hero/HomeHero'
import HomePartner from 'Component/Home/Partner/HomePartner'
import HomeLeagueFilter from 'Component/Home/LeagueFilter/HomeLeagueFilter'
// import HomeSpotifyBanner from 'Component/Home/SpotifyBanner/HomeSpotifyBanner'
import {
  leaguePriority,
  selectedPriorities,
  sortLeaguesByPriority
} from '../../Common/Service/RelApi/RelApi'
import analytics from 'Common/Service/Analytics/Analytics'

const maxNumVods = 30
const leagueFilterKey = 'selected-leagues-home'

class Home extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)

    const selectedLeagues = this.getUserSelectedLeagues()

    this.setState({
      liveEvents: [],
      selectedLeagues,
      leagueFilterActive: false
    })

    this.addListeners()
    this.fetchLeagues()
    this.fetchHomepage()
  }

  addListeners () {
    store.onChange(
      'liveEvents',
      this.liveEventsListener = (json) => {
        this.setState({ liveEvents: json.events })
      }
    )
  }

  setLeagueFilterActiveness (activeness) {
    this.setState({ leagueFilterActive: activeness })
  }

  onLeagueFilterClose (selectedLeagues) {
    window.localStorage.setItem(
      leagueFilterKey,
      JSON.stringify(selectedLeagues)
    )
    this.setState({
      selectedLeagues,
      leagueFilterActive: false
    })

    this.fetchVodsForHome(selectedLeagues)
  }

  componentWillMount () {
    // Temporary redirect to counteract users changing locales on home
    if (!locale.supportsHomepage()) {
      window.location.pathname = `/${locale.get()}/schedule`
    }
  }

  componentWillUnmount () {
    store.removeListener('liveEvents', this.liveEventsListener)
  }

  getUserSelectedLeagues () {
    try {
      return JSON.parse(window.localStorage.getItem(leagueFilterKey))
    }
    catch (ex) {
      this.log.error('Bad user config data. Resetting.')
      window.localStorage.removeItem(leagueFilterKey)

      return null
    }
  }

  getPrioritizedLeagueIds (leagues) {
    return leagues
      .filter((league) =>
        selectedPriorities.includes(league.displayPriority?.status)
      )
      .map((league) => league.id)
  }

  getForcedLeagueIds (leagues) {
    return leagues
      .filter(
        (league) =>
          league.displayPriority?.status === leaguePriority.FORCE_SELECTED
      )
      .map((league) => league.id)
  }

  fetchLeagues () {
    return relApi
      .fetchLeagues()
      .then((json) => {
        const leagues = json.leagues
        const prioritizedLeagues = this.getPrioritizedLeagueIds(leagues)
        const selectedLeagues
          = this.state.selectedLeagues && this.state.selectedLeagues.length
            ? this.state.selectedLeagues
            : prioritizedLeagues

        const forcedLeagues = this.getForcedLeagueIds(leagues)

        forcedLeagues.forEach(
          (league) =>
            !selectedLeagues.includes(league) && selectedLeagues.push(league)
        )

        this.setState({
          leagues,
          selectedLeagues
        })

        this.fetchVodsForHome(selectedLeagues)
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  fetchVodsForHome (leagues) {
    return relApi
      .fetchVodsForHome(leagues)
      .then((json) => {
        const matches = json.slice(0, maxNumVods)

        this.setState({ matches })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  fetchHomepage () {
    return contentstack
      .fetchHomepage()
      .then((homepage) => {
        this.log.info(
          `Current version is ${homepage._version} published`,
          new Date(homepage.publish_details.time).toLocaleString()
        )

        // demote hero if live banner is showing and hero is enabled
        if (this.shouldRenderLiveBanner() && homepage.hero_enabled) {
          homepage.content_list.unshift(homepage.hero.reference[0])
        }

        this.setState({ homepage })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  getLiveMatches () {
    return (
      this.state.liveEvents
      && this.state.liveEvents.filter((event) => event.type === 'match')
    )
  }

  shouldRenderLiveBanner () {
    const events = this.getLiveMatches()

    return events && events.length !== 0
  }

  renderLiveBanner () {
    const liveMatches = this.getLiveMatches()

    // filter events by preferred leagues and sort by league priority
    const sortedEvents = liveMatches.sort((eventA, eventB) =>
      sortLeaguesByPriority(eventA.league, eventB.league)
    )

    const sortedPreferredEvents = sortedEvents.filter(
      (event) => this.state.selectedLeagues.indexOf(event.league.id) !== -1
    )

    const liveBannerEvent = sortedPreferredEvents.length
      ? sortedPreferredEvents[0]
      : sortedEvents[0]

    return <HomeLiveBanner event={ liveBannerEvent }/>
  }

  // Need to do client-side data verification due to Contentstack mandatory
  // field restrictions with field visibility rules
  heroIsValid (hero) {
    return hero.cta !== '' && hero.thumbnail && hero.reference.length
  }

  renderHero (hero) {
    if (!this.heroIsValid(hero)) {
      return this.log.warn(
        'Hero enabled, but missing required fields. Please verify on Contentstack.'
      )
    }

    return (
      <HomeHero
        url={ hero.thumbnail.url }
        reference={ hero.reference[0] }
        cta={ hero.cta }
        title={ hero.title || hero.reference[0].title }
        type={ hero.reference[0]._content_type_uid }
      />
    )
  }

  renderLeagueFilter (leagues, selectedLeagues) {
    const onClose = (newSelection) => {
      this.onLeagueFilterClose(newSelection)
      analytics.trackEvent('home_league_filter_set')
    }

    return (
      <HomeLeagueFilter
        leagues={ leagues }
        selectedLeagues={ selectedLeagues }
        onClose={ onClose }
      />
    )
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading/>
        }
      </main>
    )
  }

  render () {
    const {
      matches,
      homepage,
      leagues,
      selectedLeagues,
      error,
      leagueFilterActive
    } = this.state

    if (!matches || !homepage || !leagues || !selectedLeagues || error) {
      return this.renderErrorOrLoading(error)
    }

    return (
      <main class={ this.constructor.name }>
        { leagueFilterActive
          && this.renderLeagueFilter(leagues, selectedLeagues) }
        <EventWidget
          leagueIds={ selectedLeagues }
          onSettingsClick={ () => this.setLeagueFilterActiveness(true) }
        />
        { /* <HomeSpotifyBanner/> */ }
        { this.shouldRenderLiveBanner()
          ? this.renderLiveBanner()
          : homepage.hero_enabled && this.renderHero(homepage.hero) }
        <div class="home-content">
          <HomeContent content={ homepage.content_list }/>
          <div class="home-sidebar">
            <HomePartner sponsors={ homepage.sponsors }/>
            <HomeVods matches={ matches }/>
          </div>
        </div>
      </main>
    )
  }
}
export default Home
