import { Component, Logger, router, store } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import Image from 'Component/Asset/Image/Image'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import RewardsDropsOverlay from 'Component/Rewards/DropsOverlay/RewardsDropsOverlay'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'
import notifications from 'Common/Service/Notifications/Notifications'
import rewardsDrops from 'Common/Service/Rewards/Drops/RewardsDrops'
import google from '../../../Common/Service/Google/Google'
import analytics from 'Common/Service/Analytics/Analytics'

// when updating slide and timeout duration, also update InformNotifications.styl
// $timeout-duration and $slide-duration at the top of the file
const slideDuration = 1 * util.times.SECONDS
const timeoutDuration = 10 * util.times.SECONDS
const defaultTimeout = timeoutDuration + slideDuration
const {
  DROP_FULFILLED,
  BROADCAST_STARTED,
  MATCH_STARTED
} = notifications.resourceTypes

class InformNotifications extends Component {
  notificationListenersSetup = false

  constructor () {
    super()

    this.log = new Logger(this.constructor.name)

    store.onChange('riotBar.authChecked', () => this.authChecked())
  }

  authChecked () {
    if (riotBar.isLoggedIn()) {
      if (!this.notificationListenersSetup) {
        this.log.info('Notifications enabled. Logged in')
        this.addListeners()
      }
    }
    else {
      this.log.info('Notifications disabled. Not logged in')
    }
  }

  stopAndPrevent (event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  remove (key, event) {
    this.stopAndPrevent(event)
    if (!this.state[key]) return
    delete this.state[key]
    this.forceUpdate()
  }

  removeAll () {
    this.state = {}
    this.forceUpdate()
  }

  addDropFulfilled (message) {
    if (!this.isValidDropsFulfilledMessage(message)) {
      return this.log.warn('Invalid drops fulfilled message.', message)
    }

    const key = message.i

    // for drops notifications we fetch translations from a service
    return rewardsDrops.fetchLocalization(message.l).then((title) =>
      this.setState({
        [key]: { ...message, title }
      })
    )
  }

  addMatchStarted (message) {
    if (!this.isValidMatchStartedMessage(message)) {
      return this.log.warn('Invalid match started message.', message)
    }

    if (!this.isTeamEnabled(message)) {
      return this.log.debug(
        'Notifications disabled.',
        'None of the teams for this match are selected in the settings page'
      )
    }

    const key = message.match.id

    window.setTimeout((key) => this.remove(key), defaultTimeout, key) // timeout (remove from UI) after x seconds

    this.setState({
      [key]: message
    })
  }

  addBroadcastStarted (message) {
    if (!this.isValidBroadcastStartedMessage(message)) {
      return this.log.warn('Invalid broadcast started message.', message)
    }

    if (!this.isLeagueEnabled(message)) {
      return this.log.debug(
        'Notifications disabled.',
        'None of the leagues for this broadcast are selected in the settings page'
      )
    }

    const key = message.broadcast.id

    window.setTimeout((key) => this.remove(key), defaultTimeout, key) // timeout (remove from UI) after x seconds

    this.setState({
      [key]: message
    })
  }

  isTeamEnabled (message) {
    const settings = store.get('settings')
    const teams = util.getDeepValue(settings, 'topics.teams')
    const team1Id = util.getDeepValue(message, 'match.team1.id')
    const team2Id = util.getDeepValue(message, 'match.team2.id')

    return !!(
      teams
      && teams.find(
        (team) => team.relApiId === team1Id || team.relApiId === team2Id
      )
    )
  }

  isLeagueEnabled (message) {
    const settings = store.get('settings')
    const leagues = util.getDeepValue(settings, 'topics.leagues')
    const leagueId = util.getDeepValue(message, 'broadcast.league.id')

    return !!(leagues && leagues.find((league) => league.relApiId === leagueId))
  }

  isEnabled () {
    const settings = store.get('settings')
    const enabled = util.getDeepValue(settings, 'notificationsSettings.watch')

    return enabled === 'enabled'
  }

  addListeners () {
    this.notificationListenersSetup = true
    const broadcastChannel = new window.BroadcastChannel('notifications')

    broadcastChannel.addEventListener('message', (event) => {
      const message = event.data

      if (!this.isEnabled()) {
        return this.log.info('Notifications disabled in the settings page')
      }

      if (message.resource === DROP_FULFILLED) {
        this.addDropFulfilled(message)
        analytics.trackEvent('drop_notification_received', {
          dropId: message.i,
          titleLocalizationGroup: message.l
        })
      }
      else if (message.resource === BROADCAST_STARTED) {
        this.addBroadcastStarted(message)
      }
      else if (message.resource === MATCH_STARTED) {
        this.addMatchStarted(message)
      }
      else {
        this.log.warn('Invalid message resource', message.resource)
      }
    })

    document.addEventListener('router.navigation.go', () => this.removeAll())
  }

  go (path, event) {
    this.stopAndPrevent(event)
    router.go(path)
  }

  displayOverlay (id, event) {
    this.stopAndPrevent(event)
    this.remove(id, event)
    google.pushToDataLayer({
      event: 'Drop Notification Click',
      eventCategory: 'Drops',
      puuid: riotBar.getPuuid()
    })
    RewardsDropsOverlay.display(id)
  }

  isValidBroadcastStartedMessage (message) {
    const league = message.broadcast && message.broadcast.league

    return league && league.id && league.slug && league.name && league.image
  }

  isValidMatchStartedMessage (message) {
    const match = message.match

    const isValidTeam = (team) =>
      team && team.name && team.code && team.image && team.slug

    return (
      match
      && match.id
      && match.leagueGuid
      && isValidTeam(match.team1)
      && isValidTeam(match.team2)
    )
  }

  isValidDropsFulfilledMessage (message) {
    return (
      message && message.i && message.l && message.s && message.p && message.k
    )
  }

  getSelectedTeam (message) {
    const settings = store.get('settings')
    const teams = util.getDeepValue(settings, 'topics.teams')
    const team2Id = util.getDeepValue(message, 'match.team2.id')
    const isTeam2 = teams.find((team) => team.relApiId === team2Id)

    // always return team 1 unless it is team 2
    return isTeam2 ? message.match.team2 : message.match.team1
  }

  renderBroadcastStartedMessage (key) {
    const message = this.state[key]
    const league = message.broadcast.league
    const classNames = util.classNames('message', 'broadcast-started')

    // TODO: waiting for notification.matchStart translations.
    // Remove the || match.live part when they are in
    const title
      = locale.translate('notification.broadcastStart')
      || locale.translate('match.live')

    return (
      <div
        role="button"
        class={ classNames }
        key={ key }
        onClick={ (event) => this.go(`/live/${league.slug}`, event) }>
        <div class="image">
          <Image class="img" src={ league.image } size="60"/>
        </div>
        <div class="body">
          <div class="title">{ title }</div>
          <div class="text">
            <span class="league-name">{ league.name }</span>
          </div>
        </div>
        <div class="actions">
          <button class="close" onClick={ (event) => this.remove(key, event) }>
            <Icon name="bubbleClose"/>
          </button>
          <button
            class="settings"
            onClick={ (event) => this.go('/settings/notifications', event) }>
            <Icon name="cog"/>
          </button>
        </div>
        <div class="timeout"/>
      </div>
    )
  }

  renderMatchStartedMessage (key) {
    const message = this.state[key]
    const selectedTeam = this.getSelectedTeam(message)
    const classNames = util.classNames('message', 'match-started')
    const codeTeam1 = message.match.team1.code
    const codeTeam2 = message.match.team2.code

    // TODO: waiting for notification.matchStart translations.
    // Remove the || match.live part when they are in
    const title
      = locale.translate('notification.matchStart')
      || locale.translate('match.live')

    return (
      <div
        class={ classNames }
        key={ key }
        onClick={ (event) => this.go('/live', event) }>
        <div class="image">
          <Image
            class="img"
            src={ selectedTeam.image }
            size="60"
            alt={ selectedTeam.code }
          />
        </div>
        <div class="body">
          <div class="title">{ title }</div>
          <div class="text">
            <span class="team1">{ codeTeam1 }</span>
            <span class="vs">{ locale.translate('match.vs') }</span>
            <span class="team2">{ codeTeam2 }</span>
          </div>
        </div>
        <div class="actions">
          <button class="close" onClick={ (event) => this.remove(key, event) }>
            <Icon name="bubbleClose"/>
          </button>
          <button
            class="settings"
            onClick={ (event) => this.go('/settings/notifications', event) }>
            <Icon name="cog"/>
          </button>
        </div>
        <div class="timeout"/>
      </div>
    )
  }

  renderDropsFulfilledMessage (key) {
    const message = this.state[key]
    const {
      i: id,
      s: sponsorImage,
      p: productImage,
      c: colors,
      title
    } = message
    const [color1, color2] = colors
    const gradientStyle = `background: radial-gradient(75% 75% at 95% 5%, ${color1} 0%, ${color2} 100%);`
    const classNames = util.classNames('message', 'drops-fulfilled')

    const { highContrastColor } = rewardsDrops.getColorsForRarity(gradientStyle)

    analytics.trackEvent('drop_notification_displayed', {
      dropId: id,
      title: title
    })
    const onClick = (event) => {
      analytics.trackEvent('drop_notification_clicked', {
        dropId: id,
        title: title
      })
      this.displayOverlay(id, event)
    }

    return (
      <div class={ classNames } key={ key } style={ gradientStyle } onClick={ onClick }>
        <div class="product-image">
          <Image class="img" src={ productImage } size="112x112"/>
        </div>
        <div class="body">
          <div class="sponsor-image">
            <Image class="img" src={ sponsorImage } size="x20"/>
          </div>
          <div class="title" style={ { color: highContrastColor } }>
            { title }
          </div>
          <div class="text" style={ { color: highContrastColor } }>
            { locale.translate('rewards.drops.view') }
          </div>
        </div>
        <div class="actions">
          <button class="close" onclick={ (event) => this.remove(key, event) }>
            <Icon name="bubbleClose" fill={ highContrastColor }/>
          </button>
          <button
            class="settings"
            onclick={ (event) => this.go('/settings/notifications', event) }>
            <Icon name="cog" fill={ highContrastColor }/>
          </button>
        </div>
      </div>
    )
  }

  renderMessage (key) {
    const { resource } = this.state[key]

    if (resource === BROADCAST_STARTED) {
      return this.renderBroadcastStartedMessage(key)
    }
    else if (resource === MATCH_STARTED) {
      return this.renderMatchStartedMessage(key)
    }
    else if (resource === DROP_FULFILLED) {
      return this.renderDropsFulfilledMessage(key)
    }
  }

  render () {
    return (
      <div class={ this.constructor.name }>
        { Object.keys(this.state).map((key) => this.renderMessage(key)) }
      </div>
    )
  }
}

export default InformNotifications
