import schedule from '../schedule.json'
import matchDecisionStructure from './matchDecisionStructure.json'

const bracketLineColor = '#555D64'

class StandingsBracketSwissUtil {
  constructor () {
    this.score = matchDecisionStructure
    this.advancedTeams = []
    this.totalRounds = 0
    this.currentColumn = 0
    this.schedule = schedule
  }

  isSeedRound (match) {
    return match.teams.every((team) => team.origin.type === 'seeding')
  }

  pushUniqueItemInArray = (arr, item) => {
    if (!arr.some((element) => element.structuralId === item.structuralId)) {
      arr.push(item)
    }
  }

  /**
   * Calculates the advanced teams based on the given match.
   * If the teams are in cell with 2 wins, the winning team is added to the advanced teams array.
   * If the match is not complete, placeholder is added.
   *
   * @param {Object} match - The match to calculate the advanced teams from.
   */
  calculateAdvancedTeams (match) {
    if (this.score[match.structuralId]?.wins !== 2) return

    const [team1, team2] = match.teams

    if (team1.result?.outcome === 'win') {
      this.advancedTeams.push(team1)
    }
    else if (team2.result?.outcome === 'win') {
      this.advancedTeams.push(team2)
    }
    else {
      this.advancedTeams.push({
        name: `Team ${this.advancedTeams.length + 1}`,
        image: 'http://assets.lolesports.com/watch/team-tbd.png',
        slug: null
      })
    }
  }

  getAdvancedTeams () {
    return this.advancedTeams
  }

  /**
   * Processes a match and sorts it into upper, middle, and lower matches.
   *
   * @param {Object} match - The match to process.
   * @param {Array} mutUpperMatches - The array of upper matches to add to.
   * @param {Array} mutMiddleMatches - The array of middle matches to add to.
   * @param {Array} mutLowerMatches - The array of lower matches to add to.
   */
  processMatch (match, mutUpperMatches, mutMiddleMatches, mutLowerMatches) {
    const score = this.score[match.structuralId]

    if (score.wins > score.loses) {
      mutUpperMatches.push(match)
    }
    else if (score.wins === score.loses) {
      if (this.totalRounds - 1 === score.wins + score.loses) {
        mutLowerMatches.push(match)
      }
      else {
        mutMiddleMatches.push(match)
      }
    }
    else {
      if (score.max === 1) {
        mutMiddleMatches.push(match)
      }
      else {
        mutLowerMatches.push(match)
      }
    }
  }

  /**
   * Processes a cell of the bracket, splitting it into upper, middle, and lower matches and calculating advanced teams.
   *
   * @param {Object} cell - The cell to process.
   * @returns {Object} - An object containing arrays of upper, middle, and lower matches.
   */
  processCell (cell) {
    const mutUpperMatches = []
    const mutMiddleMatches = []
    const mutLowerMatches = []

    cell.matches.forEach((match) => {
      if (this.isSeedRound(match)) {
        this.score[match.structuralId] = {
          wins: 0,
          loses: 0,
          max: 0
        }
        mutMiddleMatches.push(match)
      }
      else {
        this.processMatch(
          match,
          mutUpperMatches,
          mutMiddleMatches,
          mutLowerMatches
        )
        this.calculateAdvancedTeams(match)
      }
    })

    return {
      upperMatches: mutUpperMatches,
      middleMatches: mutMiddleMatches,
      lowerMatches: mutLowerMatches
    }
  }

  calculatePrevCellsDattAttr (matches) {
    let prevCell1 = null

    let prevCell2 = null

    matches.forEach((match) => {
      ;[prevCell1, prevCell2] = this.score[match.structuralId]?.previousBracket
      if (prevCell1 && prevCell2) return
    })

    return {
      prevCell1,
      prevCell2
    }
  }

  /**
   * Splits the bracket into upper, middle, and lower cells, and returns an array of cells.
   *
   * @param {Array} columns - The columns of the bracket.
   * @returns {Array} - An array of cells, each containing an array of matches.
   */
  splitBracket = function (columns) {
    const bracket = []

    this.totalRounds = columns.length
    this.advancedTeams = []

    columns.forEach((column, colIndex) => {
      column.cells.forEach((cell) => {
        const { upperMatches, middleMatches, lowerMatches } = this.processCell(
          cell
        )

        const upperCell = {
          ...cell,
          matches: upperMatches,
          bracketType: 'upper',
          date: this.schedule[colIndex].upper,
          wins:
            upperMatches.length
              && this.score[upperMatches[0].structuralId]?.wins
            || 0,
          loses:
            upperMatches.length
              && this.score[upperMatches[0].structuralId]?.loses
            || 0,
          ...this.calculatePrevCellsDattAttr(upperMatches)
        }

        const middleCell = {
          ...cell,
          matches: middleMatches,
          bracketType: 'middle',
          date: this.schedule[colIndex].middle,
          wins:
            middleMatches.length
              && this.score[middleMatches[0].structuralId]?.wins
            || 0,
          loses:
            middleMatches.length
              && this.score[middleMatches[0].structuralId]?.loses
            || 0,
          ...this.calculatePrevCellsDattAttr(middleMatches)
        }

        const lowerCell = {
          ...cell,
          matches: lowerMatches,
          bracketType: 'lower',
          date: this.schedule[colIndex].lower,
          wins:
            lowerMatches.length
              && this.score[lowerMatches[0].structuralId]?.wins
            || 0,
          loses:
            lowerMatches.length
              && this.score[lowerMatches[0].structuralId]?.loses
            || 0,
          ...this.calculatePrevCellsDattAttr(lowerMatches)
        }

        bracket.push({ cells: [upperCell, middleCell, lowerCell] })
      })
    })
    return bracket
  }

  drawLine (startX, startY, endX, endY, context) {
    const roundToHalf = (notRounded) => {
      const rounded = Math.round(notRounded)

      return rounded > notRounded ? rounded - 0.5 : rounded + 0.5
    }

    const startXRounded = roundToHalf(startX)
    const endXRounded = roundToHalf(endX)
    const startYRounded = roundToHalf(startY)
    const endYRounded = roundToHalf(endY)

    const xDiff = endXRounded - startXRounded
    const secondX = startXRounded + xDiff / 2

    context.moveTo(startXRounded, startYRounded)
    context.lineTo(secondX, startYRounded)
    context.lineTo(secondX, endYRounded)
    context.lineTo(endXRounded, endYRounded)
    context.stroke()
  }

  /**
   * Returns a normalized DOMRect object for an element relative to a canvas element.
   *
   * @param {HTMLElement} canvasRef - The canvas element to which the element is relative.
   * @param {HTMLElement} element - The element to get the normalized DOMRect object for.
   * @returns {DOMRect} - The normalized DOMRect object for the element.
   */
  getNormalizedRect = (canvasRef, element) => {
    const canvasRect = canvasRef?.getBoundingClientRect()
    const rect = element.getBoundingClientRect()

    return new DOMRect(
      rect.x - canvasRect.left,
      rect.y - canvasRect.top,
      rect.width,
      rect.height
    )
  }

  /**
   * Draws a graph on the canvas element of the component, connecting each cell to its previous cells using lines.
   *
   * @param {Object} component - The component object containing the canvasRef and componentRef.
   */
  drawGraph = function (component) {
    const componentRef = component.componentRef
    const canvasRef = component.canvasRef
    const context = canvasRef?.getContext('2d')

    context.strokeStyle = bracketLineColor
    context.globalAlpha = 1
    context.lineWidth = 1

    componentRef.querySelectorAll('.cell').forEach((cellNode) => {
      const prevColumn = cellNode.closest('.column').previousElementSibling

      const nodeRect = this.getNormalizedRect(canvasRef, cellNode)
      const endX = nodeRect.left

      let endY = nodeRect.top + nodeRect.height / 2

      const drawToPrevCell = (cell) => {
        const cellRect = this.getNormalizedRect(canvasRef, cell)
        const startX = cellRect.right
        const startY = cellRect.top + cellRect.height / 2

        this.drawLine(startX, startY, endX, endY, context)
      }

      const prevCell1 = cellNode.dataset.prevCell1
      const prevCell2 = cellNode.dataset.prevCell2

      if (prevColumn && prevCell1) {
        const prevCell = prevColumn.querySelector(`.${prevCell1}`)

        endY -= 10
        drawToPrevCell(prevCell)
      }
      if (prevColumn && prevCell2 && prevCell1 !== prevCell2) {
        const prevCell = prevColumn.querySelector(`.${prevCell2}`)

        endY += 20
        drawToPrevCell(prevCell)
      }
    })
  }
}
export default new StandingsBracketSwissUtil()
