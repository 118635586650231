import { Component, Logger, $, router } from 'lib'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'

class StandingsBracketMatch extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  addListeners () {
    this.link = (event) => {
      const link = $(event.currentTarget).attr('href')

      router.go(link, event)
    }
  }

  getTeamClasses (teams) {
    const team1Classes = util.classNames(
      'team team1',
      teams[0].result && teams[0].result.outcome,
      teams[0].code === 'TBD' && 'tbd'
    )
    const team2Classes = util.classNames(
      'team team2',
      teams[1].result && teams[1].result.outcome,
      teams[1].code === 'TBD' && 'tbd'
    )

    return { team1Classes, team2Classes }
  }

  renderTeam (team, classes) {
    const { slug, name, image, code, result } = team

    // If the game hasn't started, should display a dash instead of a 0
    const dashForGames = !result || isNaN(parseInt(result.gameWins))

    return (
      <a href={ `/teams/${slug}` } class={ classes } onclick={ this.link }>
        <Image class="logo" src={ image } size="36"/>
        <div class="name">{ name }</div>
        <div class="tricode">{ code }</div>
        <div class="score">
          { dashForGames ? Icon.unicode('dash') : result.gameWins }
        </div>
      </a>
    )
  }

  render () {
    if (!this.props.match) {
      return
    }

    const teams = this.props.match.teams
    const { team1Classes, team2Classes } = this.getTeamClasses(teams)

    return (
      <div class={ this.constructor.name }>
        <div class="teams">
          { this.renderTeam(teams[0], team1Classes) }
          { this.renderTeam(teams[1], team2Classes) }
        </div>
      </div>
    )
  }
}

export default StandingsBracketMatch
