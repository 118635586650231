import { Component } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'

class InformSpoiler extends Component {
  constructor () {
    super()
    this.addListeners()
  }

  addListeners () {
    this.showSpoilers = (event) => {
      event.stopPropagation()
      event.preventDefault()
      this.props.showSpoilersHandler && this.props.showSpoilersHandler()
    }
  }

  render () {
    if (!this.props.visible) {
      return
    }

    return (
      <div class={ this.constructor.name } onclick={ this.showSpoilers }>
        <div class="separated-text">
          <div class="label">{ locale.translate('match.spoilers.label') }</div>
          <Icon name="eye"/>
          <div class="label">{ locale.translate('match.spoilers.reveal') }</div>
        </div>
        <div class="single-line-text">
          <Icon name="eye"/>
          <div class="label">{ locale.translate('match.spoilers.tap') }</div>
        </div>
      </div>
    )
  }
}

export default InformSpoiler
