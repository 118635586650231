import { Component, Logger, router, $ } from 'lib'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'
import analytics from 'Common/Service/Analytics/Analytics'

const articleTypes = {
  article: 'article',
  announcement: 'announcement'
}

class HomeContentBlock extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
    this.setState({
      renderedClips: []
    })
  }

  addListeners () {
    this.enableTwitchClip = (twitchId) => {
      if (this.state.renderedClips[twitchId]) return

      const renderedClips = this.state.renderedClips

      renderedClips.push(twitchId)
      this.setState({ renderedClips })
    }
  }

  goToContent (event, isExternalLink) {
    if (isExternalLink) return // let the browser handle external links

    const link = $(event.currentTarget).attr('href')

    router.go(link, event)
  }

  getArticleUrl (article) {
    return `/article/${util.toSlugUrl(article.title)}/${article.uid}`
  }

  renderContentType (contentType, isExternalLink) {
    const contentTypeLocKey = 'home.contentType.' + contentType

    return (
      <div class="content-type">
        { locale.translate(contentTypeLocKey) }
        { isExternalLink && <Icon name="externalLink"/> }
      </div>
    )
  }

  formatDuration (seconds) {
    const milliseconds = seconds * util.times.SECOND

    return milliseconds >= util.times.HOUR
      ? util.toHHMMSS(milliseconds)
      : util.toMMSS(milliseconds)
  }

  renderVideo (content) {
    const onClickLink = (event) => {
      analytics.trackEvent('content_viewed', {
        type: 'video',
        title: content.title
      })
      this.goToContent(event)
    }

    return (
      <a
        class="content-block video"
        href={ `/video/${content.uid}` }
        onClick={ onClickLink }>
        <div class="thumbnail">
          <div
            class="background"
            style={ Image.background(content.thumbnail_high) }
          />
          <div class="duration">{ this.formatDuration(content.duration) }</div>
        </div>
        <div class="description">
          { this.renderContentType('video') }
          <div class="title">{ content.title }</div>
        </div>
      </a>
    )
  }

  renderTwitchClipEmbed (twitchId) {
    const clipSrc
      = 'https://clips.twitch.tv/embed?clip='
      + twitchId
      + '&autoplay=true&muted=false&parent='
      + window.location.hostname

    return (
      <iframe
        class="iframe"
        src={ clipSrc }
        height="360"
        width="640"
        frameborder="0"
        scrolling="no"
        allowfullscreen="0"
      />
    )
  }

  renderClip (content) {
    const shouldRenderIframe = this.state.renderedClips.find(
      (clipId) => content.twitch_id === clipId
    )

    const wrapperClasses = util.classNames(
      'clip-wrapper',
      shouldRenderIframe && 'open'
    )

    const onClick = () => {
      analytics.trackEvent('content_viewed', {
        type: 'clip',
        title: content.title
      })
      this.enableTwitchClip(content.twitch_id)
    }

    return (
      <div class="content-block clip" role="button" onClick={ onClick }>
        <div class={ wrapperClasses }>
          <Icon name="play"/>
          <Image
            class="thumbnail"
            src={ content.thumbnail_url }
            size="480x272"
            alt={ content.title }
          />
          { shouldRenderIframe && this.renderTwitchClipEmbed(content.twitch_id) }
        </div>
        <div class="description">
          { this.renderContentType('clip') }
          <div class="title">{ content.title }</div>
        </div>
      </div>
    )
  }

  contentIsExternalLink (content) {
    return content.external_link && content.external_link !== ''
  }

  getArticleLink (content) {
    return this.contentIsExternalLink(content)
      ? content.external_link
      : this.getArticleUrl(content)
  }

  getArticleTarget (content) {
    return this.contentIsExternalLink(content) ? '_blank' : '_self'
  }

  renderTextBlock (content) {
    const isExternalLink = this.contentIsExternalLink(content)
    const onClickLink = (event) => {
      analytics.trackEvent('content_viewed', {
        type: 'article',
        title: content.title
      })
      this.goToContent(event, isExternalLink)
    }

    return (
      <a
        class="content-block text-block"
        href={ this.getArticleLink(content) }
        target={ this.getArticleTarget(content) }
        onClick={ onClickLink }>
        <div class="description">
          { this.renderContentType(articleTypes.announcement, isExternalLink) }
          <div class="title">
            <div class="text">{ content.title }</div>
          </div>
          <div class="intro">{ content.intro }</div>
        </div>
      </a>
    )
  }

  renderArticle (content) {
    if (!content.header_image) return this.renderTextBlock(content)

    const isExternalLink = this.contentIsExternalLink(content)
    const sizes = { large: 800, medium: 500, small: 135 }
    const onClickLink = (event) => {
      analytics.trackEvent('content_viewed', {
        type: 'article',
        title: content.title
      })
      this.goToContent(event, isExternalLink)
    }

    return (
      <a
        class="content-block article"
        href={ this.getArticleLink(content) }
        target={ this.getArticleTarget(content) }
        onClick={ onClickLink }>
        <div
          class="header"
          style={ Image.background(content.header_image.url, sizes) }
        />
        <div class="description">
          { this.renderContentType(articleTypes.article, isExternalLink) }
          <div class="title">
            <div class="text">{ content.title }</div>
          </div>
        </div>
      </a>
    )
  }

  renderContentBlock (content) {
    switch (content._content_type_uid) {
      case 'youtube_videos':
        return this.renderVideo(content)
      case 'twitch_clips':
        return this.renderClip(content)
      case 'articles':
        return this.renderArticle(content)
      default:
        this.log.warn('Unsupported content type', content._content_type_uid)
        return
    }
  }

  render ({ content }) {
    if (!content) return

    return (
      <div class={ this.constructor.name }>
        { this.renderContentBlock(content) }
      </div>
    )
  }
}

export default HomeContentBlock
