import { Component, Logger } from 'lib'
import locale from 'Common/Locale/Locale'
import dataDragon from 'Common/Service/DataDragon/DataDragon'
import util from 'Common/Util/Util'

class StatsMatchupItems extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  renderItem (id, quantity) {
    const item = this.props.items.data[id]
    const image = dataDragon.getItemImage(id)

    return (
      <div class="item">
        <div class="image-and-quantity">
          <img class="image" src={ image } alt=""/>
          { quantity > 1 && <div class="quantity">{ quantity }</div> }
        </div>
        <div class="detail">
          <div class="name">{ item.name }</div>
          <div class="gold">
            { item.gold.total } { locale.translate('stats.gold.short') }
          </div>
          <div class="description">{ item.plaintext }</div>
        </div>
      </div>
    )
  }

  renderPlayerItems (playerItems, id) {
    const playerClasses = util.classNames(
      'player',
      id === this.props.primaryTeam ? 'primary' : 'secondary'
    )

    return (
      <div class={ playerClasses }>
        { playerItems.map((item) => this.renderItem(item.id, item.quantity)) }
      </div>
    )
  }

  sortAndQuantitizeItems (playerItems) {
    // find the quantities for the items
    const itemsQuantitized = {}

    playerItems.map((item) => {
      itemsQuantitized[item]
        ? itemsQuantitized[item]++
        : itemsQuantitized[item] = 1
    })

    // sort according to gold value
    const playerItemsSorted = Object.keys(itemsQuantitized).sort(
      (itemA, itemB) =>
        this.props.items.data[itemB].gold.total
        - this.props.items.data[itemA].gold.total
    )

    // returned combined data
    return playerItemsSorted.map((itemId) => {
      const quantitizedItemObject = {
        id: itemId,
        quantity: itemsQuantitized[itemId]
      }

      return quantitizedItemObject
    })
  }

  render () {
    if (!this.props.bluePlayer || !this.props.redPlayer || !this.props.items) {
      return
    }

    const player1Items = this.sortAndQuantitizeItems(
      this.props.bluePlayer.items
    )
    const player2Items = this.sortAndQuantitizeItems(this.props.redPlayer.items)

    return (
      <div class={ this.constructor.name }>
        <div class="items">
          { this.renderPlayerItems(player1Items, 'blue') }
          { this.renderPlayerItems(player2Items, 'red') }
        </div>
      </div>
    )
  }
}

export default StatsMatchupItems
