import { Component, Logger, $ } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import device from 'Common/Device/Device'
import {
  leaguePriority,
  sortLeaguesByPriority
} from '../../../Common/Service/RelApi/RelApi'

class HomeLeagueFilter extends Component {
  constructor (props) {
    super(props)
    this.log = new Logger(this.constructor.name)
    this.addListeners()

    $(document.body).addClass('no-scroll-small')
    this.setState({
      selectedLeagues: props.selectedLeagues.slice(0)
    })
  }

  componentWillUnmount () {
    $(document.body).removeClass('no-scroll-small')
  }

  addListeners () {
    this.save = () => {
      this.props.onClose && this.props.onClose(this.state.selectedLeagues)
    }

    this.cancel = () => {
      this.props.onClose && this.props.onClose(this.props.selectedLeagues)
    }

    this.toggleLeagueSelection = (league) => {
      const selectedLeagues = this.state.selectedLeagues
      const leagueIndex = selectedLeagues.indexOf(league)

      if (leagueIndex !== -1) {
        if (this.state.selectedLeagues.length === 1) return
        selectedLeagues.splice(leagueIndex, 1)
      }
      else {
        selectedLeagues.push(league)
      }

      this.setState({ selectedLeagues })
    }
  }

  renderLeague (league) {
    const isPrioritized
      = league.displayPriority?.status === leaguePriority.FORCE_SELECTED
    const isSelected = this.state.selectedLeagues.indexOf(league.id) !== -1
    const leagueClasses = util.classNames(
      'league',
      isSelected && 'selected',
      isPrioritized && 'prioritized'
    )

    return (
      <div
        class={ leagueClasses }
        onClick={ () => this.toggleLeagueSelection(league.id) }>
        <div class="checkbox">
          <Icon name="checkmark"/>
        </div>
        <Image class="image" src={ league.image } size="60"/>
        <div class="text">
          <span class="name">{ league.name }</span>
          { league.region
            && <span class="region">{ `(${league.region.toLowerCase()})` }</span>
          }
        </div>
        <div class="toggle-small">
          <div class="bubble"/>
        </div>
      </div>
    )
  }

  renderHeader () {
    const onClose = device.getSize() === 'small' ? this.save : this.cancel

    return (
      <div class="header">
        <div class="title">{ locale.translate('home.leagueFilter.title') }</div>
        <div class="close" onClick={ onClose }>
          <Icon name="close"/>
        </div>
      </div>
    )
  }

  renderFooter () {
    return (
      <div class="footer">
        <div class="cancel" onClick={ this.cancel }>
          { locale.translate('navigation.cancel') }
        </div>
        <div class="save" onClick={ this.save }>
          { locale.translate('navigation.save') }
        </div>
      </div>
    )
  }

  render () {
    const sortedLeagues = this.props.leagues.sort((leagueA, leagueB) =>
      sortLeaguesByPriority(leagueA, leagueB)
    )

    return (
      <div class={ this.constructor.name }>
        <div class="background" onClick={ this.cancel }/>
        <div class="content">
          { this.renderHeader() }
          <div class="leagues">
            { sortedLeagues.map((league) => this.renderLeague(league)) }
          </div>
          { this.renderFooter() }
        </div>
      </div>
    )
  }
}

export default HomeLeagueFilter
