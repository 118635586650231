// We need to have this manual override in place to allow for a "Play All" display.

module.exports = {
  playAllOverrides: [
    {
      tournamentId: '106273298442359221',
      stages: ['regular_season']
    },
    {
      tournamentId: '107418086627198298',
      stages: ['regular_season']
    }
  ]
}
