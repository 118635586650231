import { Component, Logger, store } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import dataDragon from 'Common/Service/DataDragon/DataDragon'
import util from 'Common/Util/Util'

class StatsMatchupPlayers extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  componentDidMount () {
    this.addListeners()
  }

  addListeners () {
    store.onChange(
      'stats.players.compare',
      this.loadedPlayers = (data) => {
        this.log.debug('stats.players.compare data:', data)
        const championIdPlayer1 = util.getDeepValue(
          data,
          'bluePlayerInfo.championId'
        )
        const championIdPlayer2 = util.getDeepValue(
          data,
          'redPlayerInfo.championId'
        )

        if (championIdPlayer1 && championIdPlayer2) {
          this.fetchDataDragonData(championIdPlayer1, championIdPlayer2)
        }
      }
    )

    store.onChange(
      'dataDragon.setup',
      this.onDataDragonSetup = (setup) => {
        if (setup) {
          this.fetchDataDragonData(
            this.props.bluePlayerInfo.championId,
            this.props.redPlayerInfo.championId
          )
        }
      }
    )
  }

  fetchDataDragonData (championIdPlayer1, championIdPlayer2) {
    this.log.debug(
      "Fetching Data Dragon with champion ID's",
      championIdPlayer1,
      championIdPlayer2
    )

    return Promise.all([
      dataDragon.fetchChampion(championIdPlayer1),
      dataDragon.fetchChampion(championIdPlayer2)
    ])
      .then((jsons) => {
        this.setState({
          player1Champion: jsons[0].data[championIdPlayer1],
          player2Champion: jsons[1].data[championIdPlayer2]
        })
      })
      .catch((error) => {
        this.log.error(error)
      })
  }

  componentWillUnmount () {
    store.removeListener('stats.players.compare', this.loadedPlayers)
    store.removeListener('dataDragon.setup', this.onDataDragonSetup)
  }

  renderPlayer (player, level, champion, teamColor) {
    const championImage = dataDragon.getChampionImage(player.championId)
    const classes = util.classNames(
      'player',
      teamColor === this.props.primaryTeam ? 'primary' : 'secondary'
    )

    return (
      <div class={ classes }>
        <div class="details">
          <div class="name">{ player.summonerName }</div>
          <div class="champion">{ champion.name }</div>
          <span class="separator">{ Icon.unicode('dash') }</span>
          <div class="role">{ player.role }</div>
        </div>
        <div class="portrait">
          <div class="wrapper">
            <img class="image" src={ championImage } alt=""/>
          </div>
          <div class="level">{ level }</div>
        </div>
      </div>
    )
  }

  render () {
    if (!this.props.bluePlayerStats || !this.props.redPlayerStats) {
      this.log.error('Missing player props:', this.props)
      return
    }
    if (!this.state.player1Champion || !this.state.player2Champion) {
      return // dataDragon setup not called yet
    }

    return (
      <div class={ this.constructor.name }>
        { this.renderPlayer(
          this.props.bluePlayerInfo,
          this.props.bluePlayerStats.level,
          this.state.player1Champion,
          'blue'
        ) }
        { this.renderPlayer(
          this.props.redPlayerInfo,
          this.props.redPlayerStats.level,
          this.state.player2Champion,
          'red'
        ) }
      </div>
    )
  }
}

export default StatsMatchupPlayers
