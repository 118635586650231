import { Component, $ } from 'lib'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'

class InformPassCelebration extends Component {
  componentDidMount () {
    this.showConfetti(this.props.showConfetti)
  }

  componentWillMount () {
    this.addListeners()
  }

  addListeners () {
    this.dismiss = () => {
      this.props.onCelebrationOverlayDismissed
        && this.props.onCelebrationOverlayDismissed()
    }
  }

  getAchievementDetails () {
    const {
      activePass,
      isTeamPass,
      isFinalAchievementLevel,
      achievementLevel
    } = this.props
    const level = achievementLevel.level

    if (isFinalAchievementLevel) {
      return locale.translate('rewards.prestige.complete', {
        pass: isTeamPass
          ? activePass.name + ' ' + locale.translate('rewards.passes.teamPass')
          : locale.translate('rewards.passes.fanPass'),
        encouragement: locale.translate(
          'rewards.prestige.encouragement.complete'
        )
      })
    }
    else {
      return locale.translate('rewards.prestige.details', {
        number: level + 1,
        pass: isTeamPass
          ? activePass.name + ' ' + locale.translate('rewards.passes.teamPass')
          : locale.translate('rewards.passes.fanPass'),
        encouragement: locale.translate(
          'rewards.prestige.encouragement.' + level
        )
      })
    }
  }

  showConfetti (shouldShow) {
    if (!shouldShow) {
      $('.confetti-piece').css('display', 'none')
    }
  }

  renderConfetti (pieces) {
    const confetti = []

    for (let i = 0; i < pieces; i++) {
      confetti.push(<div class="confetti-piece"/>)
    }
    return confetti
  }

  render () {
    const { activePass, isTeamPass, isFinalAchievementLevel } = this.props
    const details = this.getAchievementDetails()

    return (
      <div class={ this.constructor.name }>
        <div class="modal">
          <div class="content">
            { this.renderConfetti(7) }
            <div class="info">
              { isTeamPass && (
                <div class="pass-image">
                  <img class="pass-icon" src={ activePass.iconUrl } alt=""/>
                </div>
              ) }
              <div class={ util.classNames('title', !isTeamPass && 'fan-title') }>
                { locale.translate(
                  isFinalAchievementLevel
                    ? 'rewards.prestige.title.complete'
                    : 'rewards.prestige.title'
                ) }
              </div>
              <div class="congratulations">
                { locale.translate('rewards.prestige.congrats') }
              </div>
              <div class="details">{ details }</div>
              <span
                class="button continue-confirm"
                onclick={ this.dismiss }
                role="button">
                { locale.translate('rewards.prestige.ack') }
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InformPassCelebration
