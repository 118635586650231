import { Component, Logger, $ } from 'lib'
import contentstack from 'Common/Service/Contentstack/Contentstack'
import device from 'Common/Device/Device'
import locale from 'Common/Locale/Locale'
import HomeContent from 'Component/Home/Content/HomeContent'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import ContentFilters from 'Component/News/ContentFilters/ContentFilters'
import ContentFiltersHeader from 'Component/News/ContentFilters/ContentFiltersHeader/ContentFiltersHeader'

const contentFiltersKey = 'selected-content-filters'
const filterGroupsKey = 'selected-filter-groups'

class News extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.hl = locale.get()

    // content types to include in the content feed
    this.contentTypes = ['articles', 'youtube_videos', 'twitch_clips']

    // Since the items in the FORMAT filter group can be inferred from their
    // content type, recreating them as Content Filters in Contentstack would be
    // redundant. Instead, we define them here, adhering to the same schema as
    // the ones that will be fetched from Contentstack.
    this.videoContentFilter = {
      title: locale.translate('home.contentType.video'),
      uid: 'cf0'
    }
    this.articleContentFilter = {
      title: locale.translate('home.contentType.article'),
      uid: 'cf1'
    }
    this.defaultFilterGroups = [
      {
        title: locale.translate('news.contentFilters.format'),
        uid: 'fg0',
        content_filters: [this.videoContentFilter, this.articleContentFilter]
      }
    ]

    // get user selected content filters from local storage
    const selectedContentFilters
      = this.getUserSelectedData(contentFiltersKey) || []
    const selectedFilterGroups = this.getUserSelectedData(filterGroupsKey) || []

    this.setState({
      deviceSize: device.getSize(),
      filterGroups: this.defaultFilterGroups,
      selectedContentFilters,
      selectedFilterGroups,
      contentFiltersActive: false,
      content: null
    })

    // set up initial state for counting fetched entries for each content type
    this.contentTypes.forEach((contentType) => {
      this.updateFetchedEntryCount(contentType, 0)
    })

    this.addListeners()
    this.fetchFilterGroups()
    this.fetchContent()
  }

  addListeners () {
    $(document).on('mediaSizeChange', (event) => {
      this.setState({ deviceSize: event.data })
      if (event.data === 'large') {
        this.setContentFiltersActiveness(false)
      }
    })
  }

  componentWillMount () {
    // Temporary redirect to counteract users changing locales
    if (!locale.supportsHomepage()) {
      window.location.pathname = `/${this.hl}/schedule`
    }
  }

  componentWillUnmount () {
    $(document).off('mediaSizeChange')
  }

  getUserSelectedData (lsKey) {
    try {
      return JSON.parse(window.localStorage.getItem(lsKey))
    }
    catch (ex) {
      this.log.error('Bad user config data. Resetting.')
      window.localStorage.removeItem(lsKey)

      return null
    }
  }

  setUserSelectedData (lsKey, data) {
    window.localStorage.setItem(lsKey, JSON.stringify(data))
  }

  setContentFiltersActiveness (isActive) {
    this.setState({ contentFiltersActive: isActive })
    isActive
      ? $(document.body).addClass('no-scroll-small-medium')
      : $(document.body).removeClass('no-scroll-small-medium')
  }

  fetchFilterGroups () {
    let filterGroups = this.state.filterGroups

    return contentstack
      .fetchFilterGroups()
      .then((entries) => {
        filterGroups = [...filterGroups, ...entries]
        this.setState({ filterGroups })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  updateFetchedEntryCount (reference, batchCount) {
    let fetchedCount = this.state[reference]

    if (fetchedCount > 0) {
      fetchedCount = fetchedCount + batchCount
      this.setState({ [reference]: fetchedCount })
    }
    else {
      this.setState({ [reference]: batchCount })
    }
  }

  fetchContent () {
    this.contentTypes.forEach((contentType) => {
      this.fetchContentEntries(contentType)
    })
  }

  fetchContentEntries (contentType, fetchedCount = 0) {
    return contentstack
      .fetchAllContentEntries(contentType, fetchedCount)
      .then((response) => {
        this.handleFetchedContent(contentType, response)
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  addContentTypeToEntry (entry, contentType) {
    // need to add content type so that HomeContentBlock can handle accordingly
    entry._content_type_uid = contentType

    // add the FORMAT content filters to each item based on its content type
    if (!entry.content_filters) {
      entry.content_filters = []
    }
    if (contentType === 'youtube_videos' || contentType === 'twitch_clips') {
      entry.content_filters.push({ uid: this.videoContentFilter.uid })
    }
    else if (contentType === 'articles') {
      entry.content_filters.push({ uid: this.articleContentFilter.uid })
    }
    return entry
  }

  filterByEsportsLocale (entries) {
    return entries.filter((entry) => entry.esports_locale === this.hl)
  }

  handleFetchedContent (contentType, response) {
    const batchCount = response.entries.length
    const totalCount = response.count

    // remove en-US youtube entries from en-GB feed and vice versa
    // necessary because they currently share a youtube channel
    const shouldFilter
      = contentType === 'youtube_videos'
      && (this.hl === 'en-US' || this.hl === 'en-GB')
    const filteredEntries = shouldFilter
      ? this.filterByEsportsLocale(response.entries)
      : response.entries

    const mappedEntries = filteredEntries.map((entry) =>
      this.addContentTypeToEntry(entry, contentType)
    )

    // add this batch of entries to the total content list, then sort by date
    const content = this.state.content
      ? [...this.state.content, ...mappedEntries]
      : mappedEntries

    content.sort((a, b) => a.created_at > b.created_at ? -1 : 1)
    this.setState({ content })

    // update the count of entries fetched for the given conten type
    const fetchedCountRef = `${contentType}_fetched_count`

    this.updateFetchedEntryCount(fetchedCountRef, batchCount)
    const fetchedCount = this.state[fetchedCountRef]

    // fetch additional entries if necessary
    if (fetchedCount < totalCount) {
      this.fetchContentEntries(contentType, fetchedCount)
    }
  }

  getFilteredContent () {
    return this.state.selectedContentFilters.length < 1
      ? this.state.content
      : this.state.content.filter((entry) => this.filterContent(entry))
  }

  filterContent (entry) {
    // get a list containing the uids of all Content Filters assigned to the
    // given entry
    const selectedContentFilters = this.state.selectedContentFilters
    const entryFilters = []

    entry.content_filters.forEach((contentFilter) => {
      entryFilters.push(contentFilter.uid)
    })

    let groupsMet = 0

    // check each Content Filter against the list created above to determine if
    // the entry should be shown
    this.state.filterGroups.forEach((filterGroup) => {
      let filtersMet = 0

      if (this.state.selectedFilterGroups.indexOf(filterGroup.uid) !== -1) {
        filterGroup.content_filters.forEach((contentFilter) => {
          if (
            selectedContentFilters
            && entryFilters.indexOf(contentFilter.uid) !== -1
            && this.state.selectedContentFilters.indexOf(contentFilter.uid) !== -1
          ) {
            filtersMet += 1
          }
        })
      }
      // if any selected filters match those assigned to the entry, it passes
      // the conditions for that goup
      if (filtersMet > 0) groupsMet += 1
    })

    // if the entry meets the conditions for every selected Filter Group,
    // include it in the content feed
    return groupsMet >= this.state.selectedFilterGroups.length
  }

  onContentFilterSelect (selectedContentFilters, selectedFilterGroups) {
    this.setUserSelectedData(contentFiltersKey, selectedContentFilters)
    this.setUserSelectedData(filterGroupsKey, selectedFilterGroups)
    this.setState({ selectedContentFilters })
    this.setState({ selectedFilterGroups })
  }

  renderContentFilters (
    filterGroups,
    selectedFilterGroups,
    selectedContentFilters
  ) {
    const inSidebar = this.state.deviceSize === 'large'
    const contentFilters = (
      <ContentFilters
        inSidebar={ inSidebar }
        filterGroups={ this.state.filterGroups }
        setFiltersState={ (selectedContentFilters, selectedFilterGroups) =>
          this.onContentFilterSelect(
            selectedContentFilters,
            selectedFilterGroups
          )
        }
        selectedFilterGroups={ selectedFilterGroups }
        selectedContentFilters={ selectedContentFilters }
        contentFiltersActive={ this.state.contentFiltersActive }
        setContentFiltersActiveness={ (isActive) =>
          this.setContentFiltersActiveness(isActive)
        }
      />
    )

    if (inSidebar) {
      return <div class="content-feed-sidebar">{ contentFilters }</div>
    }
    else if (this.state.contentFiltersActive) {
      return contentFilters
    }
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading/>
        }
      </main>
    )
  }

  render () {
    const {
      deviceSize,
      filterGroups,
      selectedContentFilters,
      selectedFilterGroups,
      content,
      error
    } = this.state

    if (
      !deviceSize
      || !filterGroups
      || !selectedContentFilters
      || !selectedFilterGroups
      || !content
      || error
    ) {
      return this.renderErrorOrLoading(error)
    }

    return (
      <main class={ this.constructor.name }>
        <div class="content-feed">
          <ContentFiltersHeader
            selectedContentFilters={ selectedContentFilters }
            setFiltersState={ (selectedContentFilters, selectedFilterGroups) =>
              this.onContentFilterSelect(
                selectedContentFilters,
                selectedFilterGroups
              )
            }
            contentFiltersActive={ this.state.contentFiltersActive }
            setContentFiltersActiveness={ (isActive) =>
              this.setContentFiltersActiveness(isActive)
            }
          />
          <HomeContent content={ this.getFilteredContent() } hasContentFilters/>
          { this.renderContentFilters(
            filterGroups,
            selectedFilterGroups,
            selectedContentFilters
          ) }
        </div>
      </main>
    )
  }
}
export default News
