import { Component, Logger } from 'lib'
import StandingsBracketMatch from 'Component/Standings/Bracket/Match/StandingsBracketMatch'
import util from 'Common/Util/Util'

class StandingsBracketStandard extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  initializeMatchMap (stage) {
    const matchMap = {}

    let finalMatches = []

    stage.sections.map((section, index) => {
      if (index === stage.sections.length - 1) {
        finalMatches = section.matches
      }
      section.matches.map((match) => {
        matchMap[match.id] = match
      })
    })

    return { finalMatches, matchMap }
  }

  renderMatch (match) {
    return (
      <div class="match">
        <StandingsBracketMatch match={ match }/>
      </div>
    )
  }

  renderPreviousMatches (previousMatches, currentRound, matchMap) {
    if (currentRound === 1) return

    const firstRoundPreviousMatches = currentRound - 1 === 1

    const lineClasses = util.classNames(
      'bracket-lines',
      firstRoundPreviousMatches && 'first-round'
    )
    const previousMatchClasses = util.classNames(
      'previous-matches',
      'round-' + currentRound
    )

    return (
      <div class={ previousMatchClasses }>
        { previousMatches[0]
          && this.buildBracketGrouping(
            previousMatches[0],
            currentRound - 1,
            matchMap
          ) }
        <div class={ lineClasses }/>
        { previousMatches[1]
          && this.buildBracketGrouping(
            previousMatches[1],
            currentRound - 1,
            matchMap
          ) }
      </div>
    )
  }

  buildBracketGrouping (match, currentRound, matchMap) {
    const previousMatches = []

    match.previousMatchIds.map((id) => {
      matchMap[id] && previousMatches.push(matchMap[id])
    })

    // special class for the deepest nested matches (the first displayed)
    const firstMatchClass = !match.previousMatchIds.length && 'first'
    const groupingClasses = util.classNames('grouping', firstMatchClass)

    return (
      <div class={ groupingClasses }>
        { this.renderMatch(match) }
        { this.renderPreviousMatches(previousMatches, currentRound, matchMap) }
      </div>
    )
  }

  renderStandardBracket (numRounds) {
    const { finalMatches, matchMap } = this.initializeMatchMap(this.props.stage)

    return finalMatches.map((match) =>
      this.buildBracketGrouping(match, numRounds, matchMap)
    )
  }

  renderTitles () {
    return this.props.stage.sections.map((section) =>
      <div class="title">{ section.name }</div>
    )
  }

  render () {
    if (!this.props.stage) {
      return
    }

    // used to determine initial width of bracket
    const numRounds = this.props.stage.sections.length
    const componentClasses = util.classNames(
      'outer-grouping',
      'num-rounds-' + numRounds
    )

    return (
      <div class={ this.constructor.name }>
        <div class={ componentClasses }>
          <div class="round-names">{ this.renderTitles() }</div>
          { this.renderStandardBracket(numRounds) }
        </div>
      </div>
    )
  }
}

export default StandingsBracketStandard
