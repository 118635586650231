import { Component, Logger } from 'lib'
import dataDragon from 'Common/Service/DataDragon/DataDragon'
import util from 'Common/Util/Util'

// the number of runes in the primary tree chosen by the player
const numRunesPrimaryTree = 4

class StatsMatchupRunes extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  componentWillMount () {
    const { runeMap, runeCategoryMap } = this.parseRuneCategories(
      this.props.runes
    )

    this.setState({ runeMap, runeCategoryMap })
  }

  // transform the data dragon runes data into an easibly readable state
  parseRuneCategories (runeCategories) {
    const runeMap = {}
    const runeCategoryMap = {}

    runeCategories.map((runeCategory) => {
      runeCategoryMap[runeCategory.id] = {
        icon: runeCategory.icon,
        name: runeCategory.name
      }

      runeCategory.slots.map((runeTier, index) => {
        runeTier.runes.map((rune) => {
          runeMap[rune.id] = {
            icon: rune.icon,
            name: rune.name,
            description: dataDragon.sanitizeDescription(rune.shortDesc),
            categoryId: runeCategory.id,
            slot: index
          }
        })
      })
    })

    return { runeMap, runeCategoryMap }
  }

  sortRunes (runeList) {
    return runeList.sort((runeA, runeB) => runeA.slot - runeB.slot)
  }

  isRuneStat (runeId) {
    // rune stats are exclusively in the 5000s range
    return Math.floor(runeId / 1000) === 5
  }

  // Categorize runes by tree, and sort them by slot
  organizeRunes (runeIds) {
    const categorizedRunes = {}

    runeIds.map((runeId) => {
      const rune = this.state.runeMap[runeId]

      if (this.isRuneStat(runeId)) {
        this.log.debug('Rune stats not yet supported -- skipping.')
      }
      else if (!rune) {
        this.log.error(
          'Rune not found with id: '
            + runeId
            + '. Check to ensure dataDragon and patch versions are equivalent.'
        )
      }
      else {
        categorizedRunes[rune.categoryId]
          ? categorizedRunes[rune.categoryId].push(rune)
          : categorizedRunes[rune.categoryId] = [rune]
      }
    })

    const sortedRunes = []

    Object.keys(categorizedRunes).map((categoryId) => {
      const unsortedRunes = categorizedRunes[categoryId]

      unsortedRunes.length === numRunesPrimaryTree
        ? sortedRunes.unshift(this.sortRunes(unsortedRunes))
        : sortedRunes.push(this.sortRunes(unsortedRunes))
    })

    return sortedRunes
  }

  renderRune (rune) {
    const runeClasses = util.classNames('rune', rune.slot === 0 && 'keystone')
    const runeImageSrc = dataDragon.getRuneImage(rune.icon)

    return (
      <div class={ runeClasses }>
        <img class="image" src={ runeImageSrc } alt=""/>
        <div class="text">
          <div class="name">{ rune.name }</div>
          <div class="description">{ rune.description }</div>
        </div>
      </div>
    )
  }

  renderRuneCategory (runeCategoryId) {
    const runeCategory = this.state.runeCategoryMap[runeCategoryId]
    const runeCategoryImageSrc = dataDragon.getRuneImage(runeCategory.icon)

    return (
      <div class="rune-category">
        <img class="image" src={ runeCategoryImageSrc } alt=""/>
        <div class="name">{ runeCategory.name }</div>
      </div>
    )
  }

  renderRuneList (runeList) {
    return (
      <div class="rune-list">
        { this.renderRuneCategory(runeList[0].categoryId) }
        { runeList.map((rune) => this.renderRune(rune)) }
      </div>
    )
  }

  renderPlayerRunes (runeData, teamColor) {
    const playerClasses = util.classNames(
      'player',
      teamColor === this.props.primaryTeam ? 'primary' : 'secondary'
    )

    const organizedRunes = this.organizeRunes(runeData.perks)

    return (
      <div class={ playerClasses }>
        { organizedRunes.map((runeList) => this.renderRuneList(runeList)) }
      </div>
    )
  }

  render () {
    if (!this.props.bluePlayer || !this.props.redPlayer || !this.props.runes) {
      return this.log.error(
        'Missing props for StatsMatchupRunes:',
        '\nPlayer1: ',
        this.props.bluePlayer,
        '\nPlayer2: ',
        this.props.redPlayer,
        '\nRunes: ',
        this.props.runes
      )
    }

    const bluePlayerRunes = this.props.bluePlayer.perkMetadata
    const redPlayerRunes = this.props.redPlayer.perkMetadata

    return (
      <div class={ this.constructor.name }>
        { this.renderPlayerRunes(bluePlayerRunes, 'blue') }
        { this.renderPlayerRunes(redPlayerRunes, 'red') }
      </div>
    )
  }
}

export default StatsMatchupRunes
